export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;
export const SERVER_URL = `${process.env.REACT_APP_BASE_URL}/`;
export const SERVER_DOC_PATH = `${process.env.REACT_APP_BASE_URL}/docs/`;
export const SERVER_ICON_PATH = `${process.env.REACT_APP_BASE_URL}/icons/`;

// THEME Styles
export const LIGHT_BLUE_COLOR = "#F2F5F7";
export const THEME_COLOR = "#1849F4";
export const BACKGROUND_THEME_COLOR = "#EEECEC";

export const ADMINISTRATIVE_ROLES = ["Admin", "CS"];
export const SERVICE_REQUEST_APPROVAL_ROLES = [ "Prop Manager", "Finance", "Secretary", "Mayor"];
export const ACTIONS_KEYS = {
    "Admin": ["propManagerAction", "mayorAction", "secretaryAction"],
    "Prop Manager": ["propManagerAction"],
    "Mayor": ["mayorAction"],
    "Secretary": ["secretaryAction"],
    "Finance": ["financeAction"]
};
export const ROLES_OF_ACTIONS = {
    propManagerAction: "Prop Manager",
    mayorAction: "Mayor",
    secretaryAction: "Secretary"
}

export const ROLES_FOR_APPROVAL = ["Prop Manager", "Mayor", "Secretary"]