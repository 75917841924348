import "./style.css";
import ClipLoader from "react-spinners/ClipLoader"

function DashboardTable({ headings, data = [], dataKeys, loading }) {
  return (
    <table className="dashboard-table">
      <thead>
        <tr>
          {headings.map((heading) => (
            <th key={heading} style={{ textTransform: "uppercase" }}>
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      {data.length && !loading ? <tbody>
        {
          data.map((row, index) => (
            <tr key={row[dataKeys[index]]}>
              {dataKeys.map((dataKey) => (
                <td key={row[dataKey]}>{row[dataKey]}</td>
              ))}
            </tr>
          ))
        }
      </tbody>: (
        <tbody>
          <tr>
            <td colSpan={headings.length}>
              <p style={{ textAlign: "center" }}>{loading ? <ClipLoader color="black" size={25} /> : "No records found"}</p>
            </td>
          </tr>
        </tbody>
        )}
    </table>
  );
}

export default DashboardTable;
