import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { ClipLoader } from "react-spinners";
import { Modal } from "react-bootstrap";
import Label from "../../components/Label";
import { useForm } from "react-hook-form";
import CustomSelect from "../../components/CustomSelect";
import {
  DOCUMENT_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  SERVICE_TYPE_OPTIONS,
} from "./UpdateRecord.constants";
import ActionButton from "../../components/ActionButton";
import BlueText from "../../components/BlueText";
import { find } from "lodash";
import DashboardCard from "../../components/DashboardCard";
import CustomInput from "../../components/CustomInput";

const CreateRequestModal = ({ show, area, handleClose, userId, recordId, updateRecordRequestId, token, getUpdateRecordRequestDetails }) => {

  const [loading, setLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState(null);
  const [serviceAmount, setServiceAmount] = useState(null);
  const [documentId, setDocumentId] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      type: "",
      // paymentMethod: PAYMENT_METHOD_OPTIONS[0].value,      
    }
  });

  async function createServiceRequestReq(body) {
    setLoading(true);
    try {
      await axios.post(`${BASE_URL}/users/${userId}/records/${recordId}/updateRecordRequests/${updateRecordRequestId}/serviceRequests`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      getUpdateRecordRequestDetails();
      reset({});
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getServices(body) {
    setLoading(true);
    try {
      const {
        data: { serviceTypes },
      } = await axios.get(`${BASE_URL}/users/${userId}/serviceTypes`, {
        headers: {
          Authorization: token,
        },
      });

      const serviceTypesObj = prepareServiceTypesObj(serviceTypes);
      const selectedServiceValue = find(serviceTypesObj, (value) => value.selected);

      reset({ paymentMethod: PAYMENT_METHOD_OPTIONS[0].value, type: selectedServiceValue.value });
      setServiceTypes(serviceTypesObj);
      setServiceAmount(selectedServiceValue.amount);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const prepareServiceTypesObj = (service) => {
    for (let [key, value] of service.entries()) {
      let selected = false;
      if (!key) selected = true;
      service[key] = { _id: value._id, label: value.type, value: value.type, amount: value.amount, unit: value?.unit, selected }
    }
    return service;
  }

  async function onSubmit(data) {
    const uploadedDocument = await onSubmitDoc();
    if (isValid && uploadedDocument && uploadedDocument._id) {
      createServiceRequestReq(data);
    }
  }

  function onChangeServiceType(e) {
    const selectedIndex = e.target.selectedIndex;
    const selecteAmount = serviceTypes[selectedIndex].amount;

    let updatedServiceType = [...serviceTypes];
    for (let [index, serviceType] of updatedServiceType.entries()) {
      if (index !== selectedIndex) {
        serviceType.selected = false;
      } else {
        serviceType.selected = true;
      }
    }
    setServiceTypes(updatedServiceType)
    if (serviceTypes[selectedIndex]?.unit && serviceTypes[selectedIndex]?.unit !== "fixed") {
      setServiceAmount(area * selecteAmount);
    } else {
      setServiceAmount(selecteAmount);
    }
  }

  useEffect(() => {
    if(show) {
      getServices();
      getDocumentTypeReq();
    }
  }, [show]);


  // Document related code
  const [documentTypeOptions, setDiscountTypeOptions] = useState(null);

  const {
    handleSubmit: handleDocSubmit,
    formState: { errors: docErrors, isValid },
    control: docControl,
    setValue,
    getValues: getDocValues,
    reset: resetDocForm
  } = useForm({
    values: {
      name: "",
      type: "",
      url: ""
    }
  });

  async function getDocumentTypeReq(body) {
    setLoading(true);
    try {
      let {
        data: { documentTypes },
      } = await axios.get(`${BASE_URL}/users/${userId}/documentTypes`, {
        headers: {
          Authorization: token,
        },
      });
      documentTypes = documentTypes.map((documentType) => {
        return {label: documentType.type, value: documentType.type}
      })
      setDiscountTypeOptions(documentTypes);

      setValue('type', documentTypes[0].value)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function createDocumentReq(body) {
    setLoading(true);
    try {
      const { data: { uploadedDocument } } = await axios.post(`${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/uploadedDocuments`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      getUpdateRecordRequestDetails();
      resetDocForm({});
      // handleClose();
      return uploadedDocument;
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert("Document: " + extractErrorMessage(err));
    }
  }

  async function uploadDocumentReq(body) {
    setLoading(true);
    try {
      const { data } = await axios.post(`${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/uploadedDocuments/upload`, body, {
        headers: {
          Authorization: token,
          'content-type': 'multipart/form-data'
        },
      });
      setValue('url', data.url);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onFileChangeHandler(e) {
    const files = e.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadDocumentReq(formData);
  }

  async function onSubmitDoc(data) {
    data = getDocValues();
    const uploadedDocument = await createDocumentReq(data);
    return uploadedDocument;
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label required={true}>Service Type</Label>
          <CustomSelect
            name={"type"}
            control={control}
            errors={errors}
            errorText={"Service Type is required"}
            options={serviceTypes ? serviceTypes : []}
            onHandleChange={onChangeServiceType}
          />
          {/* <div className="mt-3">
            <Label required={true}>Payment Method</Label>
            <CustomSelect
              name={"paymentMethod"}
              control={control}
              errors={errors}
              errorText={"Payment Method is required"}
              options={PAYMENT_METHOD_OPTIONS}
            />
          </div> */}
          <div className="mt-3">
            <Label required={true}>Service Amount</Label>
            <BlueText>${serviceAmount}</BlueText>
          </div>
          <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
            <h4>Document</h4>
            <form encType="multipart/form-data">
              <Label required={true}>Name</Label>
              <CustomInput
                name={"name"}
                type={"text"}
                control={docControl}
                rules={{ required: true }}
                errors={docErrors}
                errorText={"Name of Document is required"}
              />
              <div className="mt-3">
                <Label required={true}>Type</Label>
                <CustomSelect
                  name={"type"}
                  control={docControl}
                  errors={docErrors}
                  errorText={"Type is required"}
                  options={documentTypeOptions && documentTypeOptions.length > 0 ? documentTypeOptions : []}
                />
              </div>
              <div className="mt-3">
                <Label required={true}>File</Label>
                <CustomInput
                  name={"file"}
                  type={"file"}
                  control={docControl}
                  rules={{ required: true }}
                  errors={docErrors}
                  onFileChangeHandler={onFileChangeHandler}
                  errorText={"Document is required"}
                />
              </div>
              <div>
                <b>Allowed types: pdf,doc,docx,jpg,jpeg,png</b>
              </div>
              {/* <div className="mt-3 flex justify-center">
                <ActionButton type="Button" onClick={onSubmitDoc} disabled={loading}>{loading ? <ClipLoader color="white" size={18} /> : "Upload"}</ActionButton>
              </div> */}
            </form>
          </DashboardCard>
          <div></div>
          <div className="mt-3 flex justify-content-end">
            <ActionButton disabled={loading}>{loading ? <ClipLoader color="white" size={18} /> : "Create"}</ActionButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateRequestModal;
