import React from "react";
import { Controller } from "react-hook-form";
import "./style.css";

function CustomInput({ name, type, rules, defaultValue, disabled, control, errors, onTextChange, inputClassName, errorText, smallClassName, placeholder, onFileChangeHandler, style, isValid, ...props }) {
  return (
    <>
      <Controller
        {...props}
        name={name}
        control={control}
        rules={rules ? rules : {}}
        render={({ field: { onChange, onBlur, ref, value, name }, fieldState: { error }  }) => (
          <>
          <input
          name={name}
            className={`custom-input ${inputClassName} ${errors[name] ? "input-error" : ""}`}
            ref={ref}
            onChange={(e) => {
              onChange(e);
              if(onTextChange){
                onTextChange(e);
              }
              if(onFileChangeHandler){
                onFileChangeHandler(e);
              }
            }}
            onBlur={onBlur}
            value={value}
            type={type ? type : "text"}
            placeholder={placeholder}
            style={style}
            disabled={disabled}
          />
          {/* {error && <p>{error.message}</p>} */}
          {errors[name] && <small className={smallClassName} style={{ color: "red", }}>{errorText}</small>}
          </>
        )}
      />
    </>
  );
}

export default CustomInput;
