import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import DashboardCard from "../../components/DashboardCard";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { ADD_USER_FIELDS, ROLE_SELECT_OPTIONS } from "./AddUser.constants";
import ActionButton from "../../components/ActionButton";
import DashboardLayout from "../../components/DashboardLayout";

const AddUser = () => {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    watch,
  } = useForm({
    values: {
      name: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "Admin",
    },
  });
  const navigate = useNavigate();
  const roleWatch = watch("role", "Administrator");

  // Redux Store
  const {
    auth: {
      token,
      user: { _id: userId },
    },
  } = useSelector((state) => state);

  // State Variables
  const [loading, setLoading] = useState(false);

  async function addUserReq(body) {
    setLoading(true);
    try {
      await axios.post(`${BASE_URL}/users/${userId}/createUser`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      navigate("/admins/users");
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    addUserReq(data);
  }
  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between align-center mx-20 mt-5">
          <div className="flex align-center">
            <Link to="/admins/users">
              <MdOutlineArrowBackIosNew size={20} />
            </Link>
            <DashboardHeading>Add User</DashboardHeading>
          </div>
          <ActionButton type="submit" disabled={loading}>
            {loading ? <ClipLoader color="black" size={20} /> : "Create"}
          </ActionButton>
        </div>
        {/* form starts here */}
        <DashboardCard>
          {ADD_USER_FIELDS(getValues).map(
            ({ name, required, label, type, rules, errorText }) => (
              <div key={name} className="mt-3">
                <Label required={required}>{label}</Label>
                <CustomInput
                  name={name}
                  type={type}
                  control={control}
                  rules={rules}
                  errors={errors}
                  errorText={errorText}
                />
              </div>
            )
          )}
          <div className="mt-3">
            <Label required={true}>Role</Label>
            <CustomSelect
              name={"role"}
              control={control}
              errors={errors}
              errorText={"Role is required"}
              options={ROLE_SELECT_OPTIONS}
            />
          </div>
          {(roleWatch === "Mayor" || roleWatch === "Secretary") && (
            <>
              <div className="mt-3">
                <Label required={true}>Start Date</Label>
                <CustomInput
                  name={"startDate"}
                  type={"date"}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                  errorText={"Start Date is required"}
                />
              </div>
              <div className="mt-3">
                <Label required={true}>Signature</Label>
                <CustomInput
                  name={"signatureUrl"}
                  type={"file"}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                  errorText={"Signature is required"}
                />
              </div>
            </>
          )}
        </DashboardCard>
      </form>
    </DashboardLayout>
  );
};

export default AddUser;
