export const APP_CONFIG_FIELDS = (getValues) => [
    {
        name: "name",
        label: "Name",
        type: "text",
        errorText: "Name is required",
    },
    {
        name: "icon",
        label: "Icon",
        type: "text",
    },
    {
        name: "file",
        label: "File",
        type: "file",
        errorText: "File is required",
    }
]

export const ADD_SERVICE_TYPE_FIELDS = (getValues) => [
    {
        name: "type",
        label: "Type",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Type is required",
    },
    {
        name: "amount",
        label: "Amount",
        required: true,
        type: "number",
        rules: { required: true },
        errorText: "Amount is required",
    },
]

export const ADD_LOCATION_FIELDS = (getValues) => [
    {
        name: "name",
        label: "Zone",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Zone name is required",
    },
]

export const PRICE_MASTER_FIELDS = (getValues) => [
    {
        name: "unit",
        label: "Unit",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Unit is required",
    },
]

export const DOCUMENT_TYPE_FIELDS = (getValues) => [
    {
        name: "type",
        label: "Type",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Document Type is required",
    },
]

export const SHARED_NAME_FIELD = (getValues) => [
    {
        name: "name",
        label: "Name",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Name is required",
    },
]

export const APP_CONFIG_TABLE_HEADINGS = ["Name", "Icon", "Action"];
export const APP_CONFIG_TABLE_DATA_KEYS = ["name", "icon", "action"];

export const LOCATIONS_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const LOCATIONS_TABLE_DATA_KEYS = ["name", "createdAt", "action"];

export const ZONES_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const ZONES_TABLE_DATA_KEYS = ["name", "createdAt", "action"];

export const SERVICE_TYPE_TABLE_HEADINGS = ["Type", "amount", "Unit", "Action"];
export const SERVICE_TYPE_TABLE_DATA_KEYS = ["type", "amount", "unit", "action"];

export const PRICE_MASTER_TABLE_HEADINGS = ["Unit", "Created At", "Action"];
export const PRICE_MASTER_TABLE_DATA_KEYS = ["unit", "createdAt", "action"];

export const DOCUMENT_TYPE_TABLE_HEADINGS = ["Type", "Created At", "Action"];
export const DOCUMENT_TYPE_TABLE_DATA_KEYS = ["type", "createdAt", "action"];

export const CUSTOMER_GROUP_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const CUSTOMER_GROUP_TABLE_DATA_KEYS = ["name", "createdAt", "action"];

export const LAND_CATEGORY_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const LAND_CATEGORY_TABLE_DATA_KEYS = ["name", "createdAt", "action"];

export const LAND_TITLE_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const LAND_TITLE_TABLE_DATA_KEYS = ["name", "createdAt", "action"];

export const UPDATE_REASON_TABLE_HEADINGS = ["Name", "Created At", "Action"];
export const UPDATE_REASON_TABLE_DATA_KEYS = ["name", "createdAt", "action"];