export const DOCUMENT_TYPE_OPTIONS = [
  {
    label: "Proof Of Identity",
    value: "Proof Of Identity",
  },
  {
    label: "Sale Agreement",
    value: "Sale Agreement",
  },
];

export const SERVICE_TYPE_OPTIONS = [
  {
    label: "Survey & Print OC",
    value: "Survey & Print OC",
  },
  {
    label: "Land Title Change",
    value: "Land Title Change",
  },
];

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Wallet",
    value: "Wallet",
  },
];

export const ACTION_OPTIONS = [
  {
    label: "Approve",
    value: "Approved",
  },
  {
    label: "Reject",
    value: "Rejected",
  },
];

export const RECORDS_TABLE_HEADINGS = [
  "Owner Name",
  "Record Change",
  "Purchase Date",
  "Purchase Price",
  "Dues",
  "Pay Method",
  "",
];
export const RECORDS_TABLE_DATA_KEYS = [
  "ownerName",
  "updateReason",
  "createdAt",
  "purchasePrice",
  "dues",
  "paymentMethod",
  "actions",
];

export const UPLOADED_DOCUMENTS_TABLE_HEADINGS = [
  "Name",
  "Document Type",
  "View",
  "Collected By",
  // "Status",
  "Upload Date",
];
export const UPLOADED_DOCUMENTS_TABLE_DATA_KEYS = [
  "name",
  "type",
  "url",
  "collectedByName",
  // "status",
  "createdAt",
];

export const SERVICE_REQUESTS_TABLE_HEADINGS_WITOUT_ACTION = [
  "Collection Date",
  "Task Type",
  "Total Area (Sq Meters)",
  "Amount",
  "Total Amount",
];

export const SERVICE_REQUESTS_TABLE_DATA_KEYS_WITOUT_ACTION = [
  "createdAt",
  "type",
  "area",
  "amount",
  "totalAmount",
];

export const SERVICE_REQUESTS_TABLE_HEADINGS = [
    "Date",
    "Task Type",
    "Area (Sq Meters)",
    "Amount",
    "Total Amount",
    // "Status",
    "Actions"
  ];
  export const SERVICE_REQUESTS_TABLE_DATA_KEYS = [
    "createdAt",
    "type",
    "area",
    "amount",
    "totalAmount",
    // "status",
    "actions"
  ];

  
export const APPROVAL_TABLE_HEADINGS = [
  "To Be Approved By",
  "Status",
  // "Actions",
];
export const APPROVAL_TABLE_DATA_KEYS = [
  "role",
  "status",
  // "actions",
];
