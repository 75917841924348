import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from "./pages/logins/Login";
import PostLogin_search from "./pages/postLogin/PostLogin_search";
import Property_details from "./pages/postLogin/Property_details";
import Dashboard from "./pages/dashboard/Dashboard";
import Records from "./pages/Records/Records.index";
import Service from "./pages/service/Service.index";
import Owners from "./pages/owners/Owners";
import AddOwner from "./pages/owners/AddOwner";
import EditOwner from "./pages/owners/EditOwner";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./routes/ProtectedRoute";
import NonProtectedRoute from "./routes/NonProtectedRoute";
import Users from "./pages/Users/Users.index";
import AddUser from "./pages/AddUser/AddUser.index";
import AddRecord from "./pages/AddRecord/AddRecord.index";
import RecordDetails from "./pages/RecordDetails/RecordDetails.index";
import "./App.css";
import UpdateRecord from "./pages/UpdateRecord/UpdateRecord.index";
import EditUser from "./pages/EditUser/EditUser.index";
import UpdateRecordRequest from "./pages/UpdateRecordRequest/UpdateRecordRequest.index";
import RequestSurvey from "./pages/RequestSurvey/RequestSurvey.index";
import SeeOnMaps from "./pages/RequestSurvey/RequestSurvey.Map";
import Configurations from "./pages/Configurations";
import AddServiceType from "./pages/Configurations/AddServiceType";
import UpdateServiceType from "./pages/Configurations/UpdateServiceType";
import AddLocation from "./pages/Configurations/AddLocation";
import UpdateLocation from "./pages/Configurations/UpdateLocation";
import AddZone from "./pages/Configurations/AddZone";
import UpdateZone from "./pages/Configurations/UpdateZone";
import { useEffect, useState } from "react";
import AddUnit from "./pages/Configurations/AddUnit";
import useAppConfig from "./hooks/useAppConfig";
import AddAppConfig from "./pages/Configurations/AddAppConfig";
import AddDocumentType from "./pages/Configurations/AddDocumentType";
import Receipt from "./components/Certificate/receipt";
import GISRecords from "./pages/GISRecords/GISRecords.index";
import { useSelector } from "react-redux";
import GISUpdate from "./pages/GISRecords/GISUpdate.Map";
import Surveyor from "./pages/Surveyor/Surveyor.index";
import Dues from "./pages/Dues/Dues.index";
import Certificate from "./components/Certificate/certificate";
import Location from "./pages/Configurations/Location";
import ServiceType from "./pages/Configurations/ServiceType";
import UpdateUnit from "./pages/Configurations/UpdateUnit";
import Unit from "./pages/Configurations/Unit";
import UpdateDocumentType from "./pages/Configurations/UpdateDocumentType";
import DocumentType from "./pages/Configurations/DocumentType";
import Zone from "./pages/Configurations/Zone";
import AppConfig from "./pages/Configurations/AppConfig";
import CSReport from "./pages/dashboard/CSReport";
import FinanceReport from "./pages/dashboard/FinanceReport";
import GISReport from "./pages/dashboard/GISReport";
import RoleReport from "./pages/dashboard/RoleReport";
import CustomerGroup from "./pages/Configurations/CustomerGroup";
import LandTitle from "./pages/Configurations/LandTitle";
import LandCategory from "./pages/Configurations/LandCategory";
import UpdateReason from "./pages/Configurations/UpdateReason";
import AddCustomerGroup from "./pages/Configurations/AddCustomerGroup";
import UpdateCustomerGroup from "./pages/Configurations/UpdateCustomerGroup";
import AddLandTitle from "./pages/Configurations/AddLandTitle";
import UpdateLandTitle from "./pages/Configurations/UpdateLandTitle";
import AddLandCategory from "./pages/Configurations/AddLandCategory";
import UpdateLandCategory from "./pages/Configurations/UpdateLandCategory";
import AddUpdateReason from "./pages/Configurations/AddUpdateReason";
import UpdateUpdateReason from "./pages/Configurations/UpdateUpdateReason";

function App() {

  const { getAppConfig } = useAppConfig();
  const { auth: { user, token }} = useSelector(state => state);

  useEffect(() => {
    getAppConfig();
  }, [])

  return (
    <Router>
      <Routes>
        <Route index element={<ProtectedRoute>{user?.role === "Super_Admin" ? <Navigate to="/admins/configurations/appConfig" replace /> : 
                      user?.role === "GIS" ? <Navigate to="/surveyor/records" replace /> : <Navigate to="/dashboard" replace />}</ProtectedRoute>} />
        <Route path="/login" element={<NonProtectedRoute><Login /></NonProtectedRoute>} />
        <Route path="/search" element={<PostLogin_search />} />
        <Route path="/details" element={<Property_details />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/service" element={<Service />} />
        <Route path="/admins/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route path="/admins/users/create" element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
        <Route path="/admins/users/:userId/edit" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
        <Route path="/admins/records" element={<ProtectedRoute><Records /></ProtectedRoute>} />
        <Route path="/admins/records/create" element={<ProtectedRoute><AddRecord /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId" element={<ProtectedRoute><RecordDetails /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/certificate" element={<ProtectedRoute><Certificate /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/updateRecordRequests/:updateRecordRequestId/requestSurvey" element={<ProtectedRoute><RequestSurvey /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/requestSurvey/map" element={<ProtectedRoute><SeeOnMaps /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/updateRecordRequests/create" element={<ProtectedRoute><UpdateRecordRequest /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/updateRecordRequests/:updateRecordRequestId/update" element={<ProtectedRoute><UpdateRecord /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/updateRecordRequests/:updateRecordRequestId/dues" element={<ProtectedRoute><Dues /></ProtectedRoute>} />
        <Route path="/admins/records/:recordId/updateRecordRequests/:updateRecordRequestId/serviceRequests/:requestId" element={<ProtectedRoute><Receipt /></ProtectedRoute>} />
        <Route path="/admins/owners" element={<ProtectedRoute><Owners /></ProtectedRoute>} />
        <Route path="/admins/owners/create" element={<ProtectedRoute><AddOwner /></ProtectedRoute>} />
        <Route path="/admins/owners/:ownerId/edit" element={<ProtectedRoute><EditOwner /></ProtectedRoute>} />
        <Route path="/admins/configurations" element={<ProtectedRoute><Configurations /></ProtectedRoute>} />
        <Route path="/admins/configurations/addAppConfig" element={<ProtectedRoute allowedRole="Super_Admin"><AddAppConfig /></ProtectedRoute>} />
        <Route path="/admins/configurations/serviceTypes" element={<ProtectedRoute><ServiceType /></ProtectedRoute>} />
        <Route path="/admins/configurations/serviceTypes/add" element={<ProtectedRoute><AddServiceType /></ProtectedRoute>} />
        <Route path="/admins/configurations/serviceTypes/:serviceTypeId/update" element={<ProtectedRoute><UpdateServiceType /></ProtectedRoute>} />
        <Route path="/admins/configurations/locations" element={<ProtectedRoute><Location allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/locations/add" element={<ProtectedRoute><AddLocation allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/locations/:locationId/update" element={<ProtectedRoute><UpdateLocation allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/zones/add" element={<ProtectedRoute><AddZone allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/locations/:locationId/zones/:zoneId/update" element={<ProtectedRoute><UpdateZone allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/units/add" element={<ProtectedRoute><AddUnit /></ProtectedRoute>} />
        <Route path="/admins/configurations/addDocumentType" element={<ProtectedRoute><AddDocumentType /></ProtectedRoute>} />
        <Route path="/admins/configurations/documentTypes/add" element={<ProtectedRoute><AddDocumentType /></ProtectedRoute>} />
        <Route path="/admins/configurations/customerGroup" element={<ProtectedRoute><CustomerGroup /></ProtectedRoute>} />
        <Route path="/admins/configurations/customerGroup/add" element={<ProtectedRoute><AddCustomerGroup /></ProtectedRoute>} />
        <Route path="/admins/configurations/customerGroup/:customerGroupId/update" element={<ProtectedRoute><UpdateCustomerGroup /></ProtectedRoute>} />
        <Route path="/admins/configurations/landTitle" element={<ProtectedRoute><LandTitle /></ProtectedRoute>} />
        <Route path="/admins/configurations/landTitle/add" element={<ProtectedRoute><AddLandTitle /></ProtectedRoute>} />
        <Route path="/admins/configurations/landTitle/:landTitleId/update" element={<ProtectedRoute><UpdateLandTitle /></ProtectedRoute>} />
        <Route path="/admins/configurations/landCategory" element={<ProtectedRoute><LandCategory /></ProtectedRoute>} />
        <Route path="/admins/configurations/landCategory/add" element={<ProtectedRoute><AddLandCategory /></ProtectedRoute>} />
        <Route path="/admins/configurations/landCategory/:landCategoryId/update" element={<ProtectedRoute><UpdateLandCategory /></ProtectedRoute>} />
        <Route path="/admins/configurations/updateReason" element={<ProtectedRoute><UpdateReason /></ProtectedRoute>} />
        <Route path="/admins/configurations/updateReason/add" element={<ProtectedRoute><AddUpdateReason /></ProtectedRoute>} />
        <Route path="/admins/configurations/updateReason/:updateReasonId/update" element={<ProtectedRoute><UpdateUpdateReason /></ProtectedRoute>} />
        <Route path="/gis/records" element={<ProtectedRoute allowedRole="GIS_MANAGER"><GISRecords /></ProtectedRoute>} />
        <Route path="/gis/:surveyId/map" element={<ProtectedRoute allowedRole="GIS_MANAGER"><GISUpdate /></ProtectedRoute>} />
        <Route path="/surveyor/records" element={<ProtectedRoute allowedRole="GIS"><Surveyor /></ProtectedRoute>} />
        <Route path="/admins/configurations/units" element={<ProtectedRoute><Unit /></ProtectedRoute>} />
        <Route path="/admins/configurations/units/:priceMasterId/update" element={<ProtectedRoute><UpdateUnit /></ProtectedRoute>} />
        <Route path="/admins/configurations/documentTypes" element={<ProtectedRoute><DocumentType /></ProtectedRoute>} />
        <Route path="/admins/configurations/documentTypes/:documentTypeId/update" element={<ProtectedRoute><UpdateDocumentType /></ProtectedRoute>} />
        <Route path="/admins/configurations/zones" element={<ProtectedRoute><Zone allowedRole="Super_Admin"/></ProtectedRoute>} />
        <Route path="/admins/configurations/appConfig" element={<ProtectedRoute allowedRole="Super_Admin"><AppConfig /></ProtectedRoute>} />
        <Route path="/admins/dashboard/cs/:reportType/download-pdf" element={<ProtectedRoute ><CSReport /></ProtectedRoute>} />
        <Route path="/admins/dashboard/finance/:reportType/download-pdf" element={<ProtectedRoute ><FinanceReport /></ProtectedRoute>} />
        <Route path="/admins/dashboard/gis/:reportType/download-pdf" element={<ProtectedRoute ><GISReport /></ProtectedRoute>} />
        <Route path="/admins/dashboard/role/:reportType/download-pdf" element={<ProtectedRoute ><RoleReport /></ProtectedRoute>} />

      </Routes>
    </Router>
  );
}

export default App;
