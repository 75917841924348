
import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdDelete, MdEdit } from "react-icons/md";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import DashboardHeading from "../../components/DashboardHeading";
import {  DOCUMENT_TYPE_TABLE_DATA_KEYS, DOCUMENT_TYPE_TABLE_HEADINGS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import DashboardTable from "../../components/DashboardTable";
import { Button } from "react-bootstrap";

const DocumentType = () => {
  const navigate = useNavigate();

  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  // State Variables
  const [documentTypeArray, setDocumentTypeArray] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getAllDocumentTypes(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { documentTypes },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/documentTypes?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setDocumentTypeArray(documentTypes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    getAllDocumentTypes(data);
  }

  useEffect(() => {
    getAllDocumentTypes();
  }, []);

  async function onDelete(id) {
    setLoading(true);
    try {
    // eslint-disable-next-line no-restricted-globals
    let result = confirm("Do you really want to delete this document type?");
      if(!result){
        return;
      }
      await axios.delete(
        `${BASE_URL}/users/${userId}/documentTypes/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getAllDocumentTypes()
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  return (
    <DashboardLayout>
      <ConfigurationsHeader />
      <div className="flex justify-between align-center mx-20 mt-5">
        <DashboardHeading>Document Type</DashboardHeading>
        <Link to="/admins/configurations/documentTypes/add" className="dash_head_link">
          <ActionButton>Add Document Type</ActionButton>
        </Link>
      </div>
      {/* <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex records-form">
          <CustomInput
            name={"ownerName"}
            errors={errors}
            placeholder="Name"
            control={control}
          />
          <CustomInput
            name={"propertyId"}
            errors={errors}
            placeholder="Property ID"
            control={control}
            inputClassName="ms-3"
          />
          <CustomInput
            name={"createdAt"}
            errors={errors}
            placeholder="Registry Date"
            control={control}
            type="date"
            inputClassName="ms-3"
          />
          <ActionButton disabled={loading} className={"ms-3 w-100"}>
            {loading ? <ClipLoader size={22} color="white" /> : "Search"}
          </ActionButton>
        </form>
      </DashboardCard> */}
      {(<div className="records-table">
        <DashboardCard>
          {/* <h5 className="mt-2 ms-1">Search Results</h5> */}
          <DashboardTable
            headings={DOCUMENT_TYPE_TABLE_HEADINGS}
            dataKeys={DOCUMENT_TYPE_TABLE_DATA_KEYS}
            data={
              documentTypeArray.length
                ? documentTypeArray.map((record) => ({
                  ...record,
                  action: (
                    <div className="d-flex" style={{gap: "8px"}}>
                    <Button style={{backgroundColor: '#00B4D8'}} onClick={() => navigate(`/admins/configurations/documentTypes/${record._id}/update`)}>
                      <MdEdit size={24} />
                    </Button>
                    <Button style={{backgroundColor: 'red'}} onClick={() => onDelete(record._id)}>
                      <MdDelete size={24} />
                    </Button>
                  </div>
                  ),
                }))
                : []
            }
          />
        </DashboardCard>
      </div>
      )}
      {/* <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ fontSize: "32px" }}>OR</span>
          <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/addDocumentType"  >
            <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
              <FiEdit color="white" size={20} className="me-2" />
              <small>Add Document Type</small>
            </ActionButton></Link>
          <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/updateDocumentType"  >
            <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
              <FiEdit color="white" size={20} className="me-2" />
              <small>Update Document Type</small>
            </ActionButton></Link>
        </div>
      </DashboardCard> */}
    </DashboardLayout>
  );
}

export default DocumentType;