
import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { ADD_LOCATION_FIELDS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import CustomSelect from "../../components/CustomSelect";
import ShowSuccessAlert from "../../components/SuccessAlert";

const UpdateZone = () => {
    const { locationId: locId, zoneId: zId } = useParams();

    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            locations: locId,
            oldLocationId: locId,
            zones: zId,
            name: "",
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(locId);
    const [locations, setLocations] = useState(null);
    const [zones, setZones] = useState(null);
    const [zoneId, setZoneId] = useState(zId);

    async function updateLocationReq(body) {
        setLoading(true);
        try {
            await axios.put(`${BASE_URL}/users/${userId}/locations/${locId}/zones/${zoneId}`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert("Zone updated successfully");
            setTimeout(()=>{
                setLoading(false);
                navigate("/admins/configurations/zones");
            }, 2000);

            // getLocations();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function getLocations(body) {
        setLoading(true);
        try {
            const {
                data: { locations },
            } = await axios.get(`${BASE_URL}/users/${userId}/locations`, {
                headers: {
                    Authorization: token,
                },
            });
            const locationsObj = prepareLocationsObj(locations);

            setLocations(locationsObj);
            if(locations && locations.length > 0) {
            const locateId = locationsObj.findIndex((val) => {
                return val._id === locId;
            })
            setZones(locationsObj[locateId].zones);
            //     const resetValues = {locations: locations[0]._id}
            //     setLocationId(resetValues.locations);
            //     if(locations[0].zones.length > 0 ){
            //         resetValues.zones = locations[0].zones[0]._id;
                    setZoneId(zId);
                    setValue('name', locationsObj[locateId].zones.find((zone) => zone._id === zId).name);
            //     }
            //     reset(resetValues);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }


    const prepareLocationsObj = (locations) => {
        for (let [key, value] of locations.entries()) {
            let selected = false;
            if (!key) selected = true;
            const zones = prepareZonesObj(value.zones);
            locations[key] = { _id: value._id, label: value.name, value: value._id, name: value.name, ...(zones ?? {zones}), selected }
        }
        return locations;
    }

    const prepareZonesObj = (zones) => {
        for (let [key, value] of zones.entries()) {
            let selected = false;
            if (!key) selected = true;
            zones[key] = { _id: value._id, label: value.name, value: value._id, name: value.name, selected }
        }
        return {zones};
    }

    function onChangeLocation(e) {
        const selectedIndex = e.target.selectedIndex;

        setLocationId(locations[selectedIndex]._id);
        let updatedLocation = [...locations];
        for (let [index, location] of updatedLocation.entries()) {
            if (index !== selectedIndex) {
                location.selected = false;
            } else {
                location.selected = true;
            }
        }
        setLocations(updatedLocation);
        // setZones(updatedLocation[selectedIndex].zones);
        setValue('locations', updatedLocation[selectedIndex]._id);
        if(updatedLocation[selectedIndex].zones && updatedLocation[selectedIndex].zones.length > 0){
            // setValue('zones', updatedLocation[selectedIndex].zones[0]._id);
            // setZoneId(updatedLocation[selectedIndex].zones[0]._id);
        }
    }

    function onChangeZone(e) {
        const selectedIndex = e.target.selectedIndex;

        setZoneId(locations[locationId].zones[selectedIndex]._id);
    }

    function onSubmit(data) {
        updateLocationReq(data);
    }

    useEffect(() => {
        getLocations();
    }, []);

    return (
        <DashboardLayout>
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/zones">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Update Zone</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Update"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        <div key="location" className="mt-3">
                        <Label required="true">Location</Label>
                        <CustomSelect
                            name={"locations"}
                            control={control}
                            errors={errors}
                            rules={{required: true }}
                            errorText={"Location is required"}
                            options={locations ? locations : []}
                            onHandleChange={onChangeLocation}
                        />
                        </div>
                        {/* <div key="zone" className="mt-3">
                        <Label required="true">Zone</Label>
                        <CustomSelect
                            name={"zones"}
                            control={control}
                            errors={errors}
                            rules={{required: true }}
                            errorText={"Zone is required"}
                            options={zones ? zones : []}
                            onHandleChange={onChangeZone}
                            disabled={true}
                        />
                        </div> */}
                        {ADD_LOCATION_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                    </DashboardCard>
                </form>
            </DashboardCard>
            {/* <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{display: "flex", flexDirection: "row"}}>
                <span style={{fontSize: "32px"}}>OR</span>
                <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/addLocation"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Add Location</small>
                    </ActionButton></Link>
                    <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/zones"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>All Zones</small>
                    </ActionButton></Link>
                    <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/addZone"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Add Zones</small>
                    </ActionButton></Link>
                    <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/updateZone"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Update Zone</small>
                    </ActionButton></Link>
                </div>
            </DashboardCard> */}
        </DashboardLayout>
    )
}

export default UpdateZone;