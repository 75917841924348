import React, { useEffect, useRef, useState } from "react";
import logoPath from '../assets/logo.png'
import { BASE_URL, SERVER_ICON_PATH } from "../constants";
import ShowErrorAlert from "../components/ErrorAlert";
import { extractErrorMessage } from "../utils";
import axios from "axios";

const useAppConfig = () => {

    const [loading, setLoading] = useState(false);
    const [appIconPath, setAppIconPath] = useState(logoPath);
    const [appName, setAppName] = useState("LAND DEPARTMENT");
    const [appConfig, setAppConfig] = useState(null);

    useEffect(() => {
        const icon = localStorage.getItem('appIcon');
        const name = localStorage.getItem('appName');
        if(icon) setAppIconPath(SERVER_ICON_PATH + icon);
        if(name) setAppName(name);
      }, []);

      async function getAppConfig(body) {
        setLoading(true);
        try {
          const {
            data: { appConfig },
          } = await axios.get(`${BASE_URL}/appConfig`);
          
          const appConfigObj = appConfig[0];
          setAppConfig(appConfigObj);

          localStorage.setItem('appName', appConfigObj?.name);
          localStorage.setItem('appIcon', appConfigObj?.icon);

          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
          ShowErrorAlert(extractErrorMessage(err));
        }
      }

    return {appIconPath, appName, getAppConfig};
}

export default useAppConfig;