import React, { useEffect, useState } from 'react'
import './dashboard.css'
import DashboardLayout from '../../components/DashboardLayout'
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { useSelector } from 'react-redux';
import ShowErrorAlert from '../../components/ErrorAlert';
import { extractErrorMessage } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';

const Dashboard = () => {

  const {
    auth: {
      user: { _id: userId, role },
      token,
    },
  } = useSelector((state) => state);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  async function getDashboardData() {
    setLoading(true);
    try {
      const {
        data,
      } = await axios.get(`${BASE_URL}/users/${userId}/dashboard`, {
        headers: {
          Authorization: token,
        },
      });
      setDashboardData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  useEffect(() => {
    getDashboardData();
  }, [])

  return (
    <DashboardLayout>
      <div className='dashboard'>
        <div className='dashboard_part'>
          <div className='dashboard_head'>
            <p className='dash_head_heading'>Dashboard</p>
          </div>
          <div className=' ps-3'>
            {
              loading ? <div className='d-flex justify-content-center align-items-center'><FiLoader size={60} className='rotate' /></div> : <div>

                {/* CS */}
                {role === "CS" || role === "Admin" ? <div class="dash_single_row">
                  <h4 class="dash_title">Customer Service</h4>
                  <div className='prf-status'>
                    <div class="report-highligter ">
                      <span class="type-highlighter">New Property</span>
                      <h3>{dashboardData && dashboardData['cutomerServiceData'] ? dashboardData['cutomerServiceData']["newPropertiesCount"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/cs/newProperty/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>

                    <div class="report-highligter ">
                      <span class="type-highlighter">Collections Done</span>
                      <h3>{dashboardData && dashboardData['cutomerServiceData'] ? dashboardData['cutomerServiceData']["collectionDone"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/cs/collection/download-pdf')} action="http://carmo.sogis.so/admin/dashboard/download-pdf" style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div> : null}

                {/* Finance */}
                {role === "Finance" || role === "Admin" ? <div class="dash_single_row">
                  <h4 class="dash_title">Finance</h4>
                  <div className='prf-status'>
                    <div class="report-highligter ">
                      <span class="type-highlighter">Cash Payments</span>
                      <h3>{dashboardData && dashboardData['financeData'] ? dashboardData['financeData']["cashPayments"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/finance/cash/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>

                    <div class="report-highligter ">
                      <span class="type-highlighter">Wallet Requests</span>
                      <h3>{dashboardData && dashboardData['financeData'] ? dashboardData['financeData']["walletRequests"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/finance/wallet/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>

                    <div class="report-highligter ">
                      <span class="type-highlighter">Collections</span>
                      <h3>{dashboardData && dashboardData['financeData'] ? dashboardData['financeData']["collectionDone"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/finance/collection/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div> : null}

                {/* GIS */}
                {role === "GIS_Manager" || role === "Admin" ? <div class="dash_single_row">
                  <h4 class="dash_title">GIS Team</h4>
                  <div className='prf-status'>
                    <div class="report-highligter ">
                      <span class="type-highlighter">Surveys Completed</span>
                      <h3>{dashboardData && dashboardData['gisData'] ? dashboardData['gisData']["surveysCompleted"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/gis/completed/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>

                    <div class="report-highligter ">
                      <span class="type-highlighter">Surveys Approved</span>
                      <h3>{dashboardData && dashboardData['gisData'] ? dashboardData['gisData']["surveysApproved"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/gis/approved/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>

                    <div class="report-highligter ">
                      <span class="type-highlighter">Surveys Pending</span>
                      <h3>{dashboardData && dashboardData['gisData'] ? dashboardData['gisData']["surveysPending"] : 0}</h3>
                      <form method="post" onClick={() => navigate('/admins/dashboard/gis/pending/download-pdf')} style={{ margin: '0' }}>
                        <button class="btn_download_report">
                          <i class="img"></i>
                          <span>Download Report</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div> : null}


                {/* ROLES */}
                <div class="dash_single_row">
                  {/* <h4 class="dash_title">Property Manager</h4> */}
                  <div className='prf-status'>

                    {role === "Prop Manager" || role === "Admin" ? <div style={{ maxWidth: '400px', width: '32%', marginRight: '15px' }}>
                      <h4 class="dash_title">Property Manager</h4>
                      <div class="report-highligter " style={{ width: '100%' }}>
                        <span class="type-highlighter">Properties Approved</span>
                        <h3>{dashboardData && dashboardData['rolesData'] ? dashboardData['rolesData']["propManagerApproved"] : 0}</h3>
                        <form method="post" onClick={() => navigate('/admins/dashboard/role/propertyManger/download-pdf')} style={{ margin: '0' }}>
                          <button class="btn_download_report">
                            <i class="img"></i>
                            <span>Download Report</span>
                          </button>
                        </form>
                      </div>
                    </div> : null}

                    {role === "Secretary" || role === "Admin" ? <div style={{ maxWidth: '400px', width: '32%', marginRight: '15px' }}>
                      <h4 class="dash_title">Secretary</h4>
                      <div class="report-highligter " style={{ width: '100%' }}>
                        <span class="type-highlighter">Properties Approved</span>
                        <h3>{dashboardData && dashboardData['rolesData'] ? dashboardData['rolesData']["secretaryApproved"] : 0}</h3>
                        <form method="post" onClick={() => navigate('/admins/dashboard/role/secretary/download-pdf')} style={{ margin: '0' }}>
                          <button class="btn_download_report">
                            <i class="img"></i>
                            <span>Download Report</span>
                          </button>
                        </form>
                      </div>
                    </div> : null}

                    {role === "Mayor" || role === "Admin" ? <div style={{ maxWidth: '400px', width: '32%', marginRight: '15px' }}>
                      <h4 class="dash_title">Mayor</h4>
                      <div class="report-highligter " style={{ width: '100%' }}>
                        <span class="type-highlighter">Properties Approved</span>
                        <h3>{dashboardData && dashboardData['rolesData'] ? dashboardData['rolesData']["mayorApproved"] : 0}</h3>
                        <form method="post" onClick={() => navigate('/admins/dashboard/role/mayor/download-pdf')} style={{ margin: '0' }}>
                          <button class="btn_download_report">
                            <i class="img"></i>
                            <span>Download Report</span>
                          </button>
                        </form>
                      </div>
                    </div> : null}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Dashboard