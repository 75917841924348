import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import CustomInput from "../../components/CustomInput";
import Label from "../../components/Label";
import {
  EDIT_USER_FIELDS,
  ROLE_SELECT_OPTIONS,
} from "./EditUser.constants";
import CustomSelect from "../../components/CustomSelect";
import DashboardCard from "../../components/DashboardCard";

const EditUser = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    values: {
      name: "",
      email: "",
      username: "",
      password: "",
      endDate: "",
    },
  });
  const navigate = useNavigate();
  const { userId: targetUserId } = useParams();

  // Redux Store
  const {
    auth: {
      token,
      user: { _id: userId },
    },
  } = useSelector((state) => state);

  // State Variables
  const [loading, setLoading] = useState(false);
  const [targetUserRole, setTargetUserRole] = useState(null);

  async function getUserDetailsReq() {
    setLoading(true);
    try {
      const {
        data: { user },
      } = await axios.get(`${BASE_URL}/users/${userId}/adminOperations/${targetUserId}`, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("username", user.username);
      setTargetUserRole(user.role);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function updateUserReq(body) {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/users/${userId}/adminOperations/${targetUserId}`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      navigate("/admins/users");
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    updateUserReq(data);
  }

  useEffect(() => {
    getUserDetailsReq();
  }, []);
  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between align-center mx-20 mt-5">
          <div className="flex align-center">
            <Link to="/admins/users">
              <MdOutlineArrowBackIosNew color="black" size={20} />
            </Link>
            <DashboardHeading>Edit User</DashboardHeading>
          </div>

          <ActionButton type="submit" disabled={loading}>
            {loading ? <ClipLoader color="white" size={20} /> : "Update"}
          </ActionButton>
        </div>
        {/* form starts here */}
        <DashboardCard>
          {targetUserRole && !loading ? EDIT_USER_FIELDS(targetUserRole).map(
            ({ name, required, label, type, rules, errorText }) => (
              <div key={name} className="mt-3">
                <Label required={required}>{label}</Label>
                <CustomInput
                  name={name}
                  type={type}
                  control={control}
                  rules={rules}
                  errors={errors}
                  errorText={errorText}
                />
              </div>
            )
          ) : <div className="text-center"><ClipLoader color="black" size={40} /></div>}
          <br />
        </DashboardCard>
      </form>
    </DashboardLayout>
  );
};

export default EditUser;
