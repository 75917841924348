
import React from "react";
import DashboardLayout from "../../components/DashboardLayout";
import ConfigurationsHeader from "./ConfigurationsHeader";

const Configurations = () => {
    return (
        <DashboardLayout >
            <ConfigurationsHeader />
        </DashboardLayout>
    )
}

export default Configurations;