
import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { ADD_LOCATION_FIELDS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import CustomSelect from "../../components/CustomSelect";
import { Button } from "react-bootstrap";
import ShowSuccessAlert from "../../components/SuccessAlert";

const UpdateLocation = () => {

    const { locationId: id } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        watch,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            name: "",
            location: id
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);
    const [locationId, setLocationId] = useState(id);
    const [locations, setLocations] = useState(null);

    async function updateLocationReq(body) {
        setLoading(true);
        try {
            await axios.put(`${BASE_URL}/users/${userId}/locations/${locationId}`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert('Location updated successfully');
            setTimeout(()=>{
                setLoading(false);
                navigate("/admins/configurations/locations");
            }, 2000);
            // getLocations();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function getLocations(body) {
        setLoading(true);
        try {
            const {
                data: { locations },
            } = await axios.get(`${BASE_URL}/users/${userId}/locations`, {
                headers: {
                    Authorization: token,
                },
            });

            const locationsObj = prepareLocationsObj(locations);
            setLocations(locationsObj);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    const prepareLocationsObj = (locations) => {
        for (let [key, value] of locations.entries()) {
            let selected = false;
            if (!key) selected = true;
            locations[key] = { _id: value._id, label: value.name, value: value._id, name: value.name, selected }
        }
        return locations;
    }

    function onChangeLocation(e) {
        const selectedIndex = e.target.selectedIndex;

        setLocationId(locations[selectedIndex]._id);
        setValue("location", locations[selectedIndex]._id);
    }

    function onSubmit(data) {
        updateLocationReq(data);
    }

    useEffect(() => {
        getLocations();
    }, []);

    return (
        <DashboardLayout>
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <span style={{cursor: "pointer"}} onClick={() => navigate("/admins/configurations/locations")}>
                                <MdOutlineArrowBackIosNew size={20} />
                            </span>
                            <DashboardHeading>Update Location</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Update"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        <div key="location" className="mt-3">
                            <Label required="true">Location</Label>
                            <CustomSelect
                                name={"location"}
                                control={control}
                                errors={errors}
                                rules={{required: true}}
                                errorText={"Location is required"}
                                options={locations ? locations : []}
                                onHandleChange={onChangeLocation}
                                disabled={true}
                            />
                        </div>
                        {ADD_LOCATION_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                    </DashboardCard>
                </form>
            </DashboardCard>
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ fontSize: "32px" }}>OR</span>
                    {/* <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/locations"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                            <FiEdit color="white" size={20} className="me-2" />
                            <small>All Locations</small>
                        </ActionButton></Link> */}
                    {/* <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/addLocation"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                            <FiEdit color="white" size={20} className="me-2" />
                            <small>Add Location</small>
                        </ActionButton></Link> */}
                    <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/zones/add"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                            <FiEdit color="white" size={20} className="me-2" />
                            <small>Add Zones</small>
                        </ActionButton></Link>
                    <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/zones/update"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                            <FiEdit color="white" size={20} className="me-2" />
                            <small>Update Zone</small>
                        </ActionButton></Link>
                </div>
            </DashboardCard>
        </DashboardLayout>
    )
}

export default UpdateLocation;