export const ROLE_SELECT_OPTIONS = [
    {
        label: "Administrator",
        value: "Admin",
    },
    {
        label: "Customer Service",
        value: "CS",
    },
    {
        label: "Finance",
        value: "Finance",
    },
    {
        label: "GIS",
        value: "GIS_Manger",
    },
    {
        label: "Surveyor",
        value: "GIS",
    },
    {
        label: "Mayor",
        value: "Mayor",
    },
    {
        label: "Property Manager",
        value: "Prop Manager",
    },
    {
        label: "Secretary",
        value: "Secretary",
    },
];

export const ADD_USER_FIELDS = (getValues) => [
    {
        name: "name",
        label: "Name",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Name is required",
    },
    {
        name: "username",
        label: "Username",
        required: true,
        type: "text",
        rules: { required: true },
        errorText: "Username is required",
    },
    {
        name: "email",
        label: "Email",
        required: false,
        type: "email",
        rules: { required: false },
        errorText: "Invalid Email",
    },
    {
        name: "password",
        label: "Password",
        required: true,
        type: "password",
        rules: { required: true, minLength: 8 },
        errorText: "Password must be 8 characters long",
    },
    {
        name: "confirmPassword",
        label: "Confirm Password",
        required: true,
        type: "password",
        rules: { validate: (value) => value == getValues("password") },
        errorText: "Password and Confirm Password are not matched",
    },
]