import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    BASE_URL,
} from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import { Col, Row } from "react-bootstrap";
import ActionButton from "../../components/ActionButton";
import { BsChatSquareText } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { TfiPrinter } from "react-icons/tfi";
import moment from "moment";
import BlueText from "../../components/BlueText";
import { RECORD_HISTORY_TABLE_DATA_KEYS, RECORD_HISTORY_TABLE_HEADINGS } from "./RequestSurvey.constants";
import useAppConfig from "../../hooks/useAppConfig";
import CustomMaps from "../../components/Maps/CustomMaps";

const SeeOnMaps = () => {
    // Redux Store
    const {
        auth: {
            user: { _id: userId, role },
            token,
        },
    } = useSelector((state) => state);
    const { appName, appIconPath } = useAppConfig()
    const { recordId, updateRecordRequestId } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        values: {
            name: "",
            file: "",
        },
    });

    // State Variables
    const [record, setRecord] = useState(null);
    const [owner, setOwner] = useState(null);
    const [updateRecordRequests, setUpdateRecordRequests] = useState([]);
    const [loading, setLoading] = useState(false);

    async function getRequestSurveyReq() {
        setLoading(true);
        try {
            const {
                data: { record: recordData, owner: ownerData, updateRecordRequests: updateRecordRequestsData },
            } = await axios.get(`${BASE_URL}/users/${userId}/records/${recordId}`, {
                headers: {
                    Authorization: token,
                },
            });
            setRecord(recordData);
            setOwner(ownerData);
            setUpdateRecordRequests(updateRecordRequestsData)
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    useEffect(() => {
        getRequestSurveyReq();
    }, []);

    const [closeDetail, setCloseDetail] = useState(false);

    return (
        // <DashboardLayout>
        <>
            <div className="d-flex flex-row" style={{height: '100%' }}>
                <div className="bg-white" style={{display: `${closeDetail ? 'none' : 'block'}`, flex: '1 1 auto', maxWidth: "518px", zIndex: 10, width: '100%'  }} >
                    <Row className="m-2">
                        <Col xl={2} lg={2} md={3} sm={4} >
                            <Link to={"/"}>
                                <img src={appIconPath} className="map-page-logo" />
                            </Link>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={8}>
                            <h2>{appName}</h2>
                        </Col>
                    </Row>
                    <DashboardCard >
                        <div className="map action-btns w-100">
                            <Row>
                                <h5 style={{ fontSize: "16px" }} className="flex-grow-1 mt-1 mb-3">Xiriirrada Degdegga Ah</h5>
                            </Row>
                            <Row className="w-100">
                                <Col xl={4} lg={4} md={4}>
                                    <Link to={`/admins/records/${recordId}/updateRecordRequests/create`} style={{ maxHeight: "42px" }}>
                                        <ActionButton style={{ maxHeight: "40px" }} className={"flex-grow-1 font-13 text-capitalize w-100"}>
                                            <FiEdit color="white" size={16} className="me-3" />
                                            <small>Update Record</small>
                                        </ActionButton>
                                    </Link>
                                </Col>
                                {record && record?.dues ? <Col xl={4} lg={4} md={4}>
                                    <Link to={`/admins/records/${recordId}/updateRecordRequests/${updateRecordRequests[0]?._id}/dues`}>
                                        <ActionButton style={{ maxHeight: "40px" }} className={"ms-3 flex-grow-1 font-13 text-capitalize w-100"}>
                                            <BsChatSquareText color="white" size={16} className="me-3" />
                                            <small>Dues Recovery</small>
                                        </ActionButton>
                                    </Link>
                                </Col> : null}
                                {<Col xl={4} lg={4} md={4}>
                                    <Link to={`/admins/records/${recordId}/certificate`}>
                                        <ActionButton style={{ maxHeight: "40px" }} className={"ms-3 flex-grow-1 font-13 text-capitalize w-100"}>
                                            <TfiPrinter color="white" size={16} className="me-3" />
                                            <small>Print Certificate</small>
                                        </ActionButton>
                                    </Link>
                                </Col>}
                            </Row>
                        </div>
                    </DashboardCard>

                    {
                        record && owner && (
                            <>
                                <Row className="m-2">
                                    <h6 className="text-uppercase">Property Details</h6>
                                </Row>
                                <div className="font-13">
                                    <Row className="ms-4">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Property ID: </p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{record.propertyId}</BlueText></div>

                                        </Col>
                                    </Row>
                                    <Row className="ms-4">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Cabbirka Dhulka: </p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{record.area} sq meter</BlueText></div>

                                        </Col>
                                    </Row>
                                    <Row className="ms-4">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Ciwaanka Waddada:</p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{record.address}</BlueText></div>

                                        </Col>
                                    </Row>

                                    <Row className="ms-4">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Taariikhda Diiwaangalinta:</p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{moment(record.createdAt).format("YYYY-MM-DD")}</BlueText></div>

                                        </Col>

                                    </Row>
                                    <hr className="carmo-hr" />
                                </div>

                                <Row className="m-2 pt-2">
                                    <h6 className="text-uppercase">Owner's Details</h6>
                                </Row>
                                <div className="font-13">
                                    <Row className="ms-4 ">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Magaca Mulkiilaha: </p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{owner.name}</BlueText></div>

                                        </Col>
                                    </Row>
                                    <Row className="ms-4">
                                        <Col xl={4} lg={4} md={4} className="text-end">
                                            <p>Lambarka Xiriirka: </p>
                                        </Col>
                                        <Col className="text-start">
                                            <div><BlueText>{<span>{`+25${owner.phoneNumber}`} <span className="text-secondary">{owner.phoneNumberVerified ? "(Verified)" : "(Unverified)"}</span></span>}</BlueText></div>
                                        </Col>
                                    </Row>
                                    <hr className="carmo-hr" />
                                </div>

                                <Row className="m-2 pt-2">
                                    <Col>
                                        <h6 className="text-uppercase">Property History</h6>
                                    </Col>
                                    <Col className="text-end ">
                                        <span className="text-secondary font-12 underline">View all</span>
                                    </Col>
                                </Row>

                                <Row className="map-page-table-container">
                                    <DashboardTable className="w-75"
                                        headings={RECORD_HISTORY_TABLE_HEADINGS}
                                        dataKeys={RECORD_HISTORY_TABLE_DATA_KEYS}
                                        data={updateRecordRequests.map((updateRecordRequest) => {
                                            if (record.dues > 0) {
                                                updateRecordRequest.action = "PAY DUES"
                                            }
                                            return updateRecordRequest;
                                        })}
                                    />
                                </Row>
                            </>
                        )
                    }

                </div>
                {/* <div xl={7} lg={7} md={7} sm={12} className="p-0"> */}
                    <div style={{ flex: '1 1 auto', position: 'relative' }}>
                        <div onClick={() => setCloseDetail(prevVal => !prevVal)} className="d-flex align-items-center justify-content-center"
                            style={{
                                backgroundColor: '#DADFDD', border: '1px solid #DADFDD', width: '32px', height: '72px',
                                position: 'absolute', zIndex: '9999', top: '40%', bottom: '50%', cursor: 'pointer'
                            }}>
                            <img width={19} height={35} src="/arrow_left.svg" alt="" />
                        </div>
                        {record && <CustomMaps height={closeDetail ? "100vh" : "100%"} owner={record.ownerName} mapCenter={Object.values(record.centroid)} coordinates={record?.coordinates} isDetailOpen={closeDetail}/>}
                    </div>
                {/* </div> */}
            </div>
        </>
        // </DashboardLayout>
    );
};

export default SeeOnMaps;
