export const RECORD_HISTORY_TABLE_HEADINGS = [
    "Owner Name",
    "Purchase Date",
    "Action",
  ];

  export const RECORD_HISTORY_TABLE_DATA_KEYS = [
    "ownerName",
    "createdAt",
    "actions",
  ];
  