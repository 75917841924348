
import React, { useState } from "react";
import ActionButton from "../../components/ActionButton";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { Link } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import DashboardCard from "../../components/DashboardCard";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { APP_CONFIG_FIELDS } from "./Config.constant";
import useAppConfig from "../../hooks/useAppConfig";
import DashboardLayout from "../../components/DashboardLayout";
import ConfigurationsHeader from "./ConfigurationsHeader";
import { FiEdit } from "react-icons/fi";

const AppConfig = () => {
    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        watch,
        setValue,
        reset
    } = useForm({
        values: {
            name: "",
            icon: "",
            file: ""
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);
    const { getAppConfig } = useAppConfig();

    async function updateAppConfigReq(body) {
        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/users/${userId}/appConfig`, body, {
                headers: {
                    Authorization: token,
                },
            });
            setLoading(false);
            reset();
            await getAppConfig();
            window.location.reload();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function uploadAppIconReq(body) {
        setLoading(true);
        try {
            const { data } = await axios.post(`${BASE_URL}/users/${userId}/appConfig/icon/upload`, body, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                },
            });
            setValue('icon', data.url)
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }


    function onFileChangeHandler(e) {
        const files = e.target.files;
        const formData = new FormData();
        formData.append('file', files[0]);
        uploadAppIconReq(formData);
    }

    function onSubmit(data) {
        updateAppConfigReq(data);
    }

    return (
        <DashboardLayout >
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>App Config</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Save"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        {APP_CONFIG_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        onFileChangeHandler={onFileChangeHandler}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                    </DashboardCard>
                </form>
            </DashboardCard>
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ fontSize: "32px" }}>OR</span>
                    <Link style={{ width: "fit-content", marginLeft: "24px" }} to="/admins/configurations/appConfig"  >
                        <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                            <FiEdit color="white" size={20} className="me-2" />
                            <small>App Config</small>
                        </ActionButton></Link>
                </div>
            </DashboardCard>
        </DashboardLayout>
    )
}

export default AppConfig;