import React from "react";
import moment from 'moment';

const PropertyReport = ({data}) => {

    return (
        <div className="data_dynamic_root">
        <span className="float-right"><small>Total Records : </small><strong>{data && data.length ? data.length : 0}</strong></span>
        <table cellPadding="0" cellSpacing="0" className="table_dynamic mx-0 w-100">
            <thead>
                <tr>
                    <th>Property Details</th>
                    <th>created At</th>
                    <th>Updated At</th>
                    <th>area</th>
                    <th>land_category</th>
                    <th>property Group</th>
                    <th>parent_id</th>
                    <th>extra</th>
                    {/* <th>status</th> */}
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 ? data.map((value) => {
                    return <tr>
                    <td>
                        <table className="subtable columns2" cellPadding="0" cellSpacing="0">
                            <tbody><tr>
                                <td><span>Property Code : </span><strong>{value.propertyId}</strong></td>
                            </tr>
                                <tr>
                                    <td><span>Owner Name : </span><strong>{value.ownerName}</strong></td>
                                </tr>
                                <tr>
                                    <td><span>Location : </span><strong>{value.location}</strong></td>
                                    <td><span>Zone : </span><strong>{value.zone}</strong></td>
                                </tr>
                                <tr>
                                    <td><span>Length x Width : </span><strong>{value.length + ' x ' + value.width}</strong></td>
                                </tr>

                            </tbody></table>

                    </td>
                    <td>
                        <span className="report_date">{moment(value.createdAt).format("YYYY-MM-DD")}</span><br></br>
                        <span className="report_time">{moment(value.createdAt).format("hh:mm:ss a")}</span>
                    </td>
                    <td>
                        <span className="report_date">{moment(value.updatedAt).format("YYYY-MM-DD")}</span><br></br>
                        <span className="report_time">{moment(value.updatedAt).format("hh:mm:ss a")}</span>
                    </td>
                    <td>{value.area ? value.area.toFixed(2) : 0}</td>
                    <td>{value.landCategory}</td>
                    <td>{value.landTitle}</td>
                    <td></td>
                    <td></td>
                    {/* <td>Processing</td> */}
                </tr>
                }) : null}
            
            </tbody>
        </table>
    </div>
    )
}

export default PropertyReport;
