import { AiOutlinePieChart, AiOutlineSetting, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsGear, BsTelephone } from "react-icons/bs";
import { MdOutlinePeopleOutline, MdOutlineAdminPanelSettings } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { ROLES_OF_ACTIONS } from "../../constants/index";

export const ROLE_BASED_SIDE_NAV_ITEMS = {
    Super_Admin: [
        {
            to: "/admins/configurations",
            icon: <BsGear size={20}/>,
            text: "Configurations"
        },
    ],
    Admin: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20}/>,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
        {
            to: "/service",
            icon: <BsTelephone size={20}/>,
            text: "Customer Service"
        },
        {
            to: "/admins/owners",
            icon: <AiOutlineUsergroupAdd size={20}/>,
            text: "Owners"
        },
        {
            to: "/admins/users",
            icon: <MdOutlineAdminPanelSettings size={20}/>,
            text: "Users"
        },
        {
            to: "/admins/configurations",
            icon: <BsGear size={20}/>,
            text: "Configurations"
        },
    ],
    CS: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20}/>,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
        // {
        //     to: "/service",
        //     icon: <BsTelephone size={20}/>,
        //     text: "Customer Service"
        // },
        {
            to: "/admins/owners",
            icon: <AiOutlineUsergroupAdd size={20}/>,
            text: "Owners"
        },
    ],
    Mayor: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20}/>,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
    ],
    Secretary: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20} />,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
    ],
    Finance: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20} />,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
    ],
    GIS_Manager: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20} />,
            text: "Dashboard"
        },
        {
            to: "/gis/records",
            icon: <CgNotes size={20} />,
            text: "Surveys"
        },
    ],
    [ROLES_OF_ACTIONS.propManagerAction]: [
        {
            to: "/dashboard",
            icon: <AiOutlinePieChart size={20} />,
            text: "Dashboard"
        },
        {
            to: "/admins/records",
            icon: <CgNotes size={20}/>,
            text: "Property"
        },
    ],
}