import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    BASE_URL,
} from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";

import { useForm } from "react-hook-form";

import Record from "../RecordDetails/RecordDetails.Record";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import { RECORD_HISTORY_TABLE_DATA_KEYS, RECORD_HISTORY_TABLE_HEADINGS } from "../RecordDetails/RecordDetails.constants";
import BlueText from "../../components/BlueText";

const RequestSurvey = () => {
    // Redux Store
    const {
        auth: {
            user: { _id: userId, role },
            token,
        },
    } = useSelector((state) => state);
    const { recordId, updateRecordRequestId } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        values: {
            name: "",
            file: "",
        },
    });

    // State Variables
    const [record, setRecord] = useState(null);
    const [owner, setOwner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateRecordRequest, setUpdateRecordRequest] = useState(null);
    const [survey, setSurvey] = useState(null);

    async function getRequestSurveyReq() {
        setLoading(true);
        try {
            const {
                data: { record: recordData, owner: ownerData },
            } = await axios.get(`${BASE_URL}/users/${userId}/records/${recordId}`, {
                headers: {
                    Authorization: token,
                },
            });
            setRecord(recordData);
            setOwner(ownerData);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function getUpdateRecordRequestDetails() {
        setLoading(true);
        try {
          const {
            data: {
              updateRecordRequest: updateRecordRequestData,
              surveys: surveyData
            },
          } = await axios.get(
            `${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          setUpdateRecordRequest(updateRecordRequestData);
          setSurvey(surveyData);
    
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
          ShowErrorAlert(extractErrorMessage(err));
        }
      }

    useEffect(() => {
        getRequestSurveyReq();
        getUpdateRecordRequestDetails();
    }, []);

    return (
        <DashboardLayout>
            {role === "CS" ?
                <Record
                    record={record}
                    owner={owner}
                    role={role}
                    recordId={recordId}
                    updateRecordRequest={updateRecordRequest}
                    survey={survey}
                /> : null
            }

            <DashboardCard>
                <div className="flex justify-between align-center">
                    <h5>Sahanka GIS</h5>
                </div>
                <DashboardTable
                    headings={RECORD_HISTORY_TABLE_HEADINGS}
                    dataKeys={RECORD_HISTORY_TABLE_DATA_KEYS}
                    data={survey && survey.length > 0 ? survey.map((survey) => {
                        survey.landCategory = survey.record.landCategory;
                        survey.ownerName = updateRecordRequest.ownerName;
                        survey.dues = survey.record.dues;
                        survey.actions = survey.status === "Scheduled" ? <BlueText style={{textDecorationLine: "underline"}}>Survey Scheduled</BlueText> : survey.status ? <BlueText style={{textDecorationLine: "underline"}}>Approved GIS Data</BlueText> : <BlueText style={{textDecorationLine: "underline"}}>Survey Rescheduled</BlueText>
                        return survey;
                    }) : []}
                />
            </DashboardCard>
        </DashboardLayout>
    );
};

export default RequestSurvey;
