
import React, { useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { ADD_SERVICE_TYPE_FIELDS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import ShowSuccessAlert from "../../components/SuccessAlert";

const AddServiceType = () => {

    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        reset,
        register
    } = useForm({
        values: {
            type: "",
            amount: 0,
            unit: ""
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);

    async function addServiceTypeReq(body) {
        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/users/${userId}/serviceTypes`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert("Service Type added successfully");

            setLoading(false);
            reset();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    function onSubmit(data) {
        addServiceTypeReq(data);
    }

    return (
        <DashboardLayout>
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/serviceTypes">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Add Service Type</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Add"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        {ADD_SERVICE_TYPE_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                        <div className="mt-3">
                            <Label required={true}>Unit</Label>
                            <div className="flex">
                                <Label for="fixed" className={"me-2"}>Fixed</Label>
                                <input required={true} {...register("unit")} id="fixed" name="unit" type="radio" value="fixed"/>
                                <div style={{width: "12px"}}></div>
                                <Label for="per_square_meter" className={"me-2"}>Per square meter</Label>
                                <input required={true} {...register("unit")} id="per_square_meter" name="unit" type="radio" value="per_square_meter"/>
                            </div>
                        </div>
                    </DashboardCard>
                </form>
            </DashboardCard>
            {/* <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{display: "flex", flexDirection: "row"}}>
                <span style={{fontSize: "32px"}}>OR</span>
                <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/serviceTypes"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>All Service Types</small>
                    </ActionButton></Link>
                <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/updateServiceType"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Update Service Type</small>
                    </ActionButton></Link>
                </div>
            </DashboardCard> */}
        </DashboardLayout>
    )
}

export default AddServiceType;