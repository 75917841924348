import { ToastContainer } from "react-toastify";
import DashboardHeader from "../dashboard_header/DashboardHeader";
import SideNav from "../side_nav/SideNav";
import { BACKGROUND_THEME_COLOR } from "../../constants";
import { useSelector } from "react-redux";

function DashboardLayout({ children }) {
  const {
    auth: {
      user: { role },
    },
  } = useSelector((state) => state);

  return (
    <div className="d-flex w-100">
      <ToastContainer />
      {role !== 'GIS' ? <SideNav /> : null}
      <div className="w-100">
        <DashboardHeader />
        <div style={{ backgroundColor: BACKGROUND_THEME_COLOR}}>{children}</div>
      </div>
    </div>
  );
}

export default DashboardLayout;
