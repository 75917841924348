import React from "react";
import moment from 'moment';

const GISPropertyReport = ({ data }) => {
    return (
        <div className="data_dynamic_root">
            <span className="float-right"><small>Total Records : </small><strong>{data && data.length ? data.length : 0}</strong></span>
            <table cellPadding="0" cellSpacing="0" className="table_dynamic mx-0 w-100">
                <thead>
                    <tr>
                        <th>Property Details</th>
                        <th>Location</th>
                        <th>Surveyor Detail</th>
                        <th>Survey Approver<br></br>Details</th>
                        <th>created_at</th>
                        <th>updated_at</th>
                        <th>Extra</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? data.map((value) => {
                        return <tr>
                            <td>
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0">
                                    <tbody><tr>
                                        <td><span>Property Code : </span><strong>{value.updateRecordRequest.recordId.propertyId}</strong></td>
                                    </tr>
                                        <tr>
                                            <td><span>Owner Name : </span><strong>{value.updateRecordRequest.recordId.ownerName}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Location : </span><strong>{value.updateRecordRequest.recordId.location}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Zone : </span><strong>{value.updateRecordRequest.recordId.zone}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Length x Width : </span><strong>{value.updateRecordRequest.recordId.length + ' x ' + value.updateRecordRequest.recordId.width }</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Prop. Group : </span><strong>{value.updateRecordRequest.recordId.landTitle}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Land Category : </span><strong>{value.updateRecordRequest.recordId.landCategory}</strong></td>
                                        </tr>
                                    </tbody></table>
                            </td>
                            <td align="top">
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0" style={{minWidth: "200px"}}>
                                    <tbody><tr>
                                        <td><span>country : </span><strong>SO</strong></td>
                                        <td><span>state : </span><strong className="capitalize">{value.updateRecordRequest.recordId.location}</strong></td>
                                        <td><span>city : </span><strong className="capitalize">{value.updateRecordRequest.recordId.zone}</strong></td>
                                        <td><span>ZipCode : </span><strong>{value.updateRecordRequest.recordId.zipCode}</strong></td>
                                        <td><span>latitude : </span><strong>{value.updateRecordRequest.recordId.centroid?.latitude ? value.updateRecordRequest.recordId.centroid.latitude : null}</strong></td>
                                        <td><span>longitude : </span><strong>{value.updateRecordRequest.recordId.centroid?.longitude ? value.updateRecordRequest.recordId.centroid.longitude : null}</strong></td>
                                    </tr>
                                    </tbody></table>
                            </td>
                            <td align="top">
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0" style={{minWidth: "200px"}}>
                                    <tbody><tr>
                                        <td><span>Is Surveyed : </span><strong>{value.status !== "Pending" ? "Yes" : "No"}</strong></td>
                                        <td><span>Surveyor Name : </span><strong>{value.assignedTo.name}</strong></td>
                                    </tr>
                                    </tbody></table>
                            </td>
                            <td align="top">
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0" style={{minWidth: "200px"}}>
                                    <tbody><tr>
                                        <td><span>Survey Approved? : </span><strong>{value.status === "Approved" ? "Yes" : "No"}</strong></td>
                                        {/* <td><span>Surveyor Approval Name : </span><strong></strong></td> */}
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <span className="report_date">{moment(value.createdAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.createdAt).format("hh:mm:ss a")}</span>
                            </td>
                            <td>
                                <span className="report_date">{moment(value.updatedAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.updatedAt).format("hh:mm:ss a")}</span>
                            </td>
                            <td></td>
                            <td>{value.updateRecordRequest.status}</td>
                        </tr>
                    }) : null}

                </tbody>
            </table>
        </div>
    );

}

export default GISPropertyReport;
