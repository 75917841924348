export const DUES_TABLE_HEADINGS = [
    "Owner Name",
    "Phone Number",
    "Type",
    "Amount",
    "Action",
  ];

  export const DUES_TABLE_DATA_KEYS = [
    "ownerName",
    "phoneNumber",
    "type",
    "dues",
    "actions",
  ];
  