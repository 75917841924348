import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { BASE_URL } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import DashboardTable from "../../components/DashboardTable";
import { USERS_TABLE_DATA_KEYS, USERS_TABLE_HEADINGS } from "./Users.constants";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { Switch } from "antd";
import BlueText from "../../components/BlueText";

const Users = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  // State Variables
  const [usersArray, setUsersArray] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);

  async function getAllUsers() {
    try {
      const {
        data: { users },
      } = await axios.get(`${BASE_URL}/users/${userId}/allUsers`, {
        headers: {
          Authorization: token,
        },
      });
      setUsersArray(users);
    } catch (err) {
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function changeStatusReq(targetUserId) {
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/adminOperations/${targetUserId}/disable`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getAllUsers();
    } catch (err) {
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onStatusChange(targetUserId, status) {
    if (!status) {
      changeStatusReq(targetUserId);
    }
  }

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <DashboardLayout>
      <div className="flex justify-between align-center mx-20 mt-5">
        <DashboardHeading>Users</DashboardHeading>
        <Link to="/admins/users/create" className="dash_head_link">
          <ActionButton>Add User</ActionButton>
        </Link>
      </div>
      <DashboardCard>
        <DashboardTable
          headings={USERS_TABLE_HEADINGS}
          dataKeys={USERS_TABLE_DATA_KEYS}
          data={
            usersArray.length
              ? usersArray.map((user) => ({
                  ...user,
                  actions: (
                    <>
                      <Link
                        className="text-decoration-none"
                        to={user.active && `/admins/users/${user._id}/edit`}
                        onClick={!user.active && ((event) => event.preventDefault())}
                      >
                        {!user.active ? <span className="text-secondary">Edit</span> : <BlueText>Edit</BlueText>}
                      </Link>
                    </>
                  ),
                  status: (user.role === "Mayor" || user.role === "Secretary" ? !user.active ? <span className="text-secondary">Deactivated</span> : <BlueText>Active</BlueText> :
                    <Switch
                      defaultChecked={user.active}
                      loading={statusLoading}
                      disabled={!user.active}
                      onChange={(status) => {
                        onStatusChange(user._id, status);
                      }}
                    />
                  ),
                }))
              : usersArray
          }
        />
      </DashboardCard>
    </DashboardLayout>
  );
};

export default Users;
