
import React, { useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import ConfigurationsHeader from "./ConfigurationsHeader";
import ShowSuccessAlert from "../../components/SuccessAlert";

const AddLandCategory = () => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        values: {
            name: "",
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);

    async function addLandCategoryReq(body) {
        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/users/${userId}/landCategory`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert("Land Category added successfully");
            setLoading(false);
            reset();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    function onSubmit(data) {
        addLandCategoryReq(data);
    }

    return (
        <DashboardLayout>
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/landCategory">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Add Land Category</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Add"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                    <div key={"name"} className="mt-3">
                                    <Label required={true}>{"Name"}</Label>
                                    <CustomInput
                                        name={"name"}
                                        type={"text"}
                                        control={control}
                                        rules={{required: true}}
                                        errors={errors}
                                        errorText={"Name field is required"}
                                    />
                                </div>
                    </DashboardCard>
                </form>
            </DashboardCard>
        </DashboardLayout>

    )
}

export default AddLandCategory;