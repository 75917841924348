export const SURVEY_RECORDS_TABLE_HEADINGS = ["DATE", "Property ID", "Location", "Address", "Land Size", "Action"];
export const SURVEY_RECORDS_TABLE_KEYS = ["createdAt", "propertyId", "location", "address", "area", "actions"];

export const ADD_COORDINATES_FIELDS = (onTextChange) => [
  {
    name: "node1",
    label: "Node 1",
    type: "text",
    required: true,
    onTextChange: onTextChange,
    rules: {required: true, pattern: {
      value: /^\s*\d+(\.\d+)?\s*,\s*\d+(\.\d+)?\s*$/,
      message: "Only numbers and one comma between two numbers are allowed.",
    }},
    errorText: "Only numbers and one comma between two numbers are allowed.",
    placeholder: 'lat, long'
  },
  {
    name: "node2",
    label: "Node 2",
    type: "text",
    required: true,
    onTextChange: onTextChange,
    rules: {required: true, pattern: {
      value: /^\s*\d+(\.\d+)?\s*,\s*\d+(\.\d+)?\s*$/,
      message: "Only numbers and one comma between two numbers are allowed.",
    }},
    errorText: "Only numbers and one comma between two numbers are allowed.",
    placeholder: 'lat, long'
  },
  {
    name: "node3",
    label: "Node 3",
    type: "text",
    required: true,
    onTextChange: onTextChange,
    rules: {required: true, pattern: {
      value: /^\s*\d+(\.\d+)?\s*,\s*\d+(\.\d+)?\s*$/,
      message: "Only numbers and one comma between two numbers are allowed.",
    }},
    errorText: "Only numbers and one comma between two numbers are allowed.",
    placeholder: 'lat, long'
  },
  {
    name: "node4",
    label: "Node 4",
    type: "text",
    required: true,
    onTextChange: onTextChange,
    rules: {required: true, pattern: {
      value: /^\s*\d+(\.\d+)?\s*,\s*\d+(\.\d+)?\s*$/,
      message: "Only numbers and one comma between two numbers are allowed.",
    }},
    errorText: "Only numbers and one comma between two numbers are allowed.",
    placeholder: 'lat, long'
  }
];