import React from 'react'
import './search_results.css';
import { Link } from 'react-router-dom';

const Search_results = () => {
  return (
    <div className='search-results'>
        <div className='search-results-headings'>
            <p className='search-results-heading'>Search Results</p>
            <p className='search-results-view'>View all</p>
        </div>
        {/* <div className='search-results-table'> */}
            <div className='content-table'>
                <thead>
                    <tr>
                        <th>Property ID</th>
                        <th>Property Owner</th>
                        <th>Location</th>
                        <th>Mao Lat/long</th>
                        <th>Pending dues</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{color:"#111827",fontWeight:'500'}}>956-235-6894</td>
                        <td>Noman</td>
                        <td>Aria Valley</td>
                        <td>No Survay</td>
                        <td>1000 SOS</td>
                        <td><Link to="/search" style={{color:'#1849F4',textTransform:'uppercase',fontWeight:'500'}}>Click here</Link></td>
                    </tr>
                    <tr>
                        <td style={{color:"#111827",fontWeight:'500'}}>956-235-6894</td>
                        <td>Noman</td>
                        <td>Aria Valley</td>
                        <td>No Survay</td>
                        <td>1000 SOS</td>
                        <td><Link to="/search" style={{color:'blue',textTransform:'uppercase'}}>Click here</Link></td>
                    </tr>
                </tbody>
            </div>
        {/* </div> */}
    </div>
  )
}

export default Search_results