import React, { useEffect, useState } from "react";
import "./owners.css";
import { Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { SlLocationPin } from "react-icons/sl";
import { BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import {
  OWNERS_TABLE_DATA_KEYS,
  OWNERS_TABLE_HEADINGS,
} from "./Owners.constants";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import BlueText from "../../components/BlueText";

const Owners = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    control,
  } = useForm({
    values: {
      name: "",
      email: "",
      phoneNumber: "",
    },
  });

  // State Variables
  const [ownersArray, setOwnersArray] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getOwnersReq(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { owners },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/owners?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setOwnersArray(owners);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    getOwnersReq(data);
  }

  useEffect(() => {
    getOwnersReq();
  }, []);

  return (
    <DashboardLayout>
      <div className="flex justify-between align-center mx-20 mt-5">
        <DashboardHeading>Owners</DashboardHeading>
        <Link to="/admins/owners/create" className="dash_head_link">
          <ActionButton>Add Owner</ActionButton>
        </Link>
      </div>

      <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex owners-form">
          <CustomInput
            name={"name"}
            errors={errors}
            placeholder="Name"
            control={control}
          />
          <CustomInput
            name={"email"}
            errors={errors}
            placeholder="Email"
            control={control}
            inputClassName="ms-3"
          />
          <CustomInput
            name={"phoneNumber"}
            errors={errors}
            placeholder="Phone Number"
            control={control}
            type="number"
            inputClassName="ms-3"
          />
          <ActionButton disabled={loading} className={"ms-3 w-100"}>
            {loading ? <ClipLoader size={22} color="white" /> : "Search"}
          </ActionButton>
        </form>
      </DashboardCard>
      {<DashboardCard>
        <DashboardTable
          headings={OWNERS_TABLE_HEADINGS}
          dataKeys={OWNERS_TABLE_DATA_KEYS}
          data={
            ownersArray.length
              ? ownersArray.map((owner) => ({
                  ...owner,
                  phoneNumberVerified: (
                    <>
                      <span>{owner.phoneNumberVerified ? 'Verified' : "Not Verified"}</span>
                    </>
                  ),
                  actions: (
                    <>
                      <Link
                        to={`/admins/owners/${owner._id}/edit`}
                        className="text-decoration-none"
                      >
                        <BlueText>Edit</BlueText>
                      </Link>
                    </>
                  ),
                }))
              : ownersArray
          }
        />
      </DashboardCard>}
    </DashboardLayout>
  );
};

export default Owners;
