import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import DashboardCard from "../../components/DashboardCard";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import {
  ADD_RECORD_FIELDS,
  ADD_RECORD_SELECT_DATA,
} from "./AddRecord.constants";
import ActionButton from "../../components/ActionButton";
import DashboardLayout from "../../components/DashboardLayout";

const AddRecord = () => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      ownerId: "",
      address: "",
      zipCode: "",
      length: "",
      width: "",
      location: "",
      zone: "",
      locationId: "",
      zoneId: "",
      landTitle: "",
      landTitleId: "",
      landCategory: "",
      updateReasonId: "",
      updateReason: "",
    },
    mode: "onTouched",
  });
  const navigate = useNavigate();

  // Redux Store
  const {
    auth: {
      token,
      user: { _id: userId },
    },
  } = useSelector((state) => state);

  let locationWatch;

  // State Variables
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const [locationId, setLocationId] = useState(false);
  const [locations, setLocations] = useState(null);
  const [landTitles, setLandTitles] = useState(null);
  const [landCategories, setLandCategories] = useState(null);
  const [updateReasons, setUpdateReasons] = useState(null);
  const [zones, setZones] = useState(null);
  const [zoneId, setZoneId] = useState(null);

  async function addRecordReq(body) {
    setLoading(true);
    try {
      await axios.post(`${BASE_URL}/users/${userId}/records`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      navigate("/admins/records");
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getAllOwners() {
    setLoading(true);
    try {
      const {
        data: { owners: ownersData },
      } = await axios.get(`${BASE_URL}/users/${userId}/owners`, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      setValue("ownerId", ownersData[0]?._id);
      setOwners(ownersData);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getLocations(body) {
    setLoading(true);
    try {
      const {
        data: { locations },
      } = await axios.get(`${BASE_URL}/users/${userId}/locations`, {
        headers: {
          Authorization: token,
        },
      });
      const locationsObj = prepareLocationsObj(locations);

      setLocations(locationsObj);

      if (locations && locations.length > 0) {
        setZones(locationsObj[0].zones);
        const resetValues = { locationId: locations[0]._id, location: locations[0].name }
        setValue("locationId", resetValues.locationId);
        setValue("location", resetValues.location);
        if (locations[0].zones.length > 0) {
          resetValues.zoneId = locations[0].zones[0]._id;
          resetValues.zone = locations[0].zones[0].name;
          setValue("zoneId", resetValues.zoneId);
          setValue("zone", resetValues.zone);
        }else {
          setValue("zone", "");
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getLandTitle(body) {
    setLoading(true);
    try {
      const {
        data: { landTitles },
      } = await axios.get(`${BASE_URL}/users/${userId}/landTitle`, {
        headers: {
          Authorization: token,
        },
      });
      const landTitleObj = prepareObj(landTitles);

      setLandTitles(landTitleObj);

      if (landTitles && landTitles.length > 0) {
        const resetValues = { landTitleId: landTitles[0]._id, landTitle: landTitles[0].name }
        setValue("landTitleId", resetValues.landTitleId);
        setValue("landTitle", resetValues.landTitle);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const prepareObj = (obj) => {
    for (let [key, value] of obj.entries()) {
      let selected = false;
      if (!key) selected = true;
      obj[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, selected }
    }
    return obj;
  }

  function onChangeLandTitle(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedLandTitles = [...landTitles];
    for (let [index, landTitle] of updatedLandTitles.entries()) {
      if (index !== selectedIndex) {
        landTitle.selected = false;
      } else {
        landTitle.selected = true;
      }
    }
    setLandTitles(updatedLandTitles);
    setValue('landTitleId', updatedLandTitles[selectedIndex]._id);
  }

  async function getLandCategory(body) {
    setLoading(true);
    try {
      const {
        data: { landCategories },
      } = await axios.get(`${BASE_URL}/users/${userId}/landCategory`, {
        headers: {
          Authorization: token,
        },
      });
      const landCategoriesObj = prepareObj(landCategories);

      setLandCategories(landCategoriesObj);

      if (landCategories && landCategories.length > 0) {
        const resetValues = { landCategoryId: landCategories[0]._id, landCategory: landCategories[0].name }
        setValue("landCategoryId", resetValues.landCategoryId);
        setValue("landCategory", resetValues.landCategory);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onChangeLandCategory(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedLandCategories = [...landCategories];
    for (let [index, landCategory] of updatedLandCategories.entries()) {
      if (index !== selectedIndex) {
        landCategory.selected = false;
      } else {
        landCategory.selected = true;
      }
    }
    setLandCategories(updatedLandCategories);
    setValue('landCategoryId', updatedLandCategories[selectedIndex]._id);
  }

  async function getUpdateReason(body) {
    setLoading(true);
    try {
      const {
        data: { updateReasons },
      } = await axios.get(`${BASE_URL}/users/${userId}/updateReason`, {
        headers: {
          Authorization: token,
        },
      });
      const updateReasonsObj = prepareObj(updateReasons);

      setUpdateReasons(updateReasonsObj);

      if (updateReasons && updateReasons.length > 0) {
        const resetValues = { updateReasonId: updateReasons[0]._id, updateReason: updateReasons[0].name }
        setValue("updateReasonId", resetValues.updateReasonId);
        setValue("updateReason", resetValues.updateReason);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onChangeUpdateReason(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedUpdateReasons = [...updateReasons];
    for (let [index, updateReason] of updatedUpdateReasons.entries()) {
      if (index !== selectedIndex) {
        updateReason.selected = false;
      } else {
        updateReason.selected = true;
      }
    }
    setUpdateReasons(updatedUpdateReasons);
    setValue('updateReasonId', updatedUpdateReasons[selectedIndex]._id);
  }

  const prepareLocationsObj = (locations) => {
    for (let [key, value] of locations.entries()) {
      let selected = false;
      if (!key) selected = true;
      const zones = prepareZonesObj(value.zones);
      locations[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, ...(zones ?? { zones }), selected }
    }
    return locations;
  }

  const prepareZonesObj = (zones) => {
    for (let [key, value] of zones.entries()) {
      let selected = false;
      if (!key) selected = true;
      zones[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, selected }
    }
    return { zones };
  }

  function onChangeLocation(e) {
    const selectedIndex = e.target.selectedIndex;

    setLocationId(locations[selectedIndex]._id);
    let updatedLocation = [...locations];
    for (let [index, location] of updatedLocation.entries()) {
      if (index !== selectedIndex) {
        location.selected = false;
      } else {
        location.selected = true;
      }
    }
    setLocations(updatedLocation);
    setZones(updatedLocation[selectedIndex].zones);
    setValue('locationId', updatedLocation[selectedIndex]._id);
    if (updatedLocation[selectedIndex].zones && updatedLocation[selectedIndex].zones.length > 0) {
      setValue('zoneId', updatedLocation[selectedIndex].zones[0]._id);
      setZoneId(updatedLocation[selectedIndex].zones[0]._id);
    }
  }

  function onChangeZone(e) {
    const selectedIndex = e.target.selectedIndex;

    setValue('zoneId', zones[selectedIndex]._id);
    setZoneId(locations[locationId].zones[selectedIndex]._id);
  }

  function onSubmit(data) {
    addRecordReq({
      ...data,
      ownerName: owners.find((owner) => owner._id === data.ownerId).name,
    });
  }

  useEffect(() => {
    getAllOwners();
    getLocations();
    getLandTitle();
    getLandCategory();
    getUpdateReason();
  }, []);

  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between align-center mx-20 mt-5">
          <div className="flex align-center">
            <Link to="/admins/records">
              <MdOutlineArrowBackIosNew size={20} />
            </Link>
            <DashboardHeading>Create Property</DashboardHeading>
          </div>
          <ActionButton type="submit" disabled={loading}>
            {loading ? <ClipLoader color="black" size={20} /> : "Create"}
          </ActionButton>
        </div>
        {/* form starts here */}
        <DashboardCard>
          <div className="flex">
            <div className="grow mx-20">
              <div
                className="flex align-center justify-between mt-3"
              >
                <Label required={true}>Owner</Label>
              </div>
              <div className="flex flex-row">
                <CustomSelect
                  name={"ownerId"}
                  control={control}
                  errors={errors}
                  errorText={"Owner is required"}
                  options={owners.map((owner) => ({
                    label: owner.name,
                    value: owner._id,
                  }))}
                />
                <Link to={"/admins/owners/create"} style={{alignSelf: "center"}}>
                  <ActionButton style={{ whiteSpace: "nowrap" }} className={'m-0 ms-2'} variant={"sm"}>Add New</ActionButton>
                </Link>
              </div>
              {ADD_RECORD_FIELDS.map(
                ({ name, required, label, type, rules, errorText }) => (
                  <div key={name} className="mt-3">
                    <Label required={required}>{label}</Label>
                    <CustomInput
                      name={name}
                      type={type}
                      control={control}
                      rules={rules}
                      errors={errors}
                      errorText={errorText}
                    />
                  </div>
                )
              )}
            </div>
            <div className="grow mx-20">
              {ADD_RECORD_SELECT_DATA(locationWatch, locations, zones, onChangeLocation, onChangeZone, 
              landTitles, onChangeLandTitle, landCategories, onChangeLandCategory, 
              updateReasons, onChangeUpdateReason).map(
                ({ name, required, label, options, errorText, rules, onHandleChange }) => (
                  <div key={name} className="mt-3">
                    <Label required={required}>{label}</Label>
                    <CustomSelect
                      name={name}
                      control={control}
                      errors={errors}
                      rules={rules}
                      errorText={errorText}
                      options={options}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </DashboardCard>
      </form>
    </DashboardLayout>
  );
};

export default AddRecord;
