import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import ActionButton from "../../components/ActionButton";
import { ClipLoader } from "react-spinners";

const RejectModal = ({ show, handleClose, userId, updateRecordRequestId, serviceId, token, updateServiceRequestStatusReq }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset
  } = useForm({
    values: {
        reason: "",
      }
  });

  const [loading, setLoading] = useState(false);

  function onSubmit(serviceId, status, reason) {
    reset();
    updateServiceRequestStatusReq(serviceId, status, reason);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Rejection Reason
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form encType="multipart/form-data" onSubmit={handleSubmit(() => onSubmit(serviceId, "Rejected", getValues('reason')))}>
          <Label required={true}>Reason</Label>
          <CustomInput
            name={"reason"}
            type={"text"}
            control={control}
            rules={{ required: true }}
            errors={errors}
            errorText={"Reason is required"}
          />
          <div className="mt-3 flex justify-center">
            <ActionButton disabled={loading}>{loading ? <ClipLoader color="white" size={18} /> : "Reject"}</ActionButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RejectModal;
