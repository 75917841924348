import React from 'react'
import logo from '../../assets/logo-2.png'
import logout from '../../assets/logout.png'
import './header.css'
const Header = () => {
  return (
    <div className='search'>
        <div className='search-header'>
            <div className='search-header-'>
            <img src={logo} alt='logo' className='search-logo'/>
            <p className='search-logo-name'>QARDHO LAND DEPARTMENT</p>
            </div>
            <div className='search-header-'>
                <p className='search-name'>Welcome <span>Asif</span>.</p>
                <img src={logout} alt='logout' className='search-logout'/>
            </div>
        </div>
        </div>
  )
}

export default Header