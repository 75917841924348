import { Circle, CircleMarker, FeatureGroup, MapContainer, Polygon, Polyline, Popup, Rectangle, TileLayer } from "react-leaflet";
import L from 'leaflet'; // Same as `import * as L from 'leaflet'` with webpack
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from "react";
import { getAreaOfPolygon, getCenterOfBounds } from "geolib";

const CustomMaps = ({owner, mapCenter, coordinates, isDetailOpen, height = '90vh'}) => {

    const [area, setArea] = useState(null);
    const [center, setCenter] = useState([9.5056194, 49.0849440]);

    useEffect(() => {
        if(coordinates && coordinates.length > 0){
            const area = getAreaOfPolygon(coordinates).toFixed(2);
            setArea(area);

            const convertedCoordinates = coordinates?.map(([lat, lon]) => ({
                latitude: lat,
                longitude: lon
              }));
          
            const center = getCenterOfBounds(convertedCoordinates);
        
            const valuesArray = Object.values(center);
        
            setCenter(valuesArray);
        }
    }, [coordinates]);
    // const center = [9.5056194, 49.0849440]

    // const polyline = [
    // [51.505, -0.09],
    // [51.51, -0.1],
    // [51.51, -0.12],
    // ]

    // const multiPolyline = [
    // [
    //     [51.5, -0.1],
    //     [51.5, -0.12],
    //     [51.52, -0.12],
    // ],
    // [
    //     [51.5, -0.05],
    //     [51.5, -0.06],
    //     [51.52, -0.06],
    // ],
    // ]

    // const polygon = [
    //     [51.515, -0.09],
    //     [51.52, -0.1],
    //     [51.52, -0.12],
    // ]

    // const multiPolygon = [
    // [
    //     [51.51, -0.12],
    //     [51.51, -0.13],
    //     [51.53, -0.13],
    // ],
    // [
    //     [51.51, -0.05],
    //     [51.51, -0.07],
    //     [51.53, -0.07],
    // ],
    // ]




    // [top, left],
    // [bottom, right]
   
    const rectangle = [
        [9.5056194, 49.0849440],
        [9.5057217, 49.0850853],
        [9.5056596, 49.0851309],
        [9.5055586, 49.0849882],
    ]

    const fillBlueOptions = { fillColor: 'blue' }

    // const blackOptions = { color: 'black' }
    // const blueOptions = { color: 'blue' }
    // const purpleOptions = { color: 'purple' }
    // const redOptions = { color: 'red' }


    if(!mapCenter) return null;

    if(mapCenter && mapCenter.length < 1) mapCenter = center;

    return (<MapContainer className="map" center={mapCenter} zoom={18} maxZoom={24} scrollWheelZoom={true} 
                key={isDetailOpen ? "open-state": "closed-state"}
                style={{height, width: '100%'}}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={24}
        />

        {coordinates && coordinates.length > 0 && (
        <FeatureGroup>
            <Popup><span><b>Owner:</b> {owner}</span><br></br><span><b>Area:</b> {area}</span><br></br><span><b>Property Co-ordinates:</b> {`Lat (${center[0]}), Long (${center[1]}) `}</span></Popup>
            <Polygon positions={coordinates} pathOptions={fillBlueOptions} />
        </FeatureGroup>
      )}
    </MapContainer>)
}

export default CustomMaps;