import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ADMINISTRATIVE_ROLES,
  BASE_URL, LIGHT_BLUE_COLOR,
} from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import { Controller, useForm } from "react-hook-form";

import { Col, Row } from "react-bootstrap";
import ActionButton from "../../components/ActionButton";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import BlueText from "../../components/BlueText";
import useAppConfig from "../../hooks/useAppConfig";
import { FaListUl } from "react-icons/fa";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { RECORDS_TABLE_DATA_KEYS, RECORDS_TABLE_HEADINGS, STATUS_OPTIONS, SURVEY_FIELDS } from "./GISRecords.constants";
import CustomSelect from "../../components/CustomSelect";
import CustomMaps from "../../components/Maps/CustomMaps";
import { getAreaOfPolygon, getCenterOfBounds } from 'geolib';
import ShowSuccessAlert from "../../components/SuccessAlert";
// import 'leaflet/dist/leaflet.css';

const GISUpdate = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId, role },
      token,
    },
  } = useSelector((state) => state);
  const { appName, appIconPath } = useAppConfig()
  const { recordId, updateRecordRequestId, surveyId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      assignedTo: "",
      landCategory: "",
      coordinates: "[]",
      area: "",
      status: "Approved"
    },
  });

  // State Variables
  const [record, setRecord] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [updateRecordRequests, setUpdateRecordRequests] = useState([]);
  const [coordinates, setCoordinates] = useState('');
  const [loading, setLoading] = useState(false);
  const [surveyors, setServeyors] = useState([{ label: "Select", value: "" }]);
  const [mapCenter, setMapCenter] = useState(null); // Initial center of the map

  const getAllSurveyors = async () => {
    setLoading(true);
    try {
      const queryObj = { role: "GIS" }
      const {
        data: { users },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/allUsers?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (users) {
        users.forEach((user) => {
          user.label = user.name;
          user.value = user._id;
        })
      }
      setServeyors((prev) => [...prev, ...users]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }


  async function getSurveyRecord(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { survey },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/survey/${surveyId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (survey) {
        survey.area = survey.width * survey.length + "sq/meter";
        survey.createdAt = moment(survey.createdAt).format("YYYY-MM-DD");
        survey.surveyBy = survey?.assignedTo?.name;
      }

      const convertedCoordinates = survey?.coordinates.map(([lat, lon]) => ({
        latitude: lat,
        longitude: lon
      }));

      let surveyDate = '', area = '', surveyBy = '', landCategory = '', center = [];

      if (convertedCoordinates && convertedCoordinates.length > 0) {
        center = getCenterOfBounds(convertedCoordinates);
      }
      if (survey?.surveyDate) {
        surveyDate = moment(survey?.surveyDate).format("YYYY-MM-DD")
      }
      if (survey.coordinates && survey.coordinates.length > 0) {
        area = getAreaOfPolygon(survey.coordinates).toFixed(2) + " sq/meters";
      }
      if (survey.assignedTo) {
        surveyBy = survey?.assignedTo?._id;
      }
      if (survey.assignedTo) {
        landCategory = survey?.record?.landCategory;
      }

      const valuesArray = Object.values(center);

      setMapCenter(valuesArray)

      reset({
        surveyBy, landCategory, area, status: "Approved",
        surveyDate, coordinates: '[\n' + survey.coordinates.map(arr => '\t[' + arr.join(', ') + ']').join(',\n') + '\n]'
      });

      setSurvey(survey);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getRequestSurveyReq() {
    setLoading(true);
    try {
      const {
        data: { record: recordData, owner: ownerData, updateRecordRequests: updateRecordRequestsData },
      } = await axios.get(`${BASE_URL}/users/${userId}/records/${recordId}`, {
        headers: {
          Authorization: token,
        },
      });
      setRecord(recordData);
      // setOwner(ownerData);
      setUpdateRecordRequests(updateRecordRequestsData)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  useEffect(() => {
    // getRequestSurveyReq();
    getAllSurveyors();
    getSurveyRecord();
  }, []);

  // setInterval(function() {   map.invalidateSize(); }, 100);


  const onHandleClick = () => {
    navigate('/gis/map')
  }

  function onSubmit(data) {
    console.log('onSubmit: ', data);
    // updateRecord(data);
    const {status} = data
    updateSurveyRecords({status})
  }

  function onChangeCategory(e) {
    // const selectedIndex = e.target.selectedIndex;

    // setValue('zoneId', zones[selectedIndex]._id);
    // setZoneId(locations[locationId].zones[selectedIndex]._id);
  }

  const updateSurveyRecords = async (body) => {
    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/survey/${survey._id}`, body,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      
      ShowSuccessAlert('Record updated successfully');

      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onChangeStatus(e) {
    // console.log('data', e.target.value);
    const data = { status: e.target.value };
    // updateSurveyRecords(data);
  }

  function onNavigateRoute(route) {
    navigate(route);
  }

  async function updateRecord(data) {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/users/${userId}/survey/${surveyId}`, data, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const [mapBounds, setMapBounds] = useState([[51.49, -0.08], [51.51, -0.06]]); // Initial bounds of the map

  const [closeDetail, setCloseDetail] = useState(false);

  return (
    <DashboardLayout style={{ height: '100vh' }}>

      <div className="d-flex flex-row" >

        {survey && <div style={{display: `${closeDetail ? 'none' : 'block'}`, flex: '1 1 auto', margin: "14px", maxWidth: "518px" }}>
          <div onClick={()=> navigate('/gis/records')} className="d-flex align-items-center justify-content-center" 
            style={{width: '32px', height: '72px', 
            position: 'absolute', zIndex: '9999', top: '10%', bottom: '50%', cursor: 'pointer'}}>
              <img width={19} height={35} src="/arrow_left.svg" alt="" />
          </div>
          <div className="font-13">
            <Row className="ms-4 ">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Property ID: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{survey.propertyId}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Cabbirka Dhulka: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{survey.area}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Ciwaanka Waddada:</p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{survey.address}</BlueText></div>
              </Col>
            </Row>

            <hr className="carmo-hr" />

            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column ">
              {SURVEY_FIELDS(onChangeCategory, surveyors).map(
                ({ name, required, label, type, options, rules, placeholder, errorText, disabled }) => (
                  <Row key={name} className="mt-3 flex flex-row" style={{ alignItems: "center" }}>
                    <Col xl={4} lg={4} md={4}><Label className="text-end" required={required}>{label}:</Label></Col>
                    <Col xl={8} lg={8} md={8}>
                      {
                        type !== "select" ? <CustomInput
                          name={name}
                          type={type}
                          control={control}
                          rules={rules}
                          errors={errors}
                          disabled={disabled}
                          errorText={errorText}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                          placeholder={placeholder}
                        /> : <CustomSelect
                          name={name}
                          control={control}
                          errors={errors}
                          rules={rules}
                          errorText={errorText}
                          options={options}
                          onHandleChange={onChangeCategory}
                          disabled={disabled}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                        />
                      }
                    </Col>
                  </Row>
                )
              )}
              <Row className="mt-3 ">
                <Col xl={4} lg={4} md={4}><Label className="text-end">Map Coordinates:</Label></Col>
                <Col xl={8} lg={8} md={8}>
                  <textarea {...register("coordinates")} name="coordinates" rows={6} style={{
                    color: "#1849F4",
                    fontWeight: "bold",
                    width: "100%"
                  }} disabled>

                  </textarea>
                </Col>
              </Row>
              <Row className="mt-3 ">
                <Col xl={4} lg={4} md={4}><Label className="text-end">Status:</Label></Col>
                <Col xl={8} lg={8} md={8}>
                  <Controller
                    name={'status'}
                    control={control}
                    rules={{}}
                    render={({ field: { onChange, onBlur, ref, value } }) => (
                      <>
                        <CustomSelect
                          name={"status"}
                          value={value}
                          control={control}
                          options={STATUS_OPTIONS}
                          errors={errors}
                          onHandleChange={onChangeStatus}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                          disabled={survey.status === "Approved" ? true : null}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row>

                <Col xl={4} lg={4} md={4}></Col>
                <Col xl={8} lg={8} md={8}>
                  <Row style={{ gap: "16px" }}>
                    <ActionButton onClick={() => onNavigateRoute('/dashboard/gis')} style={{ maxHeight: "40px", maxWidth: "140px" }} className={"mt-3 font-13 text-capitalize w-100"}>
                      <FaListUl color="white" size={16} className="me-3" />
                      <small>Table View</small>
                    </ActionButton>
                    <ActionButton disabled={survey.status === "Approved" ? true : null} type="submit" style={{ maxHeight: "40px", maxWidth: "160px" }} className={"mt-3 font-13 text-capitalize w-100"}>
                      <FiEdit color="white" size={18} className="me-3" />
                      <small>Update Record</small>
                    </ActionButton>
                  </Row>
                </Col>
              </Row>

            </form>
          </div>
        </div>}

        <div style={{flex: '1 1 auto', position: 'relative'}}>
          <div onClick={()=> setCloseDetail(prevVal => !prevVal)} className="d-flex align-items-center justify-content-center" 
          style={{backgroundColor: '#DADFDD', border: '1px solid #DADFDD', width: '32px', height: '72px', 
          position: 'absolute', zIndex: '9999', top: '40%', bottom: '50%', cursor: 'pointer'}}>
            <img width={19} height={35} src="/arrow_left.svg" alt="" />
          </div>
          {survey ? 
          <CustomMaps owner={survey.record.ownerName} mapCenter={mapCenter} coordinates={survey?.coordinates} isDetailOpen={closeDetail}/> :
          null
        }
        </div>
      </div>

    </DashboardLayout>
  )
};

export default GISUpdate;
