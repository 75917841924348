import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  LIGHT_BLUE_COLOR,
} from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import { UPDATE_REASON_OPTIONS } from "./UpdateRecordRequest.constants";
import CustomSelect from "../../components/CustomSelect";
import Label from "../../components/Label";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import Record from "../RecordDetails/RecordDetails.Record";

const UpdateRecordRequest = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId, role },
      token,
    },
  } = useSelector((state) => state);
  const { recordId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    values: {
      ownerId: "",
      updateReason: "",
      updateReasonId: ""
    },
  });

  // State Variables
  const [record, setRecord] = useState(null);
  const [owner, setOwner] = useState(null);
  const [owners, setOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateRecordRequests, setUpdateRecordRequests] = useState([]);
  const [updateReasons, setUpdateReasons] = useState([]);

  async function getRecordDetailsReq() {
    setLoading(true);
    try {
      const {
        data: {
          record: recordData,
          owner: ownerData,
          updateRecordRequests: updateRecordRequestsData,
        },
      } = await axios.get(`${BASE_URL}/users/${userId}/records/${recordId}`, {
        headers: {
          Authorization: token,
        },
      });

      updateRecordRequestsData.some((recordReqData) => {
        if(recordReqData.status === "Pending") {
          navigate(`/admins/records/${recordId}/updateRecordRequests/${recordReqData._id}/update`, { replace: "true" });
          return true;
        }
        return false;
      });

      setRecord(recordData);
      setOwner(ownerData);
      setUpdateRecordRequests(updateRecordRequestsData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getAllOwners() {
    try {
      const {
        data: { owners: ownersData },
      } = await axios.get(`${BASE_URL}/users/${userId}/owners`, {
        headers: {
          Authorization: token,
        },
      });
      setValue("ownerId", ownersData[0]?._id);
      setOwners(ownersData);
    } catch (err) {
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function createRecordRequestReq(body) {
    try {
      const { data: { updateRequest } } = await axios.post(`${BASE_URL}/users/${userId}/records/${recordId}/updateRecordRequests`, body, {
        headers: {
          Authorization: token,
        },
      });
      
        navigate(`/admins/records/${recordId}/updateRecordRequests/${updateRequest._id}/update`, { replace: "true" });
    } catch (err) {
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const prepareObj = (obj) => {
    for (let [key, value] of obj.entries()) {
      let selected = false;
      if (!key) selected = true;
      obj[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, selected }
    }
    return obj;
  }

  async function getUpdateReason(body) {
    setLoading(true);
    try {
      const {
        data: { updateReasons },
      } = await axios.get(`${BASE_URL}/users/${userId}/updateReason`, {
        headers: {
          Authorization: token,
        },
      });
      const updateReasonsObj = prepareObj(updateReasons);

      setUpdateReasons(updateReasonsObj);

      if (updateReasons && updateReasons.length > 0) {
        const resetValues = { updateReasonId: updateReasons[0]._id, updateReason: updateReasons[0].name }
        setValue("updateReasonId", resetValues.updateReasonId);
        setValue("updateReason", resetValues.updateReason);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onChangeUpdateReason(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedUpdateReasons = [...updateReasons];
    for (let [index, updateReason] of updatedUpdateReasons.entries()) {
      if (index !== selectedIndex) {
        updateReason.selected = false;
      } else {
        updateReason.selected = true;
      }
    }
    setUpdateReasons(updatedUpdateReasons);
    setValue('updateReasonId', updatedUpdateReasons[selectedIndex]._id);
  }

  function onSubmit(data) {
    createRecordRequestReq({
      ...data,
      ownerName: owners.find((owner) => owner._id === data.ownerId).name,
    });
  }

  function onChange(e) {
    setValue('ownerId', e.target.value)
  }

  useEffect(() => {
    getRecordDetailsReq();
    getAllOwners();
    getUpdateReason();
  }, []);

  return (
    <DashboardLayout>
      { role === "CS" ? 
        <Record
          record={record}
          owner={owner}
          role={role}
          recordId={recordId}
          // updateRecordRequest={approvedupdateRecordRequest}
        /> 
      // <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
      //   <div className="d-flex align-items-center action-btns">
      //     <h5 className="flex-grow-1 mt-1">Xiriirrada Degdegga Ah</h5>
      //     <ActionButton
      //       selected={true}
      //       className={"ms-3 align-items-center flex-grow-1"}
      //     >
      //       <FiEdit color={THEME_COLOR} size={20} className="me-2" />
      //       <small>Update Record</small>
      //     </ActionButton>
      //     <ActionButton className={"ms-3 flex-grow-1"} disabled={loading || (record && record.dues === 0) ? true : false}>
      //       <BsChatSquareText color="white" size={20} className="me-2" />
      //       <small>Dues Recovery</small>
      //     </ActionButton>
      //     <ActionButton className={"ms-3 flex-grow-1"} disabled>
      //       <SlLocationPin color="white" size={20} className="me-2" />
      //       <small>Request Survey</small>
      //     </ActionButton>
      //     <ActionButton className={"ms-3 flex-grow-1"}>
      //       <TfiPrinter color="white" size={20} className="me-2" disabled={updateRecordRequests && updateRecordRequests.status !== "Approved"}/>
      //       <small>Print Certificate</small>
      //     </ActionButton>
      //   </div>
      // </DashboardCard> 
      
      : null }
      {/* {record && owner && (
        <div className="mt-5">
          <DetailsRow
            leftLabel={"Property ID"}
            leftValue={record.propertyId}
            rightLabel="Taariikhda Diiwaangalinta"
            rightValue={moment(record.createdAt).format("YYYY-MM-DD")}
          />
          <DetailsRow
            leftLabel={"Length (In Meters)"}
            leftValue={record.length}
            rightLabel="Width (In Meters)"
            rightValue={record.width}
          />
          <DetailsRow
            leftLabel={"Cabbirka Dhulka (Sq Meters)"} // Area
            leftValue={record.length * record.width}
            rightLabel="Magaca Mulkiilaha"
            rightValue={
              <span className="d-flex align-items-center">
                {owner.name}{" "}
                {updateRecordRequests && updateRecordRequests.status === "Approved" && ADMINISTRATIVE_ROLES.includes(role) && (
                  <Link to={`/admins/owners/${owner._id}/edit`}>
                    <ActionButton variant={"sm"}>Edit Owner</ActionButton>
                  </Link>
                )}
              </span>
            }
          />
          <DetailsRow
            leftLabel={"Ciwaanka Waddada"}
            leftValue={record.address}
            rightLabel="Lambarka Xiriirka"
            rightValue={
              <span>
                {`+25${owner.phoneNumber}`}{" "}
                <span className="text-secondary">
                  {owner.phoneNumberVerified ? "(Verified)" : "(Unverified)"}
                </span>
              </span>
            }
          />
          <DetailsRow
            leftLabel={""}
            leftValue={""}
            rightLabel="Sahanka GIS"
            rightValue={
              record.centroid && record.centroid.length ? (
                `${record.centroid[0]}, ${record.centroid[1]}`
              ) : (
                <span className="text-danger">N/A</span>
              )
            }
          />
        </div>
      )} */}
      <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
        <h5>Details for Updation in Property Details</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between mt-3 ms-5 me-5">
          <div className="d-flex align-items-center" style={{ flex: "auto"}}> 
            <Label required={true} className="text-nowrap">Update Reason</Label>
            <CustomSelect
              name={"updateReason"}
              control={control}
              errors={errors}
              errorText={"Update Reason is required"}
              options={updateReasons}
              inputClassName="ms-3 w-50"
              onHandleChange={onChangeUpdateReason}
            />
          </div>
          <div className="d-flex ms-5 align-items-center justify-content-end" style={{ flex: "auto"}}>
            <Label required={true} className="text-nowrap">New Owner Name</Label>
            <CustomSelect
              name={"ownerName"}
              control={control}
              errors={errors}
              onHandleChange={(e) => onChange(e)}
              errorText={"New Owner Name is required"}
              options={owners.map((owner) => ({
                label: owner.name,
                value: owner._id,
              }))}
              inputClassName="ms-3 w-50"
            />
          </div>
        </div>
        <div className="mt-4"><ActionButton className={"ms-auto me-5 mb-2"}>{loading ? <ClipLoader color="white" /> : "Save & Create Service Request"}</ActionButton></div>
        </form>
      </DashboardCard>
      <br />
      <br />
    </DashboardLayout>
  );
};

export default UpdateRecordRequest;
