import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import {
  RECORDS_TABLE_DATA_KEYS,
  RECORDS_TABLE_HEADINGS,
  SURVEY_FIELDS,
} from "./GISRecords.constants";
import CustomInput from "../../components/CustomInput";
import { Controller, useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import BlueText from "../../components/BlueText";
import { CiLocationOn } from "react-icons/ci";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Label from "../../components/Label";
import CustomSelect from "../../components/CustomSelect";
import TextArea from "antd/es/input/TextArea";
import { getAreaOfPolygon } from 'geolib';
import ShowSuccessAlert from "../../components/SuccessAlert";

const GISRecords = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      assignedTo: "",
      landCategory: "",
      coordinates: ""
    },
  });

  const {
    handleSubmit: handleSearchSubmit,
    formState: { errors: searchErrors },
    control: searchControl,
  } = useForm({
    defaultValues: {
      propertyId: '',
      location: '',
      createdAt: ''
    },
  });

  // State Variables
  const [surveyArray, setSurveyArray] = useState([]);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [activeSurveyUpdate, setActiveSurveyUpdate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [surveyors, setServeyors] = useState([{ label: "Select", value: "" }]);

  const getAllSurveyors = async () => {
    setLoading(true);
    try {
      const queryObj = { role: "GIS" }
      const {
        data: { users },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/allUsers?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (users) {
        users.forEach((user) => {
          user.label = user.name;
          user.value = user._id;
        })
      }
      setServeyors((prev) => [...prev, ...users]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getAllSurveyRecords(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { surveyData },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/survey?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (surveyData) {
        surveyData.map((survey) => {
          survey.area = survey.width * survey.length + "sq/meter";
          survey.createdAt = moment(survey.createdAt).format("YYYY-MM-DD");
          survey.surveyBy = survey?.assignedTo?.name;
          return survey;
        });
      }
      // reset(surveyData);

      setSurveyArray(surveyData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const updateSurveyRecords = async (body) => {
    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/survey/${activeSurveyUpdate._id}`, body,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      ShowSuccessAlert("Surveyor updated successfully")
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSearchSubmit(data) {
    // console.log('data', data);
    getAllSurveyRecords(data);
    // updateSurveyRecords(data);
  }

  function onSubmit(data) {
    // console.log('data', data);
    // updateSurveyRecords(data);
  }

  useEffect(() => {
    getAllSurveyRecords();
    getAllSurveyors();
  }, []);

  useEffect(() => {
  }, [surveyArray]);

  function onChangeCategory(e) {
    // console.log('data', e.target.value);
    const data = { assignedTo: e.target.value };
    updateSurveyRecords(data);
  }
  const navigate = useNavigate();

  const onHandleClick = (surveyId) => {
    navigate(`/gis/${surveyId}/map`)
  }

  const handleToggleUpdate = (record) => {
    if (activeSurveyUpdate && activeSurveyUpdate.propertyId === record.propertyId) {
      setActiveSurveyUpdate(null);
      setToggleUpdate(false);
      return;
    }

    let surveyDate = '', area = '', surveyBy = '', landCategory = '';

    if(record?.surveyDate) {
      surveyDate = moment(record?.surveyDate).format("YYYY-MM-DD")
    }
    if(record.coordinates && record.coordinates.length > 0){
      area = getAreaOfPolygon(record.coordinates).toFixed(2) + " sq/meters";
    }
    if(record.assignedTo){
      surveyBy = record?.assignedTo?._id;
    }
    if(record.assignedTo){
      landCategory = record?.record?.landCategory;
    }

    const {coordinates} = record;
    reset({
      surveyBy, landCategory, area,
      surveyDate, coordinates:  '[\n' + coordinates.map(arr => '\t[' + arr.join(', ') + ']').join(',\n') + '\n]'
    });
    setActiveSurveyUpdate(record);
    setToggleUpdate(true);
  }

  return (
    <DashboardLayout>

      <div className="d-flex flex-row">

        <div style={{ flex: "1 1 auto" }}>
          <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
            <form onSubmit={handleSearchSubmit(onSearchSubmit)} className="d-flex records-form">
              <CustomInput
                name={"propertyId"}
                errors={searchErrors}
                placeholder="Property ID"
                control={searchControl}
              />
              <CustomInput
                name={"location"}
                errors={searchErrors}
                placeholder="Location"
                control={searchControl}
                inputClassName="ms-3"
              />
              <CustomInput
                name={"createdAt"}
                errors={searchErrors}
                placeholder="Registry Date"
                control={searchControl}
                type="date"
                inputClassName="ms-3"
              />
              <ActionButton disabled={loading} className={"ms-3 w-100"}>
                {loading ? <ClipLoader size={22} color="white" /> : "Search"}
              </ActionButton>
            </form>
          </DashboardCard>
          <div className="d-flex flex-row">
            {(<div className="records-table" style={{ flex: '1 1 auto' }}>
              <DashboardCard>
                <h5 className="mt-2 ms-1">Search Results</h5>
                <DashboardTable
                  headings={RECORDS_TABLE_HEADINGS}
                  dataKeys={RECORDS_TABLE_DATA_KEYS}
                  data={
                    surveyArray.length
                      ? surveyArray.map((record) => ({
                        ...record,
                        actions: (
                          <h3 onClick={() => handleToggleUpdate(record)} style={{
                            color: "#1849F4", textDecorationLine: "underline", lineHeight: "20px",
                            cursor: 'pointer', fontSize: '14px'
                          }}>Update</h3>
                        ),
                      }))
                      : surveyArray
                  }
                />
              </DashboardCard>
            </div>
            )}
          </div>
        </div>

        {toggleUpdate ? <div style={{ flex: '1 1 auto', margin: "20px" }}>
          <div className="font-13">
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Property ID: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.propertyId}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Cabbirka Dhulka: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.area}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Ciwaanka Waddada:</p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.address}</BlueText></div>
              </Col>
            </Row>

            <hr className="carmo-hr" />

            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column ">
              {SURVEY_FIELDS(onChangeCategory, surveyors).map(
                ({ name, required, label, type, options, rules, errorText, disabled }) => (
                  <Row key={name} className="mt-3 flex flex-row" style={{ alignItems: "center" }}>
                    <Col xl={4} lg={4} md={4}><Label required={required}>{label}:</Label></Col>
                    <Col xl={8} lg={8} md={8}>
                      {
                        type !== "select" ? <CustomInput
                          name={name}
                          type={type}
                          control={control}
                          rules={rules}
                          errors={errors}
                          errorText={errorText}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                          disabled={disabled}
                        /> : <CustomSelect
                          name={name}
                          control={control}
                          errors={errors}
                          rules={rules}
                          errorText={errorText}
                          options={options}
                          onHandleChange={onChangeCategory}
                          disabled={!disabled && activeSurveyUpdate.status === "Approved" ? true : disabled}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                        />
                      }
                    </Col>
                  </Row>
                )
              )}
              <Row className="mt-3 ">
                <Col xl={4} lg={4} md={4}><Label>Map Coordinates:</Label></Col>
                <Col xl={8} lg={8} md={8}>
                  <Controller
                    name={'coordinates'}
                    control={control}
                    rules={{}}
                    render={({ field: { onChange, onBlur, ref, value } }) => (
                      <>
                        <TextArea name="mapCoordinates" rows={6} style={{
                          color: "#1849F4",
                          fontWeight: "bold"
                        }}
                          value={value}
                          disabled
                        >

                        </TextArea>
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row>

                <Col xl={4} lg={4} md={4}></Col>
                <Col xl={8} lg={8} md={8}>
                  <ActionButton onClick={() => onHandleClick(activeSurveyUpdate._id)} style={{ maxHeight: "40px", maxWidth: "160px" }} className={"mt-3 font-13 text-capitalize w-100"}>
                    <CiLocationOn color="white" size={18} className="me-3" />
                    <small>View On Map</small>
                  </ActionButton>
                </Col>
              </Row>

            </form>
          </div>
        </div> : null}

      </div>

    </DashboardLayout>
  );
};

export default GISRecords;
