export const EDIT_USER_FIELDS = (targetUserRole) => {
  const fields = [
    {
      name: "name",
      label: "Name",
      required: true,
      type: "text",
      rules: { required: true },
      errorText: "Name is required",
    },
    {
      name: "username",
      label: "Username",
      required: true,
      type: "text",
      rules: { required: true },
      errorText: "Username is required",
    },
    {
      name: "email",
      label: "Email",
      required: false,
      type: "email",
      rules: { required: false },
      errorText: "Invalid Email",
    },
    {
      name: "password",
      label: "Password",
      required: false,
      type: "password",
      rules: { required: false },
      errorText: "Password is required",
    },
  ];

  if (targetUserRole === "Mayor" || targetUserRole === "Secretary") {
    fields.push({
      name: "endDate",
      label: "End Date",
      required: false,
      type: "date",
      rules: { required: false },
      errorText: "Invalid End Date",
    });
  }
  return fields;
};

export const CUSTOMER_GROUP_OPTIONS = [
  {
    label: "Co-operate",
    value: "Co-operate",
  },
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Public",
    value: "Public",
  },
];

export const ROLE_SELECT_OPTIONS = [
  {
    label: "Administrator",
    value: "Admin",
  },
  {
    label: "Customer Service",
    value: "CS",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "GIS / Survery",
    value: "GIS",
  },
  {
    label: "Mayor",
    value: "Mayor",
  },
  {
    label: "Property Manager",
    value: "Prop Manager",
  },
  {
    label: "Secretary",
    value: "Secretary",
  },
];
