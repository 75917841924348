import React from 'react'
import './postLogin_search.css';
import Search_results from "../../components/search_results/Search_results"
import Header from '../../components/header/Header';

const PostLogin_search = () => {
  return (
    <div className='search'>
       <Header/>

        <div className='search-bar'>
            <div className='search-bar-fields'>
                <input placeholder='Name' className='search-bar-field'/>
                <input placeholder='Property ID' className='search-bar-field'/>
                <input placeholder='Registry Date' className='search-bar-field'/>
                <button className="search-bar-btn">SEARCH</button>
            </div>
        </div>

        <Search_results/>
    </div>
  )
}

export default PostLogin_search