import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({allowedRole, children }) {
    const { auth: { user, token }} = useSelector(state => state);
    if(allowedRole && allowedRole === "Super_Admin"){
        if(user?.role === "Super_Admin"){
            return <React.Fragment>{children}</React.Fragment>;            
        } else {
            return <Navigate to={"/admins/configurations"} />;
        }
    }
    if(allowedRole && allowedRole === "GIS_Manager"){
        if(user?.role === "GIS_Manager"){
            return <React.Fragment>{children}</React.Fragment>;            
        } else {
            return <Navigate to={"/dashboard"} />;
        }
    }
    if(allowedRole && allowedRole === "GIS") {
        if(user?.role === "GIS"){
            return <React.Fragment>{children}</React.Fragment>;            
        } else {
            return <Navigate to={"/dashboard"} />;
        }
    }
    if(token) {
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        return <Navigate to={"/login"} />;
    }
}