import React, { useEffect, useState } from "react";
import "./certificate.css";
import axios from "axios";
import {
    BASE_URL,
} from "../../constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { extractErrorMessage } from "../../utils";
import ShowErrorAlert from "../ErrorAlert";
import moment from "moment";
import useAppConfig from "../../hooks/useAppConfig";
import { first } from "lodash";

function Certificate({ name, no, date, width, height, type, amount, totalAmount, discount, total, ...props }) {
    const { appName, appIconPath } = useAppConfig();
    // Redux Store
    const {
        auth: {
            user: { _id: userId, role },
            token,
        },
    } = useSelector((state) => state);
    const { recordId, updateRecordRequestId, requestId } = useParams();

    const [loading, setLoading] = useState(false);
    const [serviceRequest, setServiceRequests] = useState(null);
    const [record, setRecord] = useState(null);
    const [updateRecordRequest, setUpdateRecordRequest] = useState(null);
    const [receipt, setReceipt] = useState(null);

    const [owner, setOwner] = useState(null);
    const [updateRecordRequests, setUpdateRecordRequests] = useState([]);
    const [approvedupdateRecordRequest, setApprovedUpdateRecordRequest] = useState({});

    async function getRecordDetailsReq() {
        try {
          const { data: { record: recordData, owner: ownerData, updateRecordRequests: updateRecordRequestsData } } = await axios.get(
            `${BASE_URL}/users/${userId}/records/${recordId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setRecord(recordData);
          setOwner(ownerData);
          setUpdateRecordRequests(updateRecordRequestsData);
          setApprovedUpdateRecordRequest(() => {
            return first(updateRecordRequestsData.filter((val) => {
              return val.status === "Approved"
            }));
          });
        } catch (err) {
          console.log(err);
          ShowErrorAlert(extractErrorMessage(err));
        }
      }

    useEffect(() => {

        getRecordDetailsReq()
    }, []);

    return (
        <>
            {record && approvedupdateRecordRequest ? <div className="Certificate">

                <div className="container_parent">
                    <div className="container">
                        <table>
                            <tbody><tr className="row w-100 aic fjc">
                                <th>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="60" height="60" viewBox="0 0 60 60"><rect x="0" y="0" width="60" height="60" fill="#ffffff"></rect><g transform="scale(2.857)"><g transform="translate(0,0)"><path fillRule="evenodd" d="M9 0L9 2L8 2L8 3L9 3L9 4L10 4L10 5L11 5L11 4L12 4L12 7L11 7L11 6L10 6L10 7L9 7L9 6L8 6L8 8L4 8L4 10L3 10L3 11L1 11L1 12L3 12L3 11L4 11L4 12L6 12L6 13L7 13L7 12L6 12L6 11L7 11L7 10L5 10L5 9L8 9L8 11L9 11L9 12L8 12L8 17L9 17L9 18L8 18L8 21L9 21L9 19L11 19L11 20L12 20L12 21L13 21L13 20L14 20L14 19L15 19L15 21L19 21L19 20L20 20L20 21L21 21L21 18L20 18L20 17L21 17L21 15L20 15L20 16L19 16L19 17L18 17L18 14L16 14L16 13L17 13L17 12L20 12L20 13L19 13L19 14L21 14L21 11L20 11L20 10L19 10L19 8L18 8L18 9L17 9L17 10L16 10L16 9L15 9L15 8L13 8L13 4L12 4L12 3L13 3L13 2L12 2L12 1L13 1L13 0L11 0L11 1L10 1L10 0ZM10 2L10 4L11 4L11 2ZM10 7L10 8L11 8L11 7ZM0 8L0 9L3 9L3 8ZM12 8L12 9L11 9L11 12L10 12L10 13L9 13L9 14L11 14L11 13L12 13L12 15L14 15L14 16L12 16L12 18L11 18L11 19L12 19L12 20L13 20L13 19L14 19L14 18L15 18L15 19L16 19L16 20L17 20L17 19L20 19L20 18L18 18L18 17L17 17L17 15L16 15L16 14L15 14L15 13L16 13L16 12L17 12L17 11L18 11L18 10L17 10L17 11L16 11L16 10L15 10L15 9L14 9L14 10L13 10L13 8ZM12 10L12 11L13 11L13 12L12 12L12 13L13 13L13 14L14 14L14 15L15 15L15 16L14 16L14 17L13 17L13 18L12 18L12 19L13 19L13 18L14 18L14 17L15 17L15 16L16 16L16 15L15 15L15 14L14 14L14 13L15 13L15 12L14 12L14 11L15 11L15 10L14 10L14 11L13 11L13 10ZM13 12L13 13L14 13L14 12ZM9 15L9 16L10 16L10 15ZM16 18L16 19L17 19L17 18ZM0 0L0 7L7 7L7 0ZM1 1L1 6L6 6L6 1ZM2 2L2 5L5 5L5 2ZM14 0L14 7L21 7L21 0ZM15 1L15 6L20 6L20 1ZM16 2L16 5L19 5L19 2ZM0 14L0 21L7 21L7 14ZM1 15L1 20L6 20L6 15ZM2 16L2 19L5 19L5 16Z" fill="#000000"></path></g></g></svg>

                                    <h1 className="header-title">
                                        DAWLADDA PUNTLAND EE SOMALIA
                                    </h1>
                                </th>

                                <th>
                                    <img width={200} height={200} src={appIconPath} alt="" />
                                </th>

                                <th>
                                    <h2 className="header-id" style={{ color: 'red', marginBottom: "35px" }}>
                                        {record.propertyId}
                                    </h2>

                                    <h1 className="header-title">
                                        PUNTLAND STATE OF SOMALIA
                                    </h1>
                                </th>
                            </tr>
                            </tbody>
                        </table>

                        <div className="img_header_after">
                            <img src="http://carmo.sogis.so/vendor/orca/admin/assets/images/certificate-title-text.svg" alt="" />
                        </div>

                        <div className="content-summary">
                            <div className="single_row">
                                <span>
                                    <b>Date of purchase :</b> <span>{moment(approvedupdateRecordRequest.updatedAt).format("YYYY/MM/DD")}</span>
                                </span>
                            </div>

                            <div className="single_row">
                                <span>
                                    <b>Address :</b> <span>{record.address}</span>
                                </span>
                            </div>

                            <div className="single_row">
                                <span>
                                    <b>Area :</b> <span>{record.area.toFixed(2)} M<sup>2</sup></span>
                                </span>
                            </div>

                            <div className="single_row">
                                <span>
                                    <b>Property Coordinates :</b> <span>{Object.values(record.centroid)}</span>
                                </span>
                            </div>

                            <div className="owner-details">
                                <span className="owner-title">Owner of the land</span>
                                <h1 className="owner-name">
                                    {record.ownerName}
                                </h1>
                            </div>
                        </div>

                        <div className="print_footer">
                            <div className="footer_single">
                                <img src="http://carmo.sogis.so/storage" style={{ height: '90px' }} alt="" />
                                <h2>
                                    Secretary
                                </h2>
                                <h4>Secretary</h4>
                            </div>

                            <div className="footer_single">
                                <img src="http://carmo.sogis.so/vendor/orca/ui/assets/images/bw-logo.png" alt="" />
                            </div>

                            <div className="footer_single">
                                <img src="http://carmo.sogis.so/storage" style={{ height: '90px' }} alt="" />
                                <h2>
                                    Mayor user
                                </h2>
                                <h4>Mayor</h4>
                            </div>
                        </div>

                        <div className="footer_bottom">
                            <ol>
                                <li>
                                    Tani waa warqada lahaanshaha Hantida Ma Guurtada ah oo Asal ah. Lama ogola inla daabaaco.
                                </li>

                                <li>
                                    This is the original Property ownership certificate on the file. It is illegal to make copies of this certificate.
                                </li>
                            </ol>
                        </div></div>

                </div>
            </div> : null}
        </>
    );
}

export default Certificate;
