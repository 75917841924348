import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ADMINISTRATIVE_ROLES, BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import { FiEdit, FiInfo } from "react-icons/fi";
import { BsChatSquareText } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { TfiPrinter } from "react-icons/tfi";
import DetailsRow from "../../components/DetailsRow";
import moment from "moment/moment";
import { RECORD_HISTORY_TABLE_DATA_KEYS, RECORD_HISTORY_TABLE_HEADINGS } from "./RecordDetails.constants";
import Record from "./RecordDetails.Record.js";
import { find, first } from "lodash";
import { Tooltip } from 'react-tooltip'

const RecordDetails = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId, role },
      token,
    },
  } = useSelector((state) => state);
  const { recordId } = useParams();
  const navigate = useNavigate();

  // State Variables
  const [record, setRecord] = useState(null);
  const [owner, setOwner] = useState(null);
  const [updateRecordRequests, setUpdateRecordRequests] = useState([]);
  const [approvedupdateRecordRequest, setApprovedUpdateRecordRequest] = useState([]);

  async function getRecordDetailsReq() {
    try {
      const { data: { record: recordData, owner: ownerData, updateRecordRequests: updateRecordRequestsData } } = await axios.get(
        `${BASE_URL}/users/${userId}/records/${recordId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log('re', recordData)
      setRecord(recordData);
      setOwner(ownerData);
      setUpdateRecordRequests(updateRecordRequestsData);
      setApprovedUpdateRecordRequest(() => {
        return first(updateRecordRequestsData.filter((val) => {
          return val.status === "Approved"
        }));
      });
    } catch (err) {
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  useEffect(() => {
    getRecordDetailsReq();
  }, []);

  useEffect(() => {
    const updateRecordRequest = find(updateRecordRequests, (updateRecordRequest) => updateRecordRequest.status === "Pending");
    if (updateRecordRequest) {
      navigate(`updateRecordRequests/create`)
    }
  }, [updateRecordRequests]);

  return (
    <DashboardLayout>
      { role === "CS" ? 
      <Record
      record={record}
      owner={owner}
      role={role}
      recordId={recordId}
      updateRecordRequest={approvedupdateRecordRequest}
    /> : null }
      <DashboardCard>
        <div className="flex justify-between align-center">
          <h5>Taariikhda Hantida</h5>
        </div>
        <DashboardTable
          headings={RECORD_HISTORY_TABLE_HEADINGS}
          dataKeys={RECORD_HISTORY_TABLE_DATA_KEYS}
          data={updateRecordRequests.map((updateRecordReq, index) => {
            return {
              ...updateRecordReq, ...record, status: (
                <div className="flex">
                  <span>{updateRecordReq.status}</span>
                  {
                    updateRecordReq.status === "Rejected" ?
                      (<>
                        <FiInfo
                          size={18}
                          className="ms-2 cursor-pointer"
                          data-tooltip-id={"updateRecordReq-tooltip-"+index} data-tooltip-content={`${updateRecordReq.comments}`}
                        />
                        <Tooltip id={"updateRecordReq-tooltip-"+index} className="bg-white px-4 py-2 position-absolute"></Tooltip>
                      </>) :
                      null
                  }
                </div>
              )
            }
          })}
        />
      </DashboardCard>
    </DashboardLayout>
  );
};

export default RecordDetails;
