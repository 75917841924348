export const ADD_RECORD_FIELDS = [
  {
    name: "address",
    label: "Address",
    required: true,
    type: "text",
    rules: { required: true },
    errorText: "Address is required",
  },
  {
    name: "zipCode",
    label: "Zip Code",
    required: false,
    type: "text",
    rules: { required: false },
    errorText: "Invalid Zipcode",
  },
  {
    name: "length",
    label: "Property Length (in meters)",
    required: true,
    type: "number",
    rules: { required: true },
    errorText: "Length is required",
  },
  {
    name: "width",
    label: "Property Width (in meters)",
    required: true,
    type: "number",
    rules: { required: true },
    errorText: "Width is required",
  },
];

const LOCATION_OPTIONS = [
  {
    label: "MARTISOOR",
    value: "MARTISOOR",
  },
  {
    label: "MAKKA ALUMUKARAMA",
    value: "MAKKA ALUMUKARAMA",
  },
  {
    label: "HILAAC",
    value: "HILAAC",
  },
  {
    label: "HORSEED",
    value: "HORSEED",
  },
];

const ZONE_OPTIONS = {
  MARTISOOR: [
    {
      label: "DAYAXA",
      value: "DAYAXA",
    },
    {
      label: "BAXAYA",
      value: "BAXAYA",
    },
    {
      label: "GOLIS",
      value: "GOLIS",
    },
    {
      label: "WAABARI",
      value: "WAABARI",
    },
  ],
  "MAKKA ALUMUKARAMA": [
    {
      label: "REEBI",
      value: "REEBI",
    },
    {
      label: "KAAH",
      value: "KAAH",
    },
    {
      label: "HAWL WADAAG",
      value: "HAWL WADAAG",
    },
  ],
  HILAAC: [
    {
      label: "DARUURAHA",
      value: "DARUURAHA",
    },
    {
      label: "LAANBARWAAQO",
      value: "LAANBARWAAQO",
    },
    {
      label: "WADAJIR",
      value: "WADAJIR",
    },
  ],
  HORSEED: [
    {
      label: "DURDUR",
      value: "DURDUR",
    },
    {
      label: "NEW CARMO",
      value: "NEW CARMO",
    },
    {
      label: "SALWA GODEY",
      value: "SALWA GODEY",
    },
    {
      label: "CALISABIIX",
      value: "CALISABIIX",
    },
    {
      label: "HODAN",
      value: "HODAN",
    },
  ],
};

const LAND_TITLE_OPTIONS = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Public",
    value: "Public",
  },
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Corporate",
    value: "Corporate",
  },
];

const LAND_CATEGORY_OPTIONS = [
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Public",
    value: "Public",
  },
];

const UPDATE_REASON_OPTIONS = [
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Purchase",
    value: "Purchase",
  },
  {
    label: "Inheritance",
    value: "Inheritance",
  },
  {
    label: "Grant",
    value: "Grant",
  },
];

export const ADD_RECORD_SELECT_DATA = (locationWatch, locations, zones, onChangeLocation, onChangeZone, 
  landTitles, onChangeLandTitle, landCategories, onChangeLandCategory, updateReasons, onChangeUpdateReason) => [
  {
    name: "location",
    label: "Location",
    required: true,
    options: locations ? locations : [],
    errorText: "Address is required",
    onHandleChange: onChangeLocation
  },
  {
    name: "zone",
    label: "Zone",
    required: true,
    rules: {required: true},
    options: zones ? zones : [],
    errorText: "Address is required",
    onHandleChange: onChangeZone
  },
  {
    name: "landTitle",
    label: "Land Title",
    required: true,
    options: landTitles ? landTitles : [],
    errorText: "Land Title is required",
    onHandleChange: onChangeLandTitle
  },
  {
    name: "landCategory",
    label: "Land Category",
    required: true,
    options: landCategories ? landCategories : [],
    errorText: "Land Category is required",
    onHandleChange: onChangeLandCategory,
  },
  {
    name: "updateReason",
    label: "Update Reason",
    required: true,
    options: updateReasons ? updateReasons : [],
    errorText: "Update Reason is required",
    onHandleChange: onChangeUpdateReason,
  },
];
