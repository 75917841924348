import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import {
  SURVEY_RECORDS_TABLE_KEYS,
  SURVEY_RECORDS_TABLE_HEADINGS,
  ADD_COORDINATES_FIELDS,
} from "./Surveyor.constants";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import BlueText from "../../components/BlueText";
import { CiLocationOn } from "react-icons/ci";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Label from "../../components/Label";
import { ClipLoader } from "react-spinners";
import { FiEdit } from "react-icons/fi";
import ShowSuccessAlert from "../../components/SuccessAlert";
import { MdOutlinePreview } from "react-icons/md";
import CustomMapsModal from "./CustomMaps.Modal";
import { getAreaOfPolygon, getCenterOfBounds, isValidCoordinate } from "geolib";

const Surveyor = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitted, isValid },
    getFieldState,
    trigger,
    setError,
    control,
    getValues
  } = useForm({
    defaultValues: {
      node1: "",
      node2: "",
      node3: "",
      node4: "",
    }
  });


  const handleClose = () => onPreviewClicked();

  const {
    handleSubmit: handleSearchSubmit,
    formState: { errors: searchErrors },
    control: searchControl,
  } = useForm({
    defaultValues: {
      propertyId: '',
      location: '',
      createdAt: ''
    }
  });

  // State Variables
  const [surveyArray, setSurveyArray] = useState([]);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [isPreviewd, setIsPreviewd] = useState(false);
  const [center, setCenter] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [activeSurveyUpdate, setActiveSurveyUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [todos, dispatch] = useReducer(reducer, initialTodos);

  const handleToggleUpdate = (record) => {
    if (activeSurveyUpdate && activeSurveyUpdate.propertyId === record.propertyId) {
      setActiveSurveyUpdate(null);
      setToggleUpdate(false);
      return;
    }

    setActiveSurveyUpdate(record);
    setToggleUpdate(true);
  }

  async function getMySurveys(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { surveyData },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/survey?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (surveyData) {
        surveyData.map((survey) => {
          survey.area = survey.width * survey.length + "sq/meter";
          survey.createdAt = moment(survey.createdAt).format("YYYY-MM-DD");
          return survey;
        });
      }
      setSurveyArray(surveyData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const updateSurvey = async (body) => {
    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/survey/${activeSurveyUpdate._id}`, body,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      ShowSuccessAlert('Survey updated successfully');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function searchSurvey(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { surveyData },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/survey?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (surveyData) {
        surveyData.map((survey) => {
          survey.area = survey.width * survey.length + "sq/meter";
          survey.createdAt = moment(survey.createdAt).format("YYYY-MM-DD");
          return survey;
        });
      }
      setSurveyArray(surveyData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSearchSubmit(data) {
    getMySurveys(data);
  }

  function onSubmit(data) {
    const array_of_arrays = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && key.includes('node')) {
        const value = data[key];
        const numbers = value.split(",").map(Number);
        array_of_arrays.push(numbers);
        const isValid = isValidCoordinate(numbers);
        if(!isValid) {
          ShowErrorAlert('Coordinates Invalid');
          return;
        }
      }
    }

    const area = getAreaOfPolygon(array_of_arrays).toFixed(2);

    if(area > 500){
      ShowErrorAlert('Coordinates Invalid. Area should be less or equal to 500 sq/mter');
      return;
    }

    updateSurvey({ ...data, coordinates: array_of_arrays });
  }

  function onPreviewClicked() {
    if(isPreviewd) {
      setCoordinates(null);
      setCenter(null);
      setIsPreviewd(false);
      return;
    }
    const data = getValues();

    const array_of_arrays = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && key.includes('node')) {
        const value = data[key];
        const numbers = value.split(",").map(Number);
        array_of_arrays.push(numbers);
        const isValid = isValidCoordinate(numbers);
        if(!isValid) {
          ShowErrorAlert('Coordinates Invalid');
          return;
        }
      }
    }

    const area = getAreaOfPolygon(array_of_arrays).toFixed(2);

    if(area > 500){
      ShowErrorAlert('Coordinates Invalid. Area should be less or equal to 500 sq/mter');
      return;
    }

    const convertedCoordinates = array_of_arrays?.map(([lat, lon]) => ({
      latitude: lat,
      longitude: lon
    }));

    const center = getCenterOfBounds(convertedCoordinates);

    const valuesArray = Object.values(center);

    setCenter(valuesArray);
    setCoordinates(array_of_arrays);
    setIsPreviewd(true);
  }

  useEffect(() => {
    getMySurveys();
  }, []);

  const onTextChange = (e) => {
    trigger(e.target.name)
  }

  return (
    <DashboardLayout>

      <div className="d-flex flex-row justify-content-between">

        <div style={{ flex: "1 1 auto" }}>
          <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
            <form onSubmit={handleSearchSubmit(onSearchSubmit)} className="d-flex records-form">
              <CustomInput
                name={"propertyId"}
                errors={searchErrors}
                placeholder="Property ID"
                control={searchControl}
              />
              <CustomInput
                name={"location"}
                errors={searchErrors}
                placeholder="Location"
                control={searchControl}
                inputClassName="ms-3"
              />
              <CustomInput
                name={"createdAt"}
                errors={searchErrors}
                placeholder="Registry Date"
                control={searchControl}
                type="date"
                inputClassName="ms-3"
              />
              <ActionButton type="submit" disabled={loading} className={"ms-3 w-100"}>
                {loading ? <ClipLoader size={22} color="white" /> : "Search"}
              </ActionButton>
            </form>
          </DashboardCard>
          <div className="d-flex flex-row">
            {(<div className="records-table" style={{ flex: '1 1 auto' }}>
              <DashboardCard>
                {/* <h5 className="mt-2 ms-1">Search Results</h5> */}
                <DashboardTable
                  headings={SURVEY_RECORDS_TABLE_HEADINGS}
                  dataKeys={SURVEY_RECORDS_TABLE_KEYS}
                  data={
                    surveyArray.length
                      ? surveyArray.map((record) => ({
                        ...record,
                        actions: (
                          <h3 onClick={() => handleToggleUpdate(record)} style={{
                            color: "#1849F4", textDecorationLine: "underline", lineHeight: "20px",
                            cursor: 'pointer', fontSize: '14px'
                          }}>Update</h3>
                        ),
                      }))
                      : surveyArray
                  }
                />
              </DashboardCard>
            </div>
            )}
          </div>
        </div>

        {toggleUpdate ? <div style={{ flex: '1 1 auto', margin: "24px", maxWidth: "435px" }}>
          <div className="font-13 d-flex flex-column" >
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Property ID: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.propertyId}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Cabbirka Dhulka: </p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.area}</BlueText></div>

              </Col>
            </Row>
            <Row className="ms-4">
              <Col xl={5} lg={5} md={5} className="text-end">
                <p>Ciwaanka Waddada:</p>
              </Col>
              <Col className="text-start">
                <div><BlueText>{activeSurveyUpdate.address}</BlueText></div>
              </Col>
            </Row>

            <hr className="carmo-hr" />
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-self-end w-100">
              {ADD_COORDINATES_FIELDS(onTextChange).map(
                ({ name, required, label, type, options, rules, errorText, placeholder, onTextChange }) => (
                  <Row key={name} className=" m-0  mt-3 flex flex-row align-items-center" >
                    <Col xl={4} lg={4} md={4}><Label required={required}>{label}:</Label></Col>
                    <Col xl={8} lg={8} md={8}>
                      {
                        // <Row>
                        //   <Col xl={6} lg={6} md={6}>
                        <CustomInput
                          isValid={isValid}
                          name={name}
                          type={type}
                          onTextChange={onTextChange}
                          control={control}
                          rules={rules}
                          errors={errors}
                          errorText={errorText}
                          placeholder={placeholder}
                          style={{
                            color: "#1849F4",
                            fontWeight: "bold"
                          }}
                        />
                        //   </Col>
                        // </Row>
                      }
                    </Col>
                  </Row>
                )
              )}
              <Row className="m-0 mt-3 align-items-center">
                <Col xl={4} lg={4} md={4}><Label required="true">Survey Date:</Label></Col>
                <Col xl={8} lg={8} md={8}>
                  <CustomInput
                    name={'surveyDate'}
                    type={'date'}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                    errorText={'Survey Date is required'}
                    style={{
                      color: "#1849F4",
                      fontWeight: "bold"
                    }}
                  />
                </Col>
              </Row>
              <Row>

                <Col xl={3} lg={3} md={3}></Col>
                <Col xl={9} lg={9} md={9}>
                  <Row style={{ gap: "12px" }}>
                    <ActionButton type="button" onClick={() => onPreviewClicked()} style={{ maxHeight: "40px", maxWidth: "160px" }} className={"m-o mt-3 font-14 text-capitalize w-100"}>
                      <MdOutlinePreview color="white" size={16} className="me-2" />
                      <small>Preview</small>
                    </ActionButton>
                    <ActionButton type="submit" style={{ maxHeight: "40px", maxWidth: "160px" }} className={"m-o mt-3 font-14 text-capitalize w-100"}>
                      <FiEdit color="white" size={16} className="me-2" />
                      <small>Update</small>
                    </ActionButton>
                  </Row>
                </Col>
              </Row>

            </form>
          </div>
        </div> : null}
        {
          isPreviewd ?
          <CustomMapsModal handleClose={handleClose} show={isPreviewd} mapCenter={center} coordinates={coordinates} owner={activeSurveyUpdate?.record.ownerName}
        /> : null
        }

      </div>

    </DashboardLayout>
  );
};

export default Surveyor;
