
import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { ADD_SERVICE_TYPE_FIELDS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import CustomSelect from "../../components/CustomSelect";
import ShowSuccessAlert from "../../components/SuccessAlert";

const UpdateServiceType = () => {
    const { serviceTypeId } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        watch,
        setValue,
        reset,
        register
    } = useForm({
        defaultValues: {
            type: "",
            amount: 0,
            updatetype: serviceTypeId,
            unit: ""
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);
    const [serviceId, setServiceId] = useState(serviceTypeId);
    const [serviceTypes, setServiceTypes] = useState(null);

    async function updateServiceTypeReq(body) {
        setLoading(true);
        try {
            await axios.put(`${BASE_URL}/users/${userId}/serviceTypes/${serviceId}`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert("Service Type updated successfully");
            setTimeout(() => {
                setLoading(false);
                navigate("/admins/configurations/serviceTypes");
            }, 2000);
            // getServices();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function getServices(body) {
        setLoading(true);
        try {
            const {
                data: { serviceTypes },
            } = await axios.get(`${BASE_URL}/users/${userId}/serviceTypes`, {
                headers: {
                    Authorization: token,
                },
            });

            const serviceTypesObj = prepareServiceTypesObj(serviceTypes);
            setServiceTypes(serviceTypesObj);

            if (serviceTypes && serviceTypes.length > 0) {
                const index = serviceTypesObj.findIndex((val) => {
                    return val._id === serviceTypeId;
                });
                reset({ type: serviceTypes[index].label, amount: serviceTypes[index].amount, unit: serviceTypes[index].unit });
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    const prepareServiceTypesObj = (service) => {
        for (let [key, value] of service.entries()) {
            let selected = false;
            if (!key) selected = true;
            service[key] = { _id: value._id, label: value.type, value: value._id, amount: value.amount, selected, unit: value.unit }
        }
        return service;
    }

    function onChangeServiceType(e) {
        const selectedIndex = e.target.selectedIndex;
        // const selecteAmount = serviceTypes[selectedIndex].amount;
        setServiceId(serviceTypes[selectedIndex]._id);
        let updatedServiceType = [...serviceTypes];
        for (let [index, serviceType] of updatedServiceType.entries()) {
            if (index !== selectedIndex) {
                serviceType.selected = false;
            } else {
                serviceType.selected = true;
            }
        }
        setServiceTypes(updatedServiceType);
        reset({ type: serviceTypes[selectedIndex].label, amount: serviceTypes[selectedIndex].amount })
        // setServiceAmount(selecteAmount);
    }

    function onSubmit(data) {
        updateServiceTypeReq(data);
    }

    useEffect(() => {
        getServices();
    }, []);

    return (
        <DashboardLayout>
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/serviceTypes">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Update Service Type</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Update"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        <CustomSelect
                            name={"updatetype"}
                            control={control}
                            errors={errors}
                            errorText={"Service Type is required"}
                            options={serviceTypes ? serviceTypes : []}
                            onHandleChange={onChangeServiceType}
                            disabled={true}
                        />
                        {ADD_SERVICE_TYPE_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                        <div className="mt-3">
                            <Label required={true}>Unit</Label>
                            <div className="flex">
                                <Label for="fixed" className={"me-2"}>Fixed</Label>
                                <input required={true} {...register("unit")} id="fixed" name="unit" type="radio" value="fixed" />
                                <div style={{ width: "12px" }}></div>
                                <Label for="per_square_meter" className={"me-2"}>Per square meter</Label>
                                <input required={true} {...register("unit")} id="per_square_meter" name="unit" type="radio" value="per sqm" />
                            </div>
                        </div>
                    </DashboardCard>
                </form>
            </DashboardCard>
            {/* <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{display: "flex", flexDirection: "row"}}>
                <span style={{fontSize: "32px"}}>OR</span>
                <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/serviceTypes"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>All Service Types</small>
                    </ActionButton></Link>
                <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/addServiceType"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Add Service Type</small>
                    </ActionButton></Link>
                </div>
            </DashboardCard> */}
        </DashboardLayout>
    )
}

export default UpdateServiceType;