import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL, LIGHT_BLUE_COLOR } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import {
  SERVICE_TABLE_DATA_KEYS,
  SERVICE_TABLE_HEADINGS,
} from "./Service.constants";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import BlueText from "../../components/BlueText";
import { BsArrowRightCircleFill } from "react-icons/bs";

const Service = () => {

   // Redux Store
   const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    control,
  } = useForm({
    values: {
      _id: "",
      status: "",
      createdAt: "",
    },
  });

  // State Variables
  const [recordsArray, setRecordsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getAllRecords(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { serviceRequests },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/serviceRequests?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setRecordsArray(serviceRequests);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    getAllRecords(data);
  }

  useEffect(() => {
    getAllRecords();
  }, []);

  return (
    <DashboardLayout>
    <div className="flex justify-between align-center mx-20 mt-5">
      <DashboardHeading>Customer Service</DashboardHeading>
      {/* <Link to="/admins/records/create" className="dash_head_link">
        <ActionButton>Create Request</ActionButton>
      </Link> */}
    </div>
    <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex service-form">
        {/* <CustomInput
          name={"_id"}
          errors={errors}
          placeholder="ID"
          control={control}
        /> */}
        <CustomInput
          name={"status"}
          errors={errors}
          placeholder="Status"
          control={control}
          inputClassName="ms-3"
        />
        <CustomInput
          name={"createdAt"}
          errors={errors}
          placeholder="Registry Date"
          control={control}
          type="date"
          inputClassName="ms-3"
        />
        <ActionButton disabled={loading} className={"ms-3 w-100"}>
          {loading ? <ClipLoader size={22} color="white" /> : "Search"}
        </ActionButton>
      </form>
    </DashboardCard>
    {(
      <DashboardCard>
        <h5 className="mt-2 ms-1">Search Results</h5>
        <DashboardTable
          headings={SERVICE_TABLE_HEADINGS}
          dataKeys={SERVICE_TABLE_DATA_KEYS}
          data={
            recordsArray.length
              ? recordsArray.map((record) => ({
                  ...record,
                  actions: (
                    <Link to={`/admins/records/${record._id}`}>
                        <BsArrowRightCircleFill size={24} />
                    </Link>
                  ),
                  centroid:
                    record.centroid && record.centroid.length ? (
                      `${record.centroid[0]},${record.centroid[1]}`
                    ) : (
                      <span className="text-danger">No Survey</span>
                    ),
                  dues: record.dues ? (
                    <span className="text-danger">{record.dues} SOS</span>
                  ) : (
                    "Nil"
                  ),
                }))
              : recordsArray
          }
        />
      </DashboardCard>
    )}
  </DashboardLayout>
  )
}

export default Service