import React, { useState } from 'react';
import './login.css';
import logo from '../../assets/logo.png';
import ellipse from '../../assets/ellipse.png';
import user from '../../assets/user.png';
import lock from '../../assets/lock.png';
import { useNavigate } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import {BASE_URL} from "../../constants/index";
import ShowErrorAlert from '../../components/ErrorAlert';
import { extractErrorMessage } from '../../utils';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import useAppConfig from '../../hooks/useAppConfig';
const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {register, handleSubmit, formState: { errors }} = useForm();

    const {appIconPath, appName} = useAppConfig();

    // State variables
    const [loading, setLoading] = useState(false);

    async function loginReq(body) {
        setLoading(true);
        try {
            const { data } = await axios.post(`${BASE_URL}/users/login`, body);
            dispatch({ type: "LOGIN", payload: data });
            setLoading(false);
            const {user} = data;
            if(user?.role === "GIS") {
            console.log('test', user);
                navigate("/dashboard/gis", {allowedRole: "GIS"});
                return;
            }
            console.log('tes2t', user);
            if(user?.role !== "Super_Admin") {
               navigate("/dashboard");
               return;
            }

            navigate("/admins/configurations/appConfig");
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    function onSubmit(data) {
        loginReq(data);
    }
  return (
    <div className='login'>
        <ToastContainer />
        <div className='logo'>
            <div className='logo-img-bg'>
                <img src={appIconPath} className='logo-img' 
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=logo;
            }}/>
            </div>
        </div>
        <div className='fields'>
            <h1 className='login-heading'>{appName}</h1>
            {/*======= form =====*/}
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className='input-fields'>
                <label htmlFor="username" className='label'>Username</label>
                <div className='login-placeholders'>  
                        <input id='username' placeholder='' className='user-input' {...register("username", {required: true})} />
                    <div>
                        <img src={ellipse} className='ellipse-1'/>
                        <img src={user} className='user-icon'/>
                    </div>
                </div>
            </div>
            {errors.username && errors.username.type === "required" &&<div className='error-box'>
                 <div className="text-danger">This is required</div>
            </div>}
            <div className='input-fields'>
                <label className='label'>Password</label>
                <div className='login-placeholders'>  
                    <input placeholder='' className='pass-input' type="password" id="password" {...register("password", {required: true, minLength: 8})} />
                <div>
                    <img src={ellipse} className='ellipse-2'/>
                    <img src={lock} className='lock-icon'/>
                </div>
            </div>
            </div>
            <div className='error-box'>
                {errors.password && errors.password.type === "required" && <div className="text-danger">This is required</div>}
                {errors.password && <div className="text-danger">Password must be 8 characters long.</div> }
            </div>
            <div className='signin-links'>
                <button className='signin-btn-content mt-3' type='submit'>{loading ? <ClipLoader color="white" size={23} /> : "Sign In"}</button>
            </div>
            </form>
        </div>
    </div>
  )
}

export default Login