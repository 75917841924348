import { Link, useNavigate } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DetailsRow from "../../components/DetailsRow";
import { ADMINISTRATIVE_ROLES, LIGHT_BLUE_COLOR } from "../../constants";
import moment from "moment/moment";
import message from '../../assets/Vector.svg'
import edit from '../../assets/edit.svg'
import location from '../../assets/Vector-2.svg'
import print from '../../assets/Group.svg'

const Record = ({ record, owner, role, recordId, updateRecordRequest, survey, isloading }) => {

    const navigate = useNavigate();

    const navigateHandler = (to) => {
        navigate(to)
    }

    return (
        <>
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div className="d-flex align-items-center action-btns">
                    <h5 className="flex-grow-1 mt-1">Xiriirrada Degdegga Ah</h5>
                    {/* <Link style={{maxWidth: "174px"}} to={'#'} className="flex-grow-1 d-flex"> */}
                        <ActionButton onClick={() => navigateHandler(`/admins/records/${recordId}/updateRecordRequests/create`)}
                         style={{maxWidth: "174px", paddingLeft: "11px", paddingRight: "11px"}} className={"flex-grow-1"}>
                        <img className="me-2 cursor-pointer" width={24} height={24} src={edit} alt='edit'/>
                            {/* <FiEdit color="white" size={20} className="me-2" /> */}
                            <small style={{textTransform: "capitalize", fontSize: "16px"}}>Update Record</small>
                        </ActionButton>
                        {/* </Link> */}
                    {(record && record.dues > 0) ? <ActionButton onClick={() => navigateHandler(`/admins/records/${recordId}/updateRecordRequests/${updateRecordRequest._id}/dues`)} style={{maxWidth: "174px", paddingLeft: "10px", paddingRight: "10px"}} className={"ms-3 flex-grow-1"} disabled={isloading || (record && record.dues === 0) ? true : false}>
                        {/* <BsChatSquareText color="white" size={20} className="me-2" /> */}
                        <img className="me-2 cursor-pointer" width={23} height={23} src={message} alt='message'/>
                        <small style={{textTransform: "capitalize", fontSize: "16px"}}>Dues Recovery</small>
                    </ActionButton> : null}
                    {/* <Link style={{maxWidth: "176px"}} className="flex-grow-1 d-flex"> */}
                        {(survey && survey.length > 0) ? <ActionButton onClick={() => navigateHandler(`/admins/records/${recordId}/updateRecordRequests/${updateRecordRequest._id}/requestSurvey`)} style={{maxWidth: "176px", paddingLeft: "11px", paddingRight: "11px"}} className={"ms-3 flex-grow-1"}  disabled={isloading || (survey && survey.length < 1) ? true : false}>
                            <img className="me-2 cursor-pointer" width={17} height={23} src={location} alt='location'/>
                            <small style={{textTransform: "capitalize", fontSize: "16px"}}>Request Survey</small>
                        </ActionButton> : null}
                    {/* </Link> */}
                    {(updateRecordRequest && updateRecordRequest.status === "Approved") ? <ActionButton onClick={() => navigateHandler(`/admins/records/${recordId}/certificate`)} style={{maxWidth: "174px", paddingLeft: "11px", paddingRight: "11px"}} className={"ms-3 flex-grow-1"} disabled={isloading || (updateRecordRequest && updateRecordRequest.status !== "Approved") ? true : false}>
                        {/* <TfiPrinter color="white" size={20} className="me-2" /> */}
                        <img className="me-2 cursor-pointer" width={25} height={23} src={print} alt='print'/>
                        <small style={{textTransform: "capitalize", fontSize: "16px"}}>Print Certificate</small>
                    </ActionButton> : null}
                </div>
            </DashboardCard>
            {
                record && owner && (
                    <div>
                        <DetailsRow
                            leftLabel={"Property ID"}
                            leftValue={record.propertyId}
                            rightLabel="Taariikhda Diiwaangalinta"
                            rightValue={moment(record.createdAt).format("YYYY-MM-DD")}
                        />
                        <DetailsRow
                            leftLabel={"Length (In Meters)"}
                            leftValue={record.length}
                            rightLabel="Width (In Meters)"
                            rightValue={record.width}
                        />
                        <DetailsRow
                            leftLabel={"Cabbirka Dhulka (Sq Meters)"} // Area
                            leftValue={record.area.toFixed(2)}
                            rightLabel="Magaca Mulkiilaha"
                            rightValue={<span className="d-flex align-items-center "><span>{owner.name}</span>
                            {updateRecordRequest && updateRecordRequest.status === "Approved" && 
                             ADMINISTRATIVE_ROLES.includes(role) && <Link className="position-relative" to={`/admins/owners/${owner._id}/edit`}>
                                <ActionButton className="position-absolute" style={{bottom: "-25px", width: "110px", padding: "14px"}} variant={"sm"}>Edit Owner</ActionButton></Link>}
                                </span>}
                        />
                        <DetailsRow
                            leftLabel={"Ciwaanka Waddada"}
                            leftValue={record.address}
                            rightLabel="Lambarka Xiriirka"
                            rightValue={<span>{`+25${owner.phoneNumber}`} <span className="text-secondary">{owner.phoneNumberVerified ? "(Verified)" : "(Unverified)"}</span></span>}
                        />
                        <DetailsRow
                            leftLabel={""}
                            leftValue={""}
                            rightLabel="Sahanka GIS"
                            rightValue={record.coordinates && record.coordinates.length ? <span className="text-danger"><Link to={`/admins/records/${record._id}/requestSurvey/map`} target="_blank">See On Map</Link></span> : "N/A"}
                        />
                    </div>
                )
            }
        </>
    )
};

export default Record;
