import { useParams } from 'react-router-dom';
import './report.css'
import { useEffect, useState } from 'react';
import ShowErrorAlert from '../../components/ErrorAlert';
import { extractErrorMessage } from '../../utils';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../constants';
import axios from 'axios';
import RolePropertyReport from '../../components/Reports/RolePropertyReport';
import useAppConfig from '../../hooks/useAppConfig';
import moment from 'moment';

const RoleReport = () => {

    const {
        auth: {
          user: { _id: userId, role },
          token,
        },
      } = useSelector((state) => state);

    const { appName, appIconPath } = useAppConfig();

    const { reportType } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    
    async function getReportData() {
        setLoading(true);
        try {
          const {
            data,
          } = await axios.get(`${BASE_URL}/users/${userId}/dashboard/role/${reportType}`, {
            headers: {
              Authorization: token,
            },
          });

          setData(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
          ShowErrorAlert(extractErrorMessage(err));
        }
      }
    
      useEffect(() => {
        getReportData()
      }, [])

    return <div className='Report'>
        <div className="container_parent">
        <div className="container">

            <table className="w-100 data_dynamic_top_bar_lead">
                <tbody><tr className="top_bar_lead">
                    <th width="70">
                        <img src={appIconPath} width="70" />
                    </th>
                    <th>
                        <h1 className="top_bar_lead-title text-left">
                            {appName}
                        </h1>
                    </th>
                    <th>
                        <h1 className="top_bar_lead-title text-right top_bar_lead-right">

                            {/* Report : <strong className="highlight">New Property</strong> &nbsp;Zone : <strong className="highlight" style={{textTransform:"uppercase"}}>martisoor</strong><br></br>
                            Date Range : <strong className="highlight">2021-01-01</strong> to <strong className="highlight">2023-06-17</strong> */}
                            <br></br>
                            {/* <div style="display:none;">SELECT *,
                                        pgrop.name as property_group_name,
                                        p.code as property_code,
                                        p.status as status,
                                        c.name as owner_name,
                                        p.created_at as created_at,
                                        p.updated_at as updated_at
                                        FROM `properties` p
                                        JOIN `property_gis` pg ON p.id = pg.property_id
                                        JOIN `property_documents` prop_doc ON pg.property_id = prop_doc.property_id
                                        JOIN `customers` c ON c.id = prop_doc.owner_id
                                        JOIN `property_groups` pgrop ON p.property_group_id = pgrop.id
                                        WHERE date(p.created_at) BETWEEN "2021-01-01" AND "2023-06-17"  AND pg.location = 'martisoor'  AND pg.zone = 'DXY' GROUP BY p.id</div> */}
                        </h1>
                    </th>
                </tr>
                </tbody>
            </table>

            { <RolePropertyReport data={data} reportType={reportType}/>}

            <div className="data_dynamic_footer">
              Generated on : {moment().format('MMM DD, YYYY [at] hh:mm:ss a')}
            </div>
        </div>

    </div>
    </div>
}

export default RoleReport;