
import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { DOCUMENT_TYPE_FIELDS, SHARED_NAME_FIELD } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import ShowSuccessAlert from "../../components/SuccessAlert";

const UpdateUpdateReason = () => {
    const { updateReasonId } = useParams();
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        reset
    } = useForm({
        defaultValues: {
            name: "",
        }
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    // State Variables
    const [loading, setLoading] = useState(false);
    const [customerGroup, setUpdateReason] = useState(null);

    async function updateUpdateReasonReq(body) {
        setLoading(true);
        try {
            await axios.put(`${BASE_URL}/users/${userId}/updateReason/${updateReasonId}`, body, {
                headers: {
                    Authorization: token,
                },
            });
            // setLoading(false);
            ShowSuccessAlert('Update Reason updated successfully');
            setTimeout(()=>{
                setLoading(false);
                navigate("/admins/configurations/updateReason");
            }, 2000);
            // getDocumentTypes();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    async function getUpdateReason(body) {
        setLoading(true);
        try {
            const {
                data: { updateReasons },
            } = await axios.get(`${BASE_URL}/users/${userId}/updateReason`, {
                headers: {
                    Authorization: token,
                },
            });

            if(updateReasons && updateReasons.length > 0) {
                const updateReason = updateReasons.find((group) => group._id === updateReasonId);
                setUpdateReason(updateReason);
                reset({name: updateReason.name})
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    function onSubmit(data) {
        updateUpdateReasonReq(data);
    }

    useEffect(() => {
        getUpdateReason();
    }, []);

    return (
        <DashboardLayout >
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/updateReason">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Update</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Update"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        {SHARED_NAME_FIELD(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                    </DashboardCard>
                </form>
            </DashboardCard>
        </DashboardLayout>
    )
}

export default UpdateUpdateReason;