import React from "react";
import "./style.css";
import { Controller } from "react-hook-form";

function CustomSelect({
  name,
  type,
  rules,
  options = [],
  defaultValue,
  control,
  errors,
  inputClassName,
  errorText,
  onHandleChange,
  style,
  disabled = null,
  ...props
}) {
  return (
    <>
      <Controller
        {...props}
        name={name}
        control={control}
        rules={rules ? rules : {}}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <>
            <select
              className={`custom-input ${inputClassName} ${
                errors[name] ? "input-error" : ""
              }`}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                if(onHandleChange) onHandleChange(e);
              }}
              onBlur={onBlur}
              value={value}
              style={style}
              disabled={disabled ? true : null}
            >
              {options.length && options.map((option, index) => (
                <option key={index} value={option.value} selected={option.selected}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors[name] && (
              <small style={{ color: "red" }}>{errorText}</small>
            )}
          </>
        )}
      />
    </>
  );
}

const CustomSelect2 = React.forwardRef(
  ({ className, options, ...props }, ref) => {
    return (
      <select className={`custom-select ${className}`} {...props} ref={ref}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
);

export default CustomSelect;
