
import React, { useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardHeading from "../../components/DashboardHeading";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { ADD_LOCATION_FIELDS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import ShowSuccessAlert from "../../components/SuccessAlert";

const AddLocation = () => {
    const {
        handleSubmit,
        formState: { errors },
        getValues,
        control,
        reset
    } = useForm({
        values: {
            name: "",
        },
    });

    // Redux Store
    const {
        auth: {
            token,
            user: { _id: userId },
        },
    } = useSelector((state) => state);

    const navigate = useNavigate();

    // State Variables
    const [loading, setLoading] = useState(false);

    async function addLocationReq(body) {
        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/users/${userId}/locations`, body, {
                headers: {
                    Authorization: token,
                },
            });
            ShowSuccessAlert('Location added successfully');
            setTimeout(()=>{
                setLoading(false);
                navigate("/admins/configurations/locations");
            }, 2000);
            reset();
        } catch (err) {
            setLoading(false);
            console.log(err);
            ShowErrorAlert(extractErrorMessage(err));
        }
    }

    function onSubmit(data) {
        addLocationReq(data);
    }

    return (
        <DashboardLayout >
            <ConfigurationsHeader />
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between align-center">
                        <div className="flex align-center">
                            <Link to="/admins/configurations/locations">
                                <MdOutlineArrowBackIosNew size={20} />
                            </Link>
                            <DashboardHeading>Add Location</DashboardHeading>
                        </div>
                        <ActionButton type="submit" disabled={loading}>
                            {loading ? <ClipLoader color="black" size={20} /> : "Add"}
                        </ActionButton>
                    </div>
                    {/* form starts here */}
                    <DashboardCard>
                        {ADD_LOCATION_FIELDS(getValues).map(
                            ({ name, required, label, type, rules, errorText }) => (
                                <div key={name} className="mt-3">
                                    <Label required={required}>{label}</Label>
                                    <CustomInput
                                        name={name}
                                        type={type}
                                        control={control}
                                        rules={rules}
                                        errors={errors}
                                        errorText={errorText}
                                    />
                                </div>
                            )
                        )}
                    </DashboardCard>
                </form>
            </DashboardCard>
            <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
                <div style={{display: "flex", flexDirection: "row"}}>
                <span style={{fontSize: "32px"}}>OR</span>
                {/* <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/locations"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>All Locations</small>
                    </ActionButton></Link> */}
                {/* <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/updateLocation"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Update Location</small>
                    </ActionButton></Link> */}
                    <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/zones/add"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Add Zone</small>
                    </ActionButton></Link>
                    <Link style={{width: "fit-content", marginLeft: "24px"}} to="/admins/configurations/zones/update"  >
                    <ActionButton style={{ height: "55px" }} className={"flex-shrink-1"}>
                        <FiEdit color="white" size={20} className="me-2" />
                        <small>Update Zone</small>
                    </ActionButton></Link>
                </div>
            </DashboardCard>
        </DashboardLayout>

    )
}

export default AddLocation;