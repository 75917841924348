import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Label from "../../components/Label";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import CustomSelect from "../../components/CustomSelect";
import ActionButton from "../../components/ActionButton";
import { ClipLoader } from "react-spinners";
import CustomMaps from "../../components/Maps/CustomMaps";

const CustomMapsModal = ({ show, handleClose, mapCenter, coordinates, owner }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          Area Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <CustomMaps mapCenter={mapCenter}  coordinates={coordinates} owner={owner} />
      </Modal.Body>
    </Modal>
  );
};

export default CustomMapsModal;
