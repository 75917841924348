import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./owners.css";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import CustomInput from "../../components/CustomInput";
import Label from "../../components/Label";
import { ADD_OWNER_FIELDS, CUSTOMER_GROUP_OPTIONS } from "./Owners.constants";
import CustomSelect from "../../components/CustomSelect";
import DashboardCard from "../../components/DashboardCard";

const AddOwner = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    values: {
      name: "",
      email: "",
      phoneNumber: "",
      customerGroup: "",
      customerGroupId: ""
    },
  });
  const navigate = useNavigate();

  // Redux Store
  const {
    auth: {
      token,
      user: { _id: userId },
    },
  } = useSelector((state) => state);

  // State Variables
  const [loading, setLoading] = useState(false);
  const [customerGroups, setCustomerGroup] = useState([]);

  async function addOwnerReq(body) {
    setLoading(true);
    try {
      await axios.post(`${BASE_URL}/users/${userId}/owners`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      navigate("/admins/owners");
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getCustomerGroup(body) {
    setLoading(true);
    try {
      const {
        data: { customerGroups },
      } = await axios.get(`${BASE_URL}/users/${userId}/customerGroup`, {
        headers: {
          Authorization: token,
        },
      });
      const customerGroupsObj = prepareObj(customerGroups);

      setCustomerGroup(customerGroupsObj);

      if (customerGroups && customerGroups.length > 0) {
        const resetValues = { customerGroupId: customerGroups[0]._id, customerGroup: customerGroups[0].name }
        setValue("customerGroupId", resetValues.customerGroupId);
        setValue("customerGroup", resetValues.customerGroup);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const prepareObj = (obj) => {
    for (let [key, value] of obj.entries()) {
      let selected = false;
      if (!key) selected = true;
      obj[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, selected }
    }
    return obj;
  }

  function onChangeCustomerGroup(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedCustomerGroups = [...customerGroups];
    for (let [index, customerGroup] of updatedCustomerGroups.entries()) {
      if (index !== selectedIndex) {
        customerGroup.selected = false;
      } else {
        customerGroup.selected = true;
      }
    }
    setCustomerGroup(updatedCustomerGroups);
    setValue('customerGroupId', updatedCustomerGroups[selectedIndex]._id);
  }

  function onSubmit(data) {
    addOwnerReq(data);
  }

  useEffect(() => {
    getCustomerGroup();
  }, []);

  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between align-center mx-20 mt-5">
          <div className="flex align-center">
            <MdOutlineArrowBackIosNew className="cursor-pointer" onClick={() => navigate(-1)} color="black" size={20} />
            <DashboardHeading>Add Owner</DashboardHeading>
          </div>

          <ActionButton type="submit" disabled={loading}>
            {loading ? <ClipLoader color="black" size={20} /> : "Create"}
          </ActionButton>
        </div>
        {/* form starts here */}
        <DashboardCard>
          {ADD_OWNER_FIELDS.map(
            ({ name, required, label, type, rules, errorText }, index) => (
              <div key={name} className="mt-3">
                <Label required={required}>{label}</Label>
                {index !== ADD_OWNER_FIELDS.length - 1 ? (
                  <CustomInput
                    name={name}
                    type={type}
                    control={control}
                    rules={rules}
                    errors={errors}
                    errorText={errorText}
                  />
                ) : (
                  <div className="flex align-center">
                    <span>+25</span>
                    <div className="flex-grow-1 ms-3">
                      <CustomInput
                        name={name}
                        type={type}
                        control={control}
                        rules={rules}
                        errors={errors}
                        errorText={errorText}
                      />
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          <div className="mt-3">
            <Label required={true}>Customer Group</Label>
            <CustomSelect
              name={"customerGroup"}
              control={control}
              errors={errors}
              errorText={"Customer Group is required"}
              options={customerGroups}
              onHandleChange={onChangeCustomerGroup}
            />
          </div>
        </DashboardCard>
      </form>
    </DashboardLayout>
  );
};

export default AddOwner;
