import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import BlueText from "../BlueText";
import { THEME_COLOR } from "../../constants";
import useAppConfig from "../../hooks/useAppConfig";
import logout from '../../assets/logout.png'

const DashboardHeader = () => {
  // Redux Store
  const {
    auth: {
      user: { name },
    },
  } = useSelector((state) => state);
  const {appName} = useAppConfig()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onLogoutButtonClick() {
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  }

  return (
    <div style={{marginTop: "2rem"}} className="w-100 ps-3 pe-5">
      <div className="d-flex justify-content-between me-5 align-center">
        <div>
          <Link
            to={"/"}
            className="text-decoration-none text-dark text-uppercase"
          >
            <h3>
              <b>{appName}</b>
            </h3>
          </Link>
        </div>
        <div>
          Welcome <BlueText>{name}</BlueText>.{" "}
          <img className="ms-2 cursor-pointer" width={25} height={22} src={logout} alt='logout' onClick={onLogoutButtonClick} />

          {/* <FiLogOut
            color={THEME_COLOR}
            size={25}
            className="ms-2 cursor-pointer"
            onClick={onLogoutButtonClick}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
