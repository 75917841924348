import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ACTIONS_KEYS,
  ADMINISTRATIVE_ROLES,
  BASE_URL,
  LIGHT_BLUE_COLOR,
  ROLES_OF_ACTIONS,
  SERVER_DOC_PATH,
  SERVICE_REQUEST_APPROVAL_ROLES,
} from "../../constants";
import axios from "axios";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/DashboardLayout";
import ActionButton from "../../components/ActionButton";
import DashboardCard from "../../components/DashboardCard";
import DashboardTable from "../../components/DashboardTable";
import BlueText from "../../components/BlueText";
import { FiDownload, FiEdit, FiInfo, FiLogOut } from "react-icons/fi";
import moment from "moment/moment";
import {
  ACTION_OPTIONS,
  APPROVAL_TABLE_DATA_KEYS,
  APPROVAL_TABLE_HEADINGS,
  PAYMENT_METHOD_OPTIONS,
  RECORDS_TABLE_DATA_KEYS,
  RECORDS_TABLE_HEADINGS,
  SERVICE_REQUESTS_TABLE_DATA_KEYS,
  SERVICE_REQUESTS_TABLE_DATA_KEYS_WITOUT_ACTION,
  SERVICE_REQUESTS_TABLE_HEADINGS,
  SERVICE_REQUESTS_TABLE_HEADINGS_WITOUT_ACTION,
  UPLOADED_DOCUMENTS_TABLE_DATA_KEYS,
  UPLOADED_DOCUMENTS_TABLE_HEADINGS,
} from "./UpdateRecord.constants";
import UploadDocumentModal from "./UpdateRecord.uploadDocumentModal";
import CreateRequestModal from "./UpdateRecord.createReqModel";
import CustomSelect from "../../components/CustomSelect";
import { useForm } from "react-hook-form";
import Label from "../../components/Label";
import { isEmpty, lowerCase } from "lodash";
import Status from "../../components/Status";
import Record from "../RecordDetails/RecordDetails.Record";
import CustomInput from "../../components/CustomInput";
import RejectModal from "./UpdateRecord.rejectModal";
import { Tooltip } from 'react-tooltip'

const RecordDetails = () => {
  // Redux Store
  const {
    auth: {
      user: { _id: userId, role },
      token,
    },
  } = useSelector((state) => state);
  const { recordId, updateRecordRequestId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    register
  } = useForm({
    values: {
      name: "",
      file: "",
      discount: 0,
      action: "Approved",
      paymentMethod: "Cash",
      url: ""
    },
    defaultValues: {
      discount: 0,
      action: "Approve",
      paymentMethod: "Cash",
      url: ""
    }
  });

  // State Variables
  const [record, setRecord] = useState(null);
  const [owner, setOwner] = useState(null);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("Reject Request");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateRecordRequest, setUpdateRecordRequest] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [assignedForApproval, setAssignedForApproval] = useState(false);
  const [amount, setAmount] = useState(null);
  const [netAmount, setNetAmount] = useState(null);
  const [discount, setDiscount] = useState(null);
  // const [applyDiscount, setApplyDiscount] = useState(false);
  const [fullDiscount, setFullDiscount] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [approvals, setApprovals] = useState({});

  async function getRecordDetailsReq() {
    setLoading(true);
    try {
      const {
        data: { record: recordData, owner: ownerData },
      } = await axios.get(`${BASE_URL}/users/${userId}/records/${recordId}`, {
        headers: {
          Authorization: token,
        },
      });
      setRecord(recordData);
      setOwner(ownerData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function getUpdateRecordRequestDetails() {
    setLoading(true);
    try {
      const {
        data: {
          updateRecordRequest: updateRecordRequestData,
          serviceRequests: serviceRequestData,
          uploadedDocuments: uploadedDocumentsData,
          surveys: surveyData
        },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setApprovals(() => {

        return serviceRequestData?.reduce((acc, serviceRequest, index) => {
          const nextObj = serviceRequestData[index + 1];

          let propManagerAction = false;
          let financeAction = false;
          let secretaryAction = false;
          let mayorAction = false;

          if (index === 0) {
            propManagerAction = serviceRequest.propManagerAction === "Pending" || nextObj?.propManagerAction === "Pending" ? "Pending" : (
              serviceRequest.propManagerAction === "Approved" && (!nextObj || nextObj?.propManagerAction === "Approved") ? "Approved" : "Rejected"
            );

            financeAction = serviceRequest.financeAction === "Pending" || nextObj?.financeAction === "Pending" ? "Pending" : (
              serviceRequest.financeAction === "Approved" && (!nextObj || nextObj?.propManagerAction === "Approved") ? "Approved" : "Rejected"
            );

            secretaryAction = serviceRequest.secretaryAction === "Pending" || nextObj?.secretaryAction === "Pending" ? "Pending" : (
              serviceRequest.secretaryAction === "Approved" && (!nextObj || nextObj?.propManagerAction === "Approved") ? "Approved" : "Rejected"
            );

            mayorAction = serviceRequest.mayorAction === "Pending" || nextObj?.mayorAction === "Pending" ? "Pending" : (
              serviceRequest.mayorAction === "Approved" && (!nextObj || nextObj?.propManagerAction === "Approved") ? "Approved" : "Rejected"
            );
          }
          else {
            propManagerAction = serviceRequest.propManagerAction === "Pending" || acc?.propManagerAction === "Pending" ? "Pending" : (
              serviceRequest.propManagerAction === "Approved" && acc?.propManagerAction === "Approved" ? "Approved" : "Rejected"
            );

            financeAction = serviceRequest.financeAction === "Pending" || acc?.financeAction === "Pending" ? "Pending" : (
              serviceRequest.financeAction === "Approved" && acc?.financeAction === "Approved" ? "Approved" : "Rejected"
            );

            secretaryAction = serviceRequest.secretaryAction === "Pending" || acc?.secretaryAction === "Pending" ? "Pending" : (
              serviceRequest.secretaryAction === "Approved" && acc?.secretaryAction === "Approved" ? "Approved" : "Rejected"
            );

            mayorAction = serviceRequest.mayorAction === "Pending" || acc?.mayorAction === "Pending" ? "Pending" : (
              serviceRequest.mayorAction === "Approved" && acc?.mayorAction === "Approved" ? "Approved" : "Rejected"
            );
          }

          const newObj = {
            propManagerAction: propManagerAction,
            financeAction: financeAction,
            secretaryAction: secretaryAction,
            mayorAction: mayorAction,
          };

          return { ...acc, ...newObj };
        }, [])
      })

      setServiceRequests(serviceRequestData);
      setUploadedDocuments(uploadedDocumentsData);
      setUpdateRecordRequest(updateRecordRequestData);
      setSurvey(surveyData);

      const amount = Number(serviceRequestData
        .map((el) => el.totalAmount)
        .reduce((acc, current) => acc + current, 0)).toLocaleString();

      let discount = 0;
      if(serviceRequestData && !isEmpty(serviceRequestData)){
        discount = serviceRequestData[0].discount;
      }

      setAmount(amount);
      setDiscount(discount);
      setValue("discount", discount);
      setNetAmount((prevVal) => {
        const discountedValue = (Number(discount) / 100) * Number(amount.replace(/,/g, ""));
        prevVal = amount.replace(/,/g, "") - discountedValue;
        return prevVal.toLocaleString();
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function updateServiceRequestStatusReq(serviceRequestId, status, comments) {
    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/serviceRequests/${serviceRequestId}/status`,
        { status, comments },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setLoading(false);
      getRecordDetailsReq();
      getUpdateRecordRequestDetails();
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function updateServiceRequestDiscount() {
    setLoading(true);
    if (fullDiscount) {
      if (!getValues('url')) {
        ShowErrorAlert('For full discount upload the associated document');
        return;
      }
    }
    // const discountVal = applyDiscount ? discount : 0;
    const discountVal = getValues('action') === "Rejected" ? 0 : discount;
    try {
      await axios.put(
        `${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/serviceRequests/submitDiscount`,
        { paymentMethod: getValues('paymentMethod'), discount: discountVal, action: getValues('action'), fullDiscount },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setLoading(false);
      getRecordDetailsReq();
      getUpdateRecordRequestDetails();
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function uploadDocumentReq(body) {
    setLoading(true);
    try {
      const { data } = await axios.post(`${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/uploadedDocuments/upload`, body, {
        headers: {
          Authorization: token,
          'content-type': 'multipart/form-data'
        },
      });
      setValue('url', data.url);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onDiscountFileChangeHandler(e) {
    const files = e.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadDocumentReq(formData);
  }

  useEffect(() => {
    const syncCall = async () => {
      await getRecordDetailsReq();
      await getUpdateRecordRequestDetails();
    }

    syncCall()
  }, []);

  useEffect(() => {
    if (serviceRequests && serviceRequests.length > 0) {
      setAssignedForApproval(serviceRequests.some((req) => req.assignedTo === role));
    }
  }, [serviceRequests]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const discountChangeHandler = (event) => {
  //   setApplyDiscount(prevVal => !prevVal);
  // }

  const onDiscountChange = (evt) => {
    const discountEntered = evt.target.value;
    if(discountEntered < 0 || discountEntered > 100) {
      const value = discountEntered > 100 ? 100 : 0;
      if(value === 100){
        setDiscount(100);
      }else{
        setDiscount(0);
      }
    }
    if (discountEntered >= 100) setFullDiscount(true);
    else setFullDiscount(false);
    setDiscount(Number(discountEntered));
    setNetAmount((prevVal) => {
      const discountedValue = (discountEntered / 100) * Number(amount.replace(/,/g, ""));
      prevVal = amount.replace(/,/g, "") - discountedValue;
      return prevVal.toLocaleString();
    });
  }

  return (
    <DashboardLayout>
      {role === "CS" ?
        <Record
          record={record}
          owner={owner}
          role={role}
          recordId={recordId}
          updateRecordRequest={updateRecordRequest}
          survey={survey}
          isloading={loading}
        /> : null
      }
      {(
        <DashboardCard>
          <div className="flex justify-between align-center">
            <h5>Documents</h5>
            {/* {ADMINISTRATIVE_ROLES.includes(role) && (
              <ActionButton
                variant={"sm"}
                onClick={() => {
                  setType("Upload Document");
                  handleShow();
                }}
              >
                Upload
              </ActionButton>
            )} */}
          </div>
          <DashboardTable
            headings={UPLOADED_DOCUMENTS_TABLE_HEADINGS}
            dataKeys={UPLOADED_DOCUMENTS_TABLE_DATA_KEYS}
            loading={loading}
            data={
              uploadedDocuments.length
                ? uploadedDocuments.map((document) => ({
                  ...document,
                  url: (
                    <a href={SERVER_DOC_PATH + document.url} target="_blank" rel="norefferer" className="text-nowrap">
                      Document Link
                    </a>
                  ),
                  createdAt: moment(document.createdAt).format("DD-MM-YYYY"),
                }))
                : uploadedDocuments
            }
          />
        </DashboardCard>
      )}
      <DashboardCard>
        <div className="flex justify-between align-center">
          <h5>Service Requests</h5>
          {role === "CS" ? <ActionButton
            variant={"sm"}
            onClick={() => {
              setType("Create");
              handleShow();
            }}
          // disabled={!uploadedDocuments.length}
          >
            Create Request
          </ActionButton> : null}
        </div>
        <DashboardTable
          headings={role !== "CS" && role !== "Prop Manager" ? SERVICE_REQUESTS_TABLE_HEADINGS : SERVICE_REQUESTS_TABLE_HEADINGS_WITOUT_ACTION}
          dataKeys={role !== "CS" && role !== "Prop Manager" ? SERVICE_REQUESTS_TABLE_DATA_KEYS : SERVICE_REQUESTS_TABLE_DATA_KEYS_WITOUT_ACTION}
          loading={loading}
          data={
            serviceRequests.length
              ? serviceRequests.map((service, index) => ({
                ...service,
                createdAt: moment(record.createdAt).format("DD-MM-YYYY"),
                // ...(role !== "CS" ?? {status: service[`${lowerCase(role)}Action`] ? service[`${lowerCase(role)}Action`] : service.status}),
                actions: (
                  <span className="flex">
                    {(service.assignedTo !== role || service[ACTIONS_KEYS[role][0]] !== "Pending") ? (

                      <>
                        <span>{service[ACTIONS_KEYS[role]]}</span>
                        {
                          service[ACTIONS_KEYS[role]] === "Rejected" ?
                            (<>
                              <FiInfo
                                // color={THEME_COLOR}
                                size={18}
                                className="ms-2 cursor-pointer"
                                data-tooltip-id={index} data-tooltip-content={`${service.comments}`}
                              // onClick={onLogoutButtonClick}
                              />
                              <Tooltip id={index} className="bg-white px-4 py-2 position-absolute"></Tooltip>
                            </>) :
                            null
                        }
                        {
                          role === "Finance" && service.paymentVerified ? (
                            <>
                              <FiDownload
                                size={18}
                                className="ms-2 cursor-pointer"
                                onClick={() => navigate(`/admins/records/${recordId}/updateRecordRequests/${updateRecordRequestId}/serviceRequests/${service._id}`)}>

                              </FiDownload>
                            </>
                          ) : null
                        }
                      </>
                    ) : <span>
                      <BlueText
                        className={
                          SERVICE_REQUEST_APPROVAL_ROLES.includes(role) &&
                          "cursor-pointer"
                        }
                        onClick={
                          SERVICE_REQUEST_APPROVAL_ROLES.includes(role) &&
                          (() =>
                            updateServiceRequestStatusReq(
                              service._id,
                              "Approved"
                            ))
                        }
                      >
                        Approve
                      </BlueText>{" "}
                      <BlueText className="ms-2">|</BlueText>{" "}
                      <BlueText
                        className={`ms-2 ${SERVICE_REQUEST_APPROVAL_ROLES.includes(role) &&
                          "cursor-pointer"
                          }`}
                        onClick={
                          SERVICE_REQUEST_APPROVAL_ROLES.includes(role) &&
                          (() => {
                            setType("Reject Request");
                            setServiceId(service._id)
                            handleShow();
                            return
                          }
                            // updateServiceRequestStatusReq(
                            //   service._id,
                            //   "Approved"
                            // )
                          )
                        }
                      >
                        Reject
                      </BlueText>
                    </span>}
                  </span>
                )
              }))
              : serviceRequests
          }
        />
        <div className="mx-20">
          <h5 className="text-end"><BlueText>Total: <span style={{ marginLeft: "26px" }}>{amount}</span></BlueText></h5>
        </div>
        <div className="flex justify-content-between align-items-center mx-20">
          {role === "CS" && assignedForApproval ?
            <div
              className="d-flex align-items-center"
            >
              <Label className={"text-nowrap"}>Payment Method</Label>
              <CustomSelect
                name={"paymentMethod"}
                control={control}
                errors={errors}
                errorText={"Payment Method is required"}
                options={PAYMENT_METHOD_OPTIONS}
                inputClassName="ms-3 "
              />
            </div> : null}
          {role !== "CS" &&
            assignedForApproval ?
            <div
              className="d-flex align-items-center "
            >
              <Label className={"text-nowrap me-3"}>Payment Method</Label>
              <span>{getValues('paymentMethod')}</span>
            </div> :
            null
          }
          {/* {role === "Prop Manager" && assignedForApproval ? <div className="d-flex gap-2">
            <input type="checkbox" id="apply_discount" name="apply_discount" onChange={discountChangeHandler} />
            <label for="apply_discount">Apply Discount</label>
          </div> : null} */}
          {role === "Prop Manager" && assignedForApproval && fullDiscount ? <div className="d-flex flex-column gap-2">
            <label for="full_discount">Proof:  </label>
            <input type="file" id="full_discount" name="full_discount" onChange={onDiscountFileChangeHandler} />
          </div> : null}
          <div></div>
          <div></div>
          <div></div>
          {role === "Prop Manager" || (serviceRequests && serviceRequests.length > 0 && serviceRequests.some((req) => {
              return req.propManagerAction === "Approved";
            })) ? <h5>
             <div className="d-flex align-items-center ">
              Discount:{"  "}
              <div className="d-inline" style={{ marginLeft: "10px" }} >
                <input
                  name={'discount'}
                  type={'number'}
                  control={control}
                  style={{ width: "72px" }}
                  defaultValue={discount}
                  disabled={role !== "Prop Manager" ? true : null}
                  onChange={onDiscountChange}
                />
                %
              </div>
            </div> 
          </h5> : null}
        </div>
        <div className="flex justify-content-between align-items-center mx-20 mt-3">
          {role === "Prop Manager" && assignedForApproval ? <div
            className="d-flex align-items-center"
          >
            <CustomSelect
              name={"action"}
              {...register('action')}
              control={control}
              errors={errors}
              errorText={"Action is required"}
              options={ACTION_OPTIONS}
            />
            <ActionButton className={"ms-4"} onClick={
              (() =>
                updateServiceRequestDiscount()
              )
            }>Submit</ActionButton>
          </div> : null}
          <div></div>
          <h5>
            <BlueText>
              Net Total:{" "}
              <span style={{ marginLeft: "26px" }}>{netAmount}</span>
            </BlueText>
          </h5>
        </div>
      </DashboardCard>
      <DashboardCard>
        <div className="flex justify-between align-center">
          <h5>Approval</h5>
        </div>
        <DashboardTable
          headings={APPROVAL_TABLE_HEADINGS}
          dataKeys={APPROVAL_TABLE_DATA_KEYS}
          loading={loading}
          data={serviceRequests && serviceRequests.length > 0 ?
            SERVICE_REQUEST_APPROVAL_ROLES.map(val => (
              ACTIONS_KEYS[val].map((action) => {
                return { role: val, status: <Status text={approvals[action]} /> }
              })
            )).flat(1) : []
          }
        />
      </DashboardCard>
      {type === "Reject Request" ? (
        <RejectModal
          show={show}
          handleClose={handleClose}
          type={type}
          setShow={setShow}
          userId={userId}
          serviceId={serviceId}
          updateRecordRequestId={updateRecordRequestId}
          token={token}
          updateServiceRequestStatusReq={updateServiceRequestStatusReq}
        />
      ) : (
        <CreateRequestModal
          show={show}
          handleClose={handleClose}
          type={type}
          area={record.area}
          setShow={setShow}
          userId={userId}
          updateRecordRequestId={updateRecordRequestId}
          recordId={recordId}
          token={token}
          getUpdateRecordRequestDetails={getUpdateRecordRequestDetails}
        />
      )}
      <br />
      <br />
    </DashboardLayout>
  );
};

export default RecordDetails;
