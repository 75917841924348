export const OWNERS_TABLE_HEADINGS = [
  "id",
  "name",
  "email",
  "group",
  "phone",
  "phone status",
  "status",
  "actions",
];
export const OWNERS_TABLE_DATA_KEYS = [
  "_id",
  "name",
  "email",
  "customerGroup",
  "phoneNumber",
  "phoneNumberVerified",
  "status",
  "actions",
];

export const ADD_OWNER_FIELDS = [
  {
    name: "name",
    label: "Name",
    required: true,
    type: "text",
    rules: { required: true },
    errorText: "Name is required",
  },
  {
    name: "email",
    label: "Email",
    required: false,
    type: "email",
    rules: { required: false },
    errorText: "Invalid Email",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    required: true,
    type: "number",
    rules: { required: true },
    errorText: "Phone Number is required",
  },
];

export const EDIT_OWNER_FIELDS = [
  {
    name: "name",
    label: "Name",
    required: true,
    type: "text",
    rules: { required: true },
    errorText: "Name is required",
  },
  {
    name: "email",
    label: "Email",
    required: false,
    type: "email",
    rules: { required: false },
    errorText: "Invalid Email",
  },
];

export const CUSTOMER_GROUP_OPTIONS = [
  {
    label: "Co-operate",
    value: "Co-operate",
  },
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Public",
    value: "Public",
  },
];

export const STATUS_OPTIONS = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];
