import React, { useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardCard from "../../components/DashboardCard";
import { MdDelete, MdEdit } from "react-icons/md";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import DashboardHeading from "../../components/DashboardHeading";
import { CUSTOMER_GROUP_TABLE_DATA_KEYS, CUSTOMER_GROUP_TABLE_HEADINGS } from "./Config.constant";
import ConfigurationsHeader from "./ConfigurationsHeader";
import DashboardTable from "../../components/DashboardTable";
import { Button } from "react-bootstrap";

const UpdateReason = () => {

  const navigate = useNavigate();

  // Redux Store
  const {
    auth: {
      user: { _id: userId },
      token,
    },
  } = useSelector((state) => state);

  // State Variables
  const [updateReason, setUpdateReason] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getAllUpdateReason(queryObj = {}) {
    setLoading(true);
    try {
      const {
        data: { updateReasons },
      } = await axios.get(
        `${BASE_URL}/users/${userId}/updateReason?${Object.entries(queryObj)
          .map(([key, value]) => (value ? `${key}=${value}&` : ""))
          .join("")}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setUpdateReason(updateReasons);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  useEffect(() => {
    getAllUpdateReason();
  }, []);

  async function onDelete(id) {
    setLoading(true);
    try {
    // eslint-disable-next-line no-restricted-globals
    let result = confirm("Do you really want to delete this update reason?");
      if(!result){
        return;
      }
      await axios.delete(
        `${BASE_URL}/users/${userId}/updateReason/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getAllUpdateReason()
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  return (
    <DashboardLayout>
      <ConfigurationsHeader />
      <div className="flex justify-between align-center mx-20 mt-5">
        <DashboardHeading>Update Reason</DashboardHeading>
        <span onClick={() => navigate("/admins/configurations/updateReason/add")} className="dash_head_link">
          <ActionButton>Add Update Reason</ActionButton>
        </span>
      </div>
      {(<div className="records-table">
        <DashboardCard>
          <DashboardTable
            headings={CUSTOMER_GROUP_TABLE_HEADINGS}
            dataKeys={CUSTOMER_GROUP_TABLE_DATA_KEYS}
            data={
              updateReason.length
                ? updateReason.map((record) => ({
                  ...record,
                  action: (
                    <div className="d-flex" style={{gap: "8px"}}>
                    <Button style={{backgroundColor: '#00B4D8'}} onClick={() => navigate(`/admins/configurations/updateReason/${record._id}/update`)}>
                      <MdEdit size={24} />
                    </Button>
                    <Button style={{backgroundColor: 'red'}} onClick={() => onDelete(record._id)}>
                      <MdDelete size={24} />
                    </Button>
                  </div>
                  ),
                }))
                : updateReason
            }
          />
        </DashboardCard>
      </div>
      )}
    </DashboardLayout>
  );
}

export default UpdateReason;