import React, { useEffect, useState } from "react";
import "./side_nav.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_BASED_SIDE_NAV_ITEMS } from "./SideNav.constants";
import logoPath from '../../assets/logo.png'
import { SERVER_ICON_PATH } from "../../constants";
import useAppConfig from "../../hooks/useAppConfig";

const SideNav = () => {
  // Redux Store
  const {
    auth: {
      user: { role },
    },
  } = useSelector((state) => state);

  const [collapsed, setCollapsed] = useState(true);
  const { appIconPath, appName } = useAppConfig();

  return (
    <div className={`side-nav ${collapsed ? "side-nav-collapse" : ""}`} onMouseEnter={() => setCollapsed(false)} onMouseLeave={() => setCollapsed(true)}>
      <div className="logo-container mt-4">
        <img src={appIconPath} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=logoPath;
            }} />
      </div>
      <ul className="side-nav-list">
        {ROLE_BASED_SIDE_NAV_ITEMS[role]?.map((item, i) => (
          <NavLink to={item.to} key={i}>
            <li>
              {item.icon} <span>{item.text}</span>
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default SideNav;
