
import React from "react";
import ActionButton from "../../components/ActionButton";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import DashboardCard from "../../components/DashboardCard";
import { LIGHT_BLUE_COLOR } from "../../constants";
import { useSelector } from "react-redux";

const ConfigurationsHeader = () => {
    const { auth: { user: { role } }} = useSelector(state => state);

    return (
        <DashboardCard backgroundColor={LIGHT_BLUE_COLOR}>
            <div className="container">
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: "12px"}}>
                    {role === "Super_Admin" ? <div >
                        <Link to="/admins/configurations/appConfig"  style={{ marginTop: "20px" }}>
                            <ActionButton style={{ height: "55px" }} className={"flex-grow-1"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>App configurations</small>
                            </ActionButton>
                        </Link>
                    </div> : null}
                    {role === "Super_Admin" || role === "Admin" ? <div >
                        <Link to="/admins/configurations/locations" style={{ marginTop: "20px" }}>
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Locations</small>
                            </ActionButton>
                        </Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/serviceTypes"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Service Type</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/units"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Unit</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/documentTypes"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Document Type</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/customerGroup"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Customer Group</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/landTitle"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Land Title</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/landCategory"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={"w-100 "}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Land Category</small>
                            </ActionButton></Link>
                    </div> : null}
                    {role !== "Super_Admin" ? <div >
                        <Link to="/admins/configurations/updateReason"  style={{ marginTop: "20px" }} >
                            <ActionButton style={{ height: "55px" }} className={" w-100"}>
                                <FiEdit color="white" size={20} className="me-2" />
                                <small>Update Reason</small>
                            </ActionButton></Link>
                    </div> : null}  
                </div>
            </div>
        </DashboardCard>
    )
}

export default ConfigurationsHeader;