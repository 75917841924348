const authState = {
  token: null,
  user: null,
};

export default function userReducer(state = authState, action) {
  switch (action.type) {
    case "LOGIN":
      return { ...state, ...action.payload };
    case "LOGOUT":
      return authState;
    default:
      return state;
  }
}
