import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./owners.css";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useForm } from "react-hook-form";
import ShowErrorAlert from "../../components/ErrorAlert";
import { extractErrorMessage } from "../../utils";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardHeading from "../../components/DashboardHeading";
import ActionButton from "../../components/ActionButton";
import CustomInput from "../../components/CustomInput";
import Label from "../../components/Label";
import {
  ADD_OWNER_FIELDS,
  CUSTOMER_GROUP_OPTIONS,
  EDIT_OWNER_FIELDS,
  STATUS_OPTIONS,
} from "./Owners.constants";
import CustomSelect from "../../components/CustomSelect";
import DashboardCard from "../../components/DashboardCard";
import { Badge } from "react-bootstrap";
import ShowSuccessAlert from "../../components/SuccessAlert";

const EditOwner = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    values: {
      name: "",
      email: "",
      phoneNumber: "",
      status: "",
      customerGroup: "",
      customerGroupId: ""
    },
  });
  const navigate = useNavigate();
  const { ownerId } = useParams();

  // Redux Store
  const {
    auth: {
      token,
      user: { _id: userId },
    },
  } = useSelector((state) => state);

  // State Variables
  const [loading, setLoading] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [customerGroups, setCustomerGroup] = useState([]);

  async function getOwnerDetailsReq() {
    setLoading(true);
    try {
      const {
        data: { owner },
      } = await axios.get(`${BASE_URL}/users/${userId}/owners/${ownerId}`, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      setValue("name", owner.name);
      setValue("email", owner.email);
      setValue("customerGroup", owner.customerGroup);
      setValue("customerGroupId", owner.customerGroupId);
      setValue("status", owner.status);
      setValue("phoneNumber", owner.phoneNumber);
      setPhoneNumberVerified(owner.phoneNumberVerified);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  async function updateOwnerReq(body) {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/users/${userId}/owners/${ownerId}`, body, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      navigate("/admins/owners");
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSubmit(data) {
    updateOwnerReq(data);
  }

  async function sendOtpReq() {
    setOtpLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/users/${userId}/owners/${ownerId}/sendOtp`, {}, 
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setOtpLoading(false);
      ShowSuccessAlert("Otp sent successfully");
      setOtpSent(true);
    } catch (err) {
      setOtpLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onSendOtpClick(e) {
    e.preventDefault()
    sendOtpReq();
  }

  async function verifyOtpReq() {
    setOtpLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/users/${userId}/owners/${ownerId}/verifyOtp`, { otp }, 
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setOtpLoading(false);
      ShowSuccessAlert("Otp Verified successfully");
      setOtpSent(false);
      getOwnerDetailsReq();
    } catch (err) {
      setOtpLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  function onVerifyClick(e) {
    e.preventDefault()
    verifyOtpReq();
  }

  async function getCustomerGroup(body) {
    setLoading(true);
    try {
      const {
        data: { customerGroups },
      } = await axios.get(`${BASE_URL}/users/${userId}/customerGroup`, {
        headers: {
          Authorization: token,
        },
      });
      const customerGroupsObj = prepareObj(customerGroups);

      setCustomerGroup(customerGroupsObj);

      if (customerGroups && customerGroups.length > 0) {
        const resetValues = { customerGroupId: customerGroups[0]._id, customerGroup: customerGroups[0].name }
        setValue("customerGroupId", resetValues.customerGroupId);
        setValue("customerGroup", resetValues.customerGroup);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      ShowErrorAlert(extractErrorMessage(err));
    }
  }

  const prepareObj = (obj) => {
    for (let [key, value] of obj.entries()) {
      let selected = false;
      if (!key) selected = true;
      obj[key] = { _id: value._id, label: value.name, value: value.name, name: value.name, selected }
    }
    return obj;
  }

  function onChangeCustomerGroup(e) {
    const selectedIndex = e.target.selectedIndex;

    let updatedCustomerGroups = [...customerGroups];
    for (let [index, customerGroup] of updatedCustomerGroups.entries()) {
      if (index !== selectedIndex) {
        customerGroup.selected = false;
      } else {
        customerGroup.selected = true;
      }
    }
    setCustomerGroup(updatedCustomerGroups);
    setValue('customerGroupId', updatedCustomerGroups[selectedIndex]._id);
  }

  const syncCall = async () => {
    await getCustomerGroup();
    await getOwnerDetailsReq();
  }

  useEffect(() => {
      syncCall();
  }, []);


  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between align-center mx-20 mt-5">
          <div className="flex align-center">
            <Link to="/admins/owners">
              <MdOutlineArrowBackIosNew color="black" size={20} />
            </Link>
            <DashboardHeading>Update Owner</DashboardHeading>
          </div>

          <ActionButton type="submit" disabled={loading}>
            {loading ? <ClipLoader color="white" size={20} /> : "Update"}
          </ActionButton>
        </div>
        {/* form starts here */}
        <DashboardCard>
          {EDIT_OWNER_FIELDS.map(
            ({ name, required, label, type, rules, errorText }) => (
              <div key={name} className="mt-3">
                <Label required={required}>{label}</Label>
                <CustomInput
                  name={name}
                  type={type}
                  control={control}
                  rules={rules}
                  errors={errors}
                  errorText={errorText}
                />
              </div>
            )
          )}

          <div className="mt-3">
            <Label required={true}>Status</Label>
            <CustomSelect
              name={"status"}
              control={control}
              errors={errors}
              errorText={"Status is required"}
              options={STATUS_OPTIONS}
            />
          </div>
          <div className="mt-4">
            <div
              className={`flex ${!otpSent ? 'align-center' : 'align-items-start'} justify-between`}
            >
              <div className="flex align-center">
                <Label required={true}>Phone Number</Label>
                <Badge
                  className="ms-2"
                  bg={phoneNumberVerified ? "success" : "warning"}
                >
                  {phoneNumberVerified ? "VERIFIED" : "UNVERIFIED"}
                </Badge>
              </div>
              {phoneNumberVerified || loading ? null : !otpSent ? (
                <ActionButton variant={"sm"} onClick={onSendOtpClick} disabled={phoneNumberVerified || otpLoading}>
                  {otpLoading ? <ClipLoader color="white" size={20} /> : "Send OTP"}
                </ActionButton>
              ) : null}
            </div>
            <div className="flex align-center">
              <span>+25</span>
              <div className="flex-grow-1 ms-3">
                <CustomInput
                  name={"phoneNumber"}
                  type={"number"}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                  errorText={"Phone Number is required"}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            { phoneNumberVerified || loading ? null : !otpSent ? null :
              <div className="flex-grow-0">
              <Label required={true}>Verify OTP</Label>
              <input
                className={`custom-input ${!otp ? "input-error" : ""}`}
                value={otp}
                type={"number"}
                onChange={(e) => setOtp(e.target.value)}
                maxLength={6}
                style={{maxHeight: "36px"}}
              />
              {!otp && (
                <small style={{ color: "red" }}>OTP is required</small>
              )}
              <ActionButton className={`ms-0`} variant={"sm"} onClick={onVerifyClick}>Verify</ActionButton>
            </div>
            }
          </div>
          <div className="mt-3">
            <Label required={true}>Customer Group</Label>
            <CustomSelect
              name={"customerGroup"}
              control={control}
              errors={errors}
              errorText={"Customer Group is required"}
              options={customerGroups}
              onHandleChange={onChangeCustomerGroup}
            />
          </div>
          <br />
        </DashboardCard>
      </form>
    </DashboardLayout>
  );
};

export default EditOwner;
