import React from "react";
import moment from 'moment';
import { capitalize } from "lodash";

const RolePropertyReport = ({ data, reportType }) => {
    return (
        <div className="data_dynamic_root">
            <span className="float-right"><small>Total Records : </small><strong>8</strong></span>

            <table cellPadding="0" cellSpacing="0" className="table_dynamic mx-0 w-100">
                <thead>
                    <tr>
                        <th>Property Details</th>
                        <th>created_at</th>
                        <th>updated_at</th>
                        {/* <th>title</th> */}
                        {/* <th>description</th> */}
                        <th>Type</th>
                        <th>Extra</th>
                        <th>Approval</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? data.map((value) => {
                        return <tr>
                            <td style={{ width: "300px" }}>
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0">
                                    <tbody><tr>
                                        <td><span>Property Code : </span><strong>{value.updateRecordRequestId.recordId.propertyId}</strong></td>
                                    </tr>
                                        <tr>
                                            <td><span>Owner Name : </span><strong>{value.updateRecordRequestId.recordId.ownerName}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Location : </span><strong>{value.updateRecordRequestId.recordId.location}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Zone : </span><strong>{value.updateRecordRequestId.recordId.zone}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Length x Width : </span><strong>{value.updateRecordRequestId.recordId.length + " x " +value.updateRecordRequestId.recordId.width}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Prop. Group : </span><strong>{value.updateRecordRequestId.recordId.landTitle}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Land Category : </span><strong>{value.updateRecordRequestId.recordId.landCategory}</strong></td>
                                        </tr>
                                    </tbody></table>
                            </td>
                            <td>
                                <span className="report_date">{moment(value.createdAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.createdAt).format("hh:mm:ss a")}</span>
                            </td>
                            <td>
                                <span className="report_date">{moment(value.updatedAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.updatedAt).format("hh:mm:ss a")}</span>
                            </td>

                            {/* <td>Nootaayo</td>
                            <td></td> */}
                            <td>{value.type}</td>
                            <td></td>

                            <td align="top">
                                <table className="subtable" cellPadding="0" cellSpacing="0" style={{ width: "100%" }}>

                                    <tbody><tr>
                                        <th colSpan="2">{capitalize(reportType)}
                                        </th></tr>
                                        <tr>
                                            <td>Is Approved?</td>
                                            <td>{value[reportType + "Action"] === "Approved" ? "Yes" : "No"}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Name</td>
                                            <td>Secretary</td>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </td>
                            <td>Approved</td>
                        </tr>
                    }) :
                        null}


                </tbody>
            </table>



        </div>
    );

}

export default RolePropertyReport;
