import React, { useEffect, useState } from "react";
import moment from 'moment';

const CollectionReport = ({ data }) => {

    const [totalAmount, setTotalAmount] = useState(null);

    useEffect(() => {
        if(data && data.length > 0){
            const amount = data.reduce((acc, value) => {
                return Number(acc) + Number(value.totalAmount);
            }, 0);

            setTotalAmount(amount.toFixed(2));
        }
    }, [data]);

    return (
        <div className="data_dynamic_root">
            <span className="float-right"><small>Total Records : </small><strong>{data && data.length ? data.length : 0}</strong></span>
            <table cellPadding="0" cellSpacing="0" className="table_dynamic mx-0 w-100">
                <thead>
                    <tr>
                        <th>Property Details</th>
                        <th>created At</th>
                        <th>Updated At</th>
                        <th>Payment Method</th>
                        <th>Amount</th>
                        <th>Approved</th>
                        <th>Approver</th>
                        <th>extra</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? data.map((value) => {
                        return <tr>
                            <td>
                                <table className="subtable columns2" cellPadding="0" cellSpacing="0">
                                    <tbody><tr>
                                        <td><span>Property Code : </span><strong>{value.updateRecordRequestId.recordId.propertyId}</strong></td>
                                    </tr>
                                        <tr>
                                            <td><span>Owner Name : </span><strong>{value.updateRecordRequestId.recordId.ownerName}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Location : </span><strong>{value.updateRecordRequestId.recordId.location}</strong></td>
                                            <td><span>Zone : </span><strong>{value.updateRecordRequestId.recordId.zone}</strong></td>
                                        </tr>
                                        <tr>
                                            <td><span>Length x Width : </span><strong>{value.updateRecordRequestId.recordId.length + ' x ' + value.updateRecordRequestId.recordId.width}</strong></td>
                                        </tr>

                                    </tbody></table>

                            </td>
                            <td>
                                <span className="report_date">{moment(value.createdAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.createdAt).format("hh:mm:ss a")}</span>
                            </td>
                            <td>
                                <span className="report_date">{moment(value.updatedAt).format("YYYY-MM-DD")}</span><br></br>
                                <span className="report_time">{moment(value.updatedAt).format("hh:mm:ss a")}</span>
                            </td>
                            <td>{value.paymentMethod ? value.paymentMethod : ''}</td>
                            <td>{value.totalAmount.toFixed(2)}</td>
                            <td>{value.paymentVerified ? "Yes" : "No"}</td>
                            <td>Finance</td>
                            <td></td>
                            <td>{value.updateRecordRequestId.status}</td>
                        </tr>
                    }) : null}
                </tbody>
            </table>
            {totalAmount ?
                <table cellpadding="0" cellspacing="0" className=" mx-0 w-100">
                    <tfoot>
                        <tr class="bottom_total">
                            <td align="right">Total <small style={{color: "#ffffff"}}>(Amount)</small> : </td>
                            <td align="right" width="200">{totalAmount}</td>
                        </tr>
                    </tfoot>
                </table> : null}
        </div>)
}

export default CollectionReport;
