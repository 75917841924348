export const DOCUMENT_TYPE_OPTIONS = [
  {
    label: "Proof Of Identity",
    value: "Proof Of Identity",
  },
  {
    label: "Sale Agreement",
    value: "Sale Agreement",
  },
];

export const SERVICE_TYPE_OPTIONS = [
  {
    label: "Survey & Certificate @ [$540]",
    value: "Survey & Certificate",
  },
  {
    label: "Ownership Transfer @ [$810]",
    value: "Ownership Transfer",
  },
];

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Wallet",
    value: "Wallet",
  },
];

export const RECORD_HISTORY_TABLE_HEADINGS = [
  "Owner Name",
  // "Record Change",
  "Land Category",
  // "Update Date",
  // "Purchase Price",
  "Dues",
  // "Survey Date",
  "status",
  "Actions",
];
export const RECORD_HISTORY_TABLE_DATA_KEYS = [
  "ownerName",
  // "updateReason",
  "landCategory",
  // "createdAt",
  // "purchasePrice",
  "dues",
  // "surveyDate",
  "status",
  "actions",
];
