export const USERS_TABLE_HEADINGS = [
  "id",
  "name",
  "email",
  "username",
  "role",
  "status",
  "actions",
];
export const USERS_TABLE_DATA_KEYS = [
  "_id",
  "name",
  "email",
  "username",
  "role",
  "status",
  "actions",
];
