import BlueText from "../BlueText";

function DetailsRow({ leftLabel, leftValue, rightLabel, rightValue }) {
  return (
    <div className="d-flex align-center">
      <div className="d-flex justify-content-end w-25">{leftLabel}{leftLabel ? ":" : ""}</div>
      <div className="d-flex justify-content-between w-50 ms-3 me-3">
        <div><BlueText>{leftValue}</BlueText></div>
        <div>{rightLabel}{rightLabel ? ":" : ""}</div>
      </div>
      <div className="d-flex justify-content-start w-25"><BlueText>{rightValue}</BlueText></div>
    </div>
  );
}

export default DetailsRow;
