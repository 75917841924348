export const RECORD_HISTORY_TABLE_HEADINGS = [
    "Owner Name",
    "Purchase Date",
    "Action",
  ];

  export const RECORD_HISTORY_TABLE_DATA_KEYS = [
    "ownerName",
    "createdAt",
    "actions",
  ];
  
export const RECORDS_TABLE_HEADINGS = ["DATE", "Property ID", "Location", "Address", "Land Size", "Assigned To",  ""];
export const RECORDS_TABLE_DATA_KEYS = ["createdAt", "propertyId", "location", "address", "area", "surveyBy", "actions"];


const LAND_CATEGORY_OPTIONS = [
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Public",
    value: "Public",
  },
];

export const SURVEY_FIELDS = (onChangeCategory, surveyors) => [
  {
    name: "surveyBy",
    label: "Survey By",
    options: surveyors,
    onHandleChange: onChangeCategory,
    type: "select",
  },
  {
    name: "landCategory",
    label: "Land Category",
    // options: LAND_CATEGORY_OPTIONS,
    // onHandleChange: onChangeCategory,
    type: "text",
    errorText: "Land Category is required",
    disabled: true
  },
  {
    name: "surveyDate",
    label: "Survey Date",
    type: "text",
    disabled: true
  },
  {
    name: "area",
    label: "Shape Area",
    type: "text",
    placeholder: "width, height"
  },
];

export const STATUS_OPTIONS = [
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Re-Survey",
    value: "Re-Survey",
  },
]