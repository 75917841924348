import React, { useEffect, useState } from "react";
import "./receipt.css";
import axios from "axios";
import {
    BASE_URL,
  } from "../../constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { extractErrorMessage } from "../../utils";
import ShowErrorAlert from "../ErrorAlert";
import moment from "moment";
import useAppConfig from "../../hooks/useAppConfig";

function Receipt({ name, no, date, width, height, type, amount, totalAmount, discount, total, ...props }) {
    const {appName, appIconPath} = useAppConfig();
    // Redux Store
    const {
        auth: {
        user: { _id: userId, role },
        token,
        },
    } = useSelector((state) => state);
    const { recordId, updateRecordRequestId, requestId } = useParams();

    const [loading, setLoading] = useState(false);
    const [serviceRequest, setServiceRequests] = useState(null);
    const [record, setRecord] = useState(null);
    const [updateRecordRequest, setUpdateRecordRequest] = useState(null);
    const [receipt, setReceipt] = useState(null);

    async function getServiceRequest() {
        setLoading(true);
        try {
          const {
            data: {
              receipt
            },
          } = await axios.get(
            `${BASE_URL}/users/${userId}/updateRecordRequests/${updateRecordRequestId}/receipt/serviceRequests/${requestId}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setReceipt(receipt);
            console.log('re', receipt)
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
          ShowErrorAlert(extractErrorMessage(err));
        }
      }

      useEffect(() => {

        getServiceRequest()
      }, []);

    return (
        <>
            <div className="Receipt container_parent">
                {receipt ? <div className="container reciept_container">
                    <div className="copy">Office Copy</div>
                    <table>
                        <tbody><tr className="header_tr">
                            <th>
                                <h2 style={{color: "black"}} >
                                    DAWLADDA HOOSE EE <br></br>DAGMADA CARMO
                                </h2>
                            </th>
                            <th style={{width: "20%"}} >
                                <img src={appIconPath} style={{width: "100px"}}/>
                            </th>
                            <th>
                                <h2 style={{color: "black"}} >
                                    CARMO LOCAL GOVERNMENT
                                </h2>
                            </th>
                        </tr>
                            <tr>
                                <th colSpan="3">
                                    <h2 style={{marginBottom: "0", fontSize: "20px"}}>
                                        Carmo Puntland Somalia
                                    </h2>
                                    <h4 style={{marginBottom: "0", marginTop: "10px"}}>RECEIPT VOUCHER</h4>
                                </th>
                            </tr>
                        </tbody></table>
                    <div className="content-summary">
                        <table style={{width: "100%"}} >
                            <thead>
                                <tr>
                                    <th style={{textAlign: "left"}} >
                                        <h3 style={{color: "red"}} >{`NO : ${receipt.id}`}</h3>
                                    </th>
                                    <th></th>
                                    <th style={{textAlign: "right"}} >
                                        <h3>{new Date() ? `DATE : ${moment(receipt.createdAt).format("DD/MM/YYYY")}` : "DATE : 30/01/2023"}</h3>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table id="summary-table" cellPadding="3" className="table_summary">
                            <tbody>
                                <tr>
                                    <td style={{whiteSpace: "nowrap"}} >
                                        Magaca Mulkiilaha:
                                    </td>
                                    <td colSpan="3">
                                        {receipt.owner.name ? receipt.owner.name : "Property"}
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        Cabbirka Dhulka:
                                    </td>
                                    <td colSpan="3">
                                        { receipt.width && receipt.length ? `${receipt.width} W x ${receipt.length} L` : "232 W x 32 L" }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td><td>
                                        {receipt.type}
                                    </td>
                                    <td>
                                    {receipt.amount ? receipt.amount : "$0.2"} 
                                    </td>
                                    <td align="right">{receipt.totalAmount ? receipt.totalAmount : "$1484.8"} </td>

                                </tr>
                                <tr>
                                    <td>
                                        Discount:
                                    </td>
                                    <td colSpan="2" align="right" style={{fontWeight: "900"}}>
                                    </td><td align="right">{`${receipt.discount}%`}</td>

                                </tr>
                                <tr>
                                    <td>
                                        Grand Total:
                                    </td>
                                    <td colSpan="3" align="right" style={{fontWeight: "900"}}>
                                        {receipt.netAmount}</td>

                                </tr>
                            </tbody>
                        </table>
                        <table style={{marginTop: "50px", border: "none", width: "100%", textAlign: "center"}}>
                            <tbody><tr style={{border: "none"}}>
                                <td style={{border: "none"}} align="left">
                                    Cashier Sign
                                </td>
                                <td style={{border: "none"}} ></td>
                                <td style={{border: "none"}} align="right">
                                    Customer sign
                                </td>
                            </tr>
                            </tbody></table>
                        <table style={{marginTop: "20px", border: "none", width: "100%", textAlign: "center"}}>
                            <tbody><tr style={{border: "none"}} >
                                <td style={{border: "none", borderTop: "2px dashed #333"}}></td>
                            </tr>
                            </tbody></table>
                    </div>
                    <div className="copy">Customer Copy</div>
                    <table>
                        <tbody><tr className="header_tr">
                            <th>
                                <h2 style={{color: "black"}}>
                                    DAWLADDA HOOSE EE <br></br>DAGMADA CARMO
                                </h2>
                            </th>
                            <th style={{width: "20%"}}>
                                <img src={appIconPath} style={{width: "100px"}}/>
                            </th>
                            <th>
                                <h2 style={{color: "black"}}>
                                    CARMO LOCAL GOVERNMENT
                                </h2>
                            </th>
                        </tr>
                            <tr>
                                <th colSpan="3">
                                    <h2 style={{marginBottom: "0", fontSize: "20px"}}>
                                        Carmo Puntland Somalia
                                    </h2>
                                    <h4 style={{marginBottom: "0", marginTop: "10px"}}>RECEIPT VOUCHER</h4>
                                </th>
                            </tr>
                        </tbody></table>
                    <div className="content-summary">
                        <table style={{width: "100%"}}>
                            <thead>
                                <tr>
                                    <th style={{textAlign: "left"}} >
                                        <h3 style={{color: "red"}} >{`NO : ${receipt.id}`}</h3>
                                    </th>
                                    <th></th>
                                    <th style={{textAlign: "right"}}>
                                        <h3>{new Date() ? `DATE : ${moment(receipt.createdAt).format("DD/MM/YYYY")}` : "DATE : 30/01/2023"}</h3>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table id="summary-table" cellPadding="3" className="table_summary">
                            <tbody>
                                <tr>
                                    <td style={{whiteSpace: "nowrap"}}>
                                        Magaca Mulkiilaha:
                                    </td>
                                    <td colSpan="3">
                                        {receipt.owner.name ? receipt.owner.name : "Property"}
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        Cabbirka Dhulka:
                                    </td>
                                    <td colSpan="3">
                                    { receipt.width && receipt.length ? `${receipt.width} W x ${receipt.length} L` : "232 W x 32 L" }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                    <td>
                                        {receipt.type}
                                    </td>
                                    <td>
                                        {receipt.amount ? receipt.amount : "$0.2"}
                                    </td>
                                    <td align="right">{receipt.totalAmount ? receipt.totalAmount : "$1484.8"} </td>

                                </tr>
                                <tr>
                                    <td>
                                        Discount:
                                    </td>
                                    <td colSpan="2" align="right" style={{fontWeight: "900"}} >
                                    </td><td align="right">{`${receipt.discount}%`}</td>
                                </tr>
                                <tr>
                                    <td >
                                        Grand Total:
                                    </td>
                                    <td colSpan="3" align="right" style={{fontWeight: "900"}} >
                                        {receipt.netAmount}</td>

                                </tr>
                            </tbody>
                        </table>
                        <table style={{marginTop: "50px", border: "none", width: "100%", textAlign: "center"}}>
                            <tbody><tr style={{border: "none"}} >
                                <td style={{border: "none"}} align="left">
                                    Cashier Sign
                                </td>
                                <td style={{border: "none"}} ></td>
                                <td style={{border: "none"}} align="right">
                                    Customer sign
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                </div> : null}
            </div>
        </>
    );
}

export default Receipt;
