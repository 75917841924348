import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function NonProtectedRoute({ children }) {
    const { auth: { token, user }} = useSelector(state => state);

    if(!token) {
        return children;
    } else if(user?.role === "Super_Admin") {
        return <Navigate to={"/admins/configurations/appConfig"} />;
    }else if(user?.role === "GIS") {
        return <Navigate to={"/surveyor/records"} />;
    } else {
        return <Navigate to={"/dashboard"} />;
    }
}